import { NotificationsData, INITIAL_NOTIFICATIONS_DATA } from '../store-data';
import {
  ADD_UNREAD_MESSAGE_ACTION, REMOVE_UNREAD_MESSAGE_ACTION, RESET_UNREAD_MESSAGES_ACTION, RESET_NOTIFICATIONS_ACTION,
  ADD_FRIEND_TYPING_ACTION, REMOVE_FRIEND_TYPING_ACTION, AddUnreadMessageAction, RemoveUnreadMessageAction,
  ResetUnreadMessagseAction, ResetNotificationsAction, AddFriendTypingAction, RemoveFriendTypingAction,
  ADD_SYSTEM_MESSAGE_ACTION, AddSystemMessageAction, RESET_SYSTEM_NOTIFICATIONS_ACTION, ResetSystemNotificationsAction,
  RESET_SYSTEM_NOTIFICATIONS_COUNTER_ACTION, ResetSystemNotificationsCounterAction
} from '../actions/notifications';
import { Action } from '@ngrx/store';

export function notificationsData(state: NotificationsData, action: Action): NotificationsData {
  switch (action.type) {
    case ADD_UNREAD_MESSAGE_ACTION:
      return handleAddUnreadMessageAction(state, <any>action);
    case REMOVE_UNREAD_MESSAGE_ACTION:
      return handleRemoveUnreadMessageAction(state, <any>action);
    case RESET_UNREAD_MESSAGES_ACTION:
      return handleResetUnreadMessagesAction(state, <any>action);
    case RESET_NOTIFICATIONS_ACTION:
      return handleResetNotificationsAction(state, <any>action);
    case ADD_FRIEND_TYPING_ACTION:
      return handleAddFriendTypingAction(state, <any>action);
    case REMOVE_FRIEND_TYPING_ACTION:
      return handleRemoveFriendTypingAction(state, <any>action);
    case ADD_SYSTEM_MESSAGE_ACTION:
      return handleAddSystemMessageAction(state, <any>action);
    case RESET_SYSTEM_NOTIFICATIONS_ACTION:
      return handleResetSystemMessagesAction(state, <any>action);
    case RESET_SYSTEM_NOTIFICATIONS_COUNTER_ACTION:
      return handleResetSystemMessagesCounterAction(state, <any>action);
    default:
      return state;
  }
}

function handleAddUnreadMessageAction(state: NotificationsData, action: AddUnreadMessageAction) {
  const newSubtotalUnreadMessageItems = (state) ? Object.assign({}, state.subtotalUnreadMessageItems) : {},
    newSubtotalUnreadMessages = (state) ? Object.assign({}, state.subtotalUnreadMessages) : {},
    newFriendsTyping = (state) ? state.friendsTyping : {};
  let newTotalUnreadMessages = (state) ? state.totalUnreadMessages : 0;

  if (!newSubtotalUnreadMessages[action.payload.data.sender]) {
    newSubtotalUnreadMessages[action.payload.data.sender] = 1;
    newSubtotalUnreadMessageItems[action.payload.data.sender] = {};
    newSubtotalUnreadMessageItems[action.payload.data.sender][action.payload.id] = action.payload;
  } else {
    if (!newSubtotalUnreadMessageItems[action.payload.data.sender][action.payload.id]) {
      newSubtotalUnreadMessages[action.payload.data.sender] = newSubtotalUnreadMessages[action.payload.data.sender] + 1;
      newSubtotalUnreadMessageItems[action.payload.data.sender][action.payload.id] = action.payload;
    }
  }

  newTotalUnreadMessages = (newTotalUnreadMessages == 0) ? 1 : newTotalUnreadMessages + 1;

  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: newSubtotalUnreadMessageItems,
    subtotalUnreadMessages: newSubtotalUnreadMessages,
    totalUnreadMessages: newTotalUnreadMessages,
    totalUnreadSystenMessages: state.totalUnreadSystenMessages,
    unreadSystemMessages: state.unreadSystemMessages,
    friendsTyping: newFriendsTyping
  };

  return newStoreState;
}

function handleRemoveUnreadMessageAction(state: NotificationsData, action: RemoveUnreadMessageAction) {
  const newSubtotalUnreadMessageItems = (state) ? Object.assign({}, state.subtotalUnreadMessageItems) : {},
    newSubtotalUnreadMessages = (state) ? Object.assign({}, state.subtotalUnreadMessages) : {},
    newFriendsTyping = (state) ? state.friendsTyping : {};
  let newTotalUnreadMessages = (state) ? state.totalUnreadMessages : 0;

  if (newSubtotalUnreadMessageItems[action.payload.data.sender]) {
    if (newSubtotalUnreadMessageItems[action.payload.data.sender][action.payload.id]) {
      newSubtotalUnreadMessages[action.payload.data.sender] = newSubtotalUnreadMessages[action.payload.data.sender] - 1;
      newTotalUnreadMessages = newTotalUnreadMessages - 1;
      delete newSubtotalUnreadMessageItems[action.payload.data.sender][action.payload.id];
    }
  }

  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: newSubtotalUnreadMessageItems,
    subtotalUnreadMessages: newSubtotalUnreadMessages,
    totalUnreadMessages: newTotalUnreadMessages,
    totalUnreadSystenMessages: state.totalUnreadSystenMessages,
    unreadSystemMessages: state.unreadSystemMessages,
    friendsTyping: newFriendsTyping
  };

  return newStoreState;
}

function handleResetUnreadMessagesAction(state: NotificationsData, action: ResetUnreadMessagseAction) {
  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: {},
    subtotalUnreadMessages: {},
    totalUnreadMessages: 0,
    totalUnreadSystenMessages: 0,
    unreadSystemMessages: [],
    friendsTyping: {}
  };
  return newStoreState;
}

function handleAddFriendTypingAction(state: NotificationsData, action: AddFriendTypingAction) {
  const newFriendsTyping = (state) ? Object.assign({}, state.friendsTyping) : {};
  newFriendsTyping[action.payload.sender] = true;

  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: state.subtotalUnreadMessageItems,
    subtotalUnreadMessages: state.subtotalUnreadMessages,
    totalUnreadMessages: state.totalUnreadMessages,
    totalUnreadSystenMessages: state.totalUnreadSystenMessages,
    unreadSystemMessages: state.unreadSystemMessages,
    friendsTyping: newFriendsTyping
  };

  return newStoreState;
}

function handleRemoveFriendTypingAction(state: NotificationsData, action: RemoveFriendTypingAction) {
  const newFriendsTyping = (state) ? Object.assign({}, state.friendsTyping) : {};
  delete newFriendsTyping[action.payload];

  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: state.subtotalUnreadMessageItems,
    subtotalUnreadMessages: state.subtotalUnreadMessages,
    totalUnreadMessages: state.totalUnreadMessages,
    totalUnreadSystenMessages: state.totalUnreadSystenMessages,
    unreadSystemMessages: state.unreadSystemMessages,
    friendsTyping: newFriendsTyping
  };

  return newStoreState;
}

function handleResetNotificationsAction(state: NotificationsData, action: ResetNotificationsAction) {
  return INITIAL_NOTIFICATIONS_DATA;
}

function handleAddSystemMessageAction(state: NotificationsData, action: AddSystemMessageAction) {
  let newTotalUnreadMessages = (state) ? state.totalUnreadSystenMessages : 0;
  newTotalUnreadMessages = (newTotalUnreadMessages == 0) ? 1 : newTotalUnreadMessages + 1;

  state.unreadSystemMessages.push(action.payload);

  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: state.subtotalUnreadMessageItems,
    subtotalUnreadMessages: state.subtotalUnreadMessages,
    totalUnreadMessages: state.totalUnreadMessages,
    totalUnreadSystenMessages: newTotalUnreadMessages,
    unreadSystemMessages: state.unreadSystemMessages,
    friendsTyping: state.friendsTyping
  };

  return newStoreState;
}

function handleResetSystemMessagesCounterAction(state: NotificationsData, action: ResetSystemNotificationsCounterAction) {
  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: state.subtotalUnreadMessageItems,
    subtotalUnreadMessages: state.subtotalUnreadMessages,
    totalUnreadMessages: state.totalUnreadMessages,
    totalUnreadSystenMessages: 0,
    unreadSystemMessages: state.unreadSystemMessages,
    friendsTyping: state.friendsTyping
  };
  return newStoreState;
}

function handleResetSystemMessagesAction(state: NotificationsData, action: ResetSystemNotificationsAction) {
  const newStoreState: NotificationsData = {
    subtotalUnreadMessageItems: state.subtotalUnreadMessageItems,
    subtotalUnreadMessages: state.subtotalUnreadMessages,
    totalUnreadMessages: state.totalUnreadMessages,
    totalUnreadSystenMessages: 0,
    unreadSystemMessages: [],
    friendsTyping: state.friendsTyping
  };
  return newStoreState;
}