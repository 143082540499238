import { Injectable, Injector } from '@angular/core';
import { ConnectivityService } from './connectivity.service';
import { KeychainService } from './keychain.service';
import { Events } from './events.service';
import * as Sentry from '@sentry/angular-ivy';
import { first } from 'rxjs/operators';

import { ApplicationState } from '../ngrx/application-state';
import { UiState } from '../ngrx/ui-state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {

  authType = 'login';

  mobileNumber = '';

  callInProgress = false;

  isCalled = false;

  callStatus = '';

  private userId: string;

  private isAuthenticated = false;

  private authKey = '';

  private callId = '';

  private keychainService: KeychainService;

  private connectivityService: ConnectivityService;

  constructor(private store: Store<ApplicationState>, private events: Events, private injector: Injector) { }

  init(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.keychainService = this.injector.get(KeychainService);
      }

      this.isAuthenticated = false;
      this.callInProgress = false;
      this.isCalled = false;
      this.callStatus = '';

      this.store.dispatch({
        type: 'SET_BATCH_UI_VARIABLE_ACTION', payload: {
          appIsAuthenticating: true
        }
      });

      try {
        await this.connectivityService.initializeWebsockets();
        this.connectivityService.feathers.service('auth-status').on('updated', (data) => {

          console.log('CALL STATUS ' + data.status);

          if (data.status == 'answered' || data.status == 'machine' || data.status == 'unanswered' || data.status == 'completed') {
            this.callInProgress = false;
            this.isCalled = true;
            this.callStatus = data.status;
          } else if (data.status == 'failed' || data.status == 'rejected' || data.status == 'busy') {
            this.callInProgress = false;
            this.isCalled = true;
            this.callStatus = data.status;
          } else if (data.status == 'ringing') {
            this.callStatus = data.status;
          }
          this.events.publish('auth:status', { status: data.status });
        });

        resolve({});
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  destroy(): void {
    this.store.dispatch({
      type: 'SET_UI_VARIABLE_ACTION', payload: {
        variable: 'appIsAuthenticating',
        value: false
      }
    });

    this.connectivityService.feathers.service('auth-status').removeListener('updated');
    if (!this.isAuthenticated) {
      this.connectivityService.feathers.logout();
    }
  }

  async makeMissCall(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const anonymousUserId = await this.keychainService.get('anonymous_user_id');
      const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();

      Sentry.addBreadcrumb({
        message: 'makeMissCall',
        category: 'auth.service',
        data: {
          action: 'authenticate',
          anonymous_user_id: anonymousUserId,
          phone_number: this.mobileNumber,
          auth_type: this.authType,
          platform: uiState.appPlatform
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('auth').create({
        action: 'authenticate',
        anonymous_user_id: anonymousUserId,
        phone_number: this.mobileNumber,
        auth_type: this.authType,
        platform: uiState.appPlatform
      }, {}).then((data: any) => {
        this.authKey = data.auth_id;
        this.userId = data.ls_user_id;
        this.callId = data.call_id;
        resolve({});
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error.message);
      });
    });
  }

  doesNumberExist(number): Promise<any> {
    return new Promise((resolve, reject) => {
      Sentry.addBreadcrumb({
        message: 'doesNumberExist',
        category: 'auth.service',
        data: {
          action: 'check-if-new-number',
          phone_number: number
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('auth').create({
        action: 'check-if-new-number',
        phone_number: number
      }, {}).then((data) => {
        resolve(data.doesExist);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  verifyNumber(digits): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const anonymousUserId = await this.keychainService.get('anonymous_user_id');
      const deviceId = await this.keychainService.get('device_id');

      Sentry.addBreadcrumb({
        message: 'verifyNumber',
        category: 'auth.service',
        data: {
          action: 'authenticate',
          auth_id: this.authKey,
          digits: digits,
          device_id: deviceId,
          call_id: this.callId
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('auth-verify').create({
        action: 'authenticate',
        auth_id: this.authKey,
        digits: digits,
        device_id: deviceId,
        call_id: this.callId,
        user_id: anonymousUserId
      }, {}).then((data) => {
        if (this.authType == 'login') {
          Sentry.addBreadcrumb({
            message: 'verifyNumber',
            category: 'auth',
            data: data,
            level: 'info'
          });

          // Logout anonymously

          this.connectivityService.feathers.logout();

          // Autheticate user

          this.connectivityService.feathers.authenticate({
            strategy: 'jwt',
            accessToken: data.token,
            type: 'jwt'
          }).then(async () => {
            this.isAuthenticated = true;
            await this.keychainService.set('jwt', data.token);
            await this.keychainService.set('phone_number', this.mobileNumber);
            await this.keychainService.set('user_id', this.userId);
            resolve({});
          }).catch((error) => {
            Sentry.captureException(error);
            console.error(error);
            reject(error.message);
          });
        } else {
          resolve({});
        }
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error.message);
      });
    });
  }
}
