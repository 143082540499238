import { Action } from '@ngrx/store';

export const ADD_LIVE_VOCABULARY_ACTION = 'ADD_LIVE_VOCABULARY_ACTION';
export const UPDATE_LIVE_VOCABULARY_ACTION = 'UPDATE_LIVE_VOCABULARY_ACTION';
export const DELETE_LIVE_VOCABULARY_ACTION = 'DELETE_LIVE_VOCABULARY_ACTION';
export const RESET_LIVE_VOCABULARY_ACTION = 'RESET_LIVE_VOCABULARY_ACTION';

export class AddVocabularyAction implements Action {
  readonly type = ADD_LIVE_VOCABULARY_ACTION;
  constructor(public payload?: any) { }
}

export class UpdateVocabularyAction implements Action {
  readonly type = UPDATE_LIVE_VOCABULARY_ACTION;
  constructor(public payload?: any) { }
}

export class DeleteVocabularyAction implements Action {
  readonly type = DELETE_LIVE_VOCABULARY_ACTION;
  constructor(public payload?: any) { }
}

export class ResetVocabularyAction implements Action {
  readonly type = RESET_LIVE_VOCABULARY_ACTION;
  constructor(public payload?: any) { }
}
