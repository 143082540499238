<ion-header class="ion-no-border">
  <ion-toolbar #toolbar color="secondary">
    <ion-buttons *ngIf="showDismissButton" slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon *ngIf="!showConversationDetails && !showConversationsList" ios="arrow-back"
          md="{{(!plt.is('desktop') && !plt.is('tablet')) ? 'arrow-back-sharp' : 'close-sharp'}}" slot="icon-only"
          color="white"></ion-icon>
        <ion-icon *ngIf="showConversationDetails || showConversationsList" ios="arrow-back" md="arrow-back-sharp"
          slot="icon-only" color="white"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="white">{{'title_helpdesk' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<input id="fileSelector" type="file" style="display: none;" />
<ion-backdrop *ngIf="!contentIsReady" tappable="false" style="background-color: white; opacity: 1;"></ion-backdrop>

<ion-content fullscreen="{{(plt.is('ios')) ? 'false' : 'true'}}"
  style="--background: {{(showConversationDetails || showConversationsList) ? 'white' : '#f4f5f8'}};">

  <!-- Main dialog-->

  <div *ngIf="!showConversationDetails && !showConversationsList">
    <ion-card>
      <ion-card-header>
        <ion-card-title>{{'label_start_conversation' | translate}}</ion-card-title>
      </ion-card-header>

      <ion-card-content style="font-size: 16px;">
        {{'text_we_reply_soon' | translate}}
      </ion-card-content>

      <ion-card-content style="padding-top: 0px; padding-bottom: 5px;">
        <ion-button (click)="startNewConversation()" color="primary" shape="round" style="margin-left: 0px;">
          {{'button_send_us_a_message' | translate}}
        </ion-button>
      </ion-card-content>

      <!-- Show unread messages -->

      <ion-list style="padding-top: 0px;">
        <div *ngFor="let conversation of conversations; trackBy: trackFn">
          <ion-item button *ngIf="conversation.user_messages_unread > 0" (click)="openConversation(conversation)"
            detail="true" style="padding-top: 0px;">
            <ion-thumbnail slot="start" style="border-radius: 50%;"
              [style.background-color]="(conversation.sender == 'bot' || !conversation.is_assigned) ? '#005C72' : 'lightgray'">
              <img *ngIf="conversation.sender == 'bot' || !conversation.is_assigned" src="assets/img/chatbot.svg"
                width="40" height="40" />

              <img *ngIf="conversation.is_assigned" imageType="support"
                imageUrl="staff-thumbnails/{{conversation.support_user_id}}-100x100.webp" imageDate="''"
                imageHeight="100" imageWidth="100" placeholderImage="''" style="border-radius: 50%;">
            </ion-thumbnail>

            <!-- Name -->

            <ion-grid class="ion-no-padding ion-no-margin" style="padding-top: 10px;">
              <ion-row class="ion-no-padding ion-no-margin ion-no-wrap">
                <ion-col class="ion-no-padding ion-no-margin">
                  <table style="width: 100%;">
                    <tbody>
                      <tr style="line-height: 22px;">
                        <td class="ellipsis">
                          <span style="font-size: 18px;">
                            {{((conversation.messages[conversation.messages.length-1].sender == 'support') ?
                            'label_support':'') | translate}}
                            {{((conversation.messages[conversation.messages.length-1].sender == 'bot') ? 'label_bot' :
                            '') | translate}}
                            {{((conversation.messages[conversation.messages.length-1].sender != 'support' &&
                            conversation.messages[conversation.messages.length-1].sender != 'bot') ? 'label_you' : '') |
                            translate}}
                          </span>
                        </td>
                        <td style="max-width: 100px; float: right;">
                          <div style="margin-bottom: 3px;">
                            <span style="font-size: 14px; padding-right: 6px; color: #4F4F4F;">
                              {{conversation.date_updated | dateTimeFromIso | dateTimeToFormat:((conversation.date_updated |
                                lxDifference:null:'days') > -1) ? 'HH:mm':'DD/MM/YY'}}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ion-col>
              </ion-row>

              <!-- Message -->

              <ion-row class="ion-no-padding ion-no-margin ion-no-wrap" style="padding-top: 2px;">
                <ion-col class="ion-no-padding ion-no-margin">
                  <table style="width: 100%; margin-bottom: 4px;">
                    <tbody>
                      <tr>
                        <td *ngIf="conversation.messages[conversation.messages.length-1].text.length > 0"
                          class="ellipsis">
                          <span style="font-size: 16px; vertical-align:middle;"
                            [style.color]="(plt.is('android')) ? '#666' : '#8e9093'"
                            [innerHTML]="((conversation.messages[conversation.messages.length-1].sender != 'support' && conversation.messages[conversation.messages.length-1].sender != 'bot') ? 
                                  conversation.messages[conversation.messages.length-1].text : conversation.messages[conversation.messages.length-1].translation) | emoji"></span>
                        </td>
                        <td *ngIf="conversation.messages[conversation.messages.length-1].text.length == 0"
                          class="ellipsis">
                          <span *ngIf="conversation.messages[conversation.messages.length-1].message_type == 'gif'"
                            style="font-size: 16px; vertical-align:middle;"
                            [style.color]="(plt.is('android')) ? '#666' : '#8e9093'">
                            {{'text_gif' | translate }}
                          </span>

                          <span *ngIf="conversation.messages[conversation.messages.length-1].message_type == 'picture'"
                            style="font-size: 20px; vertical-align:middle;"
                            [style.color]="(plt.is('android')) ? '#666' : '#8e9093'">
                            <ion-icon ios="camera" md="camera" style="margin: 0px 4px 0px 4px;"></ion-icon>
                          </span>

                          <span *ngIf="conversation.messages[conversation.messages.length-1].message_type == 'video'"
                            style="font-size: 20px; vertical-align:middle;"
                            [style.color]="(plt.is('android')) ? '#666' : '#8e9093'">
                            <ion-icon ios="videocam" md="videocam" style="margin: 0px 4px 0px 4px;"></ion-icon>
                          </span>
                        </td>
                        <td style="width: 1px; vertical-align: middle; padding-top: 2px;">
                          <ion-badge *ngIf="conversation.user_messages_unread > 0" color="primary"
                            style="margin-right: 6px;" slot="end" mode="ios">
                            {{conversation.user_messages_unread}}</ion-badge>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
        </div>
      </ion-list>

      <ion-item *ngIf="conversations.length > 0" (click)="listConversations()" button detail lines="none"
        style="border-top: 1px solid #f4f5f8;">
        <ion-label>{{'button_see_conversations' | translate}}</ion-label>
      </ion-item>
    </ion-card>


    <!-- FAQ -->

    <ion-card style="margin-top: 20px;">
      <ion-card-header style="padding-bottom: 10px;">
        <ion-card-title>{{'label_faq' | translate}}</ion-card-title>
      </ion-card-header>

      <ion-searchbar mode="ios" debounce="500" (ionInput)="searchFaq($event)" (ionCancel)="faqSearchCancelled()"
        (ionClear)="faqSearchCancelled()" placeholder="{{'button_search' | translate}}"
        style="--icon-color: #989aa2; --cancel-button-color: #989aa2; --clear-button-color: #989aa2; padding-top: 0px;">
      </ion-searchbar>

      <div *ngIf="isSearchingfaq">
        <ion-card-content style="padding-top: 0px; border-bottom: 1px solid #f4f5f8;">
          {{'label_search_results_for' | translate}} "{{faqSearchParam}}"
        </ion-card-content>

        <ion-item button *ngFor="let item of faqItems; let last = last; trackBy: trackFn" (click)="openFAQ(item)"
          lines="{{(!last) ? 'inset' : 'none'}}">
          <ion-label>
            <ion-text color="primary">
              <h3><strong>{{item.document.title}}</strong></h3>
            </ion-text>
            <p>{{item.document.text}}</p>
          </ion-label>
        </ion-item>

        <ion-card-content>
          <ion-button (click)="openFAQ()" color="primary" expand="full" fill="outline" shape="round" strong="true">
            {{'label_see_more_results' | translate}}
          </ion-button>
        </ion-card-content>
      </div>
    </ion-card>


    <!-- System status -->

    <ion-card style="margin-top: 20px;">
      <ion-card-header style="padding-bottom: 0px;">
        <ion-card-title>{{'label_system_status' | translate}}</ion-card-title>
      </ion-card-header>

      <ion-item lines="none" button style="padding-top: 0px;" (click)="openStatusPage()">
        <ion-icon name="checkmark-circle-outline" color="{{(systemStatus == 'UP') ? 'success' : 'danger'}}" slot="start"
          style="font-size: 32px; margin-right: 20px;"></ion-icon>
        <ion-label>
          <ion-text color="primary">
            <h3 style="font-size: 16px;">
              <strong>{{((systemStatus == 'UP') ? 'text_system_status_up' : 'text_system_status_down') |
                translate}}</strong>
            </h3>
          </ion-text>
          <p style="font-size: 16px;">{{'text_updated' | translate}} {{systemDate | dateTimeFromIso | dateTimeToFormat:'llll'}}</p>
        </ion-label>
      </ion-item>
    </ion-card>


    <!-- News -->

    <ion-card style="margin-top: 20px;">
      <ion-card-header style="padding-bottom: 10px; border-bottom: 1px solid #f4f5f8;">
        <ion-card-title>{{'label_latest_news' | translate}}</ion-card-title>
      </ion-card-header>

      <div *ngIf="news.length == 0">
        <ion-item style="padding-top: 0px;">
          <ion-label>
            <ion-text color="primary">
              <ion-skeleton-text animated style="width: 60%; margin-bottom: 10px;"></ion-skeleton-text>
            </ion-text>
            <ion-skeleton-text animated style="width: 45%;"></ion-skeleton-text>
          </ion-label>
        </ion-item>

        <ion-item style="padding-top: 0px;">
          <ion-label>
            <ion-text color="primary">
              <ion-skeleton-text animated style="width: 60%; margin-bottom: 10px;"></ion-skeleton-text>
            </ion-text>
            <ion-skeleton-text animated style="width: 45%;"></ion-skeleton-text>
          </ion-label>
        </ion-item>

        <ion-item style="padding-top: 0px;">
          <ion-label>
            <ion-text color="primary">
              <ion-skeleton-text animated style="width: 60%; margin-bottom: 10px;"></ion-skeleton-text>
            </ion-text>
            <ion-skeleton-text animated style="width: 45%;"></ion-skeleton-text>
          </ion-label>
        </ion-item>
      </div>

      <ion-item *ngFor="let newsItem of news; let last = last; trackBy: trackFn" (click)="openNews(newsItem)" button
        style="padding-top: 0px;" lines="{{(!last) ? 'inset' : 'none'}}">
        <ion-label>
          <ion-text color="primary">
            <h3 style="font-size: 16px;"><strong>{{newsItem.title}}</strong></h3>
          </ion-text>
          <p style="font-size: 16px;">{{newsItem.text}}</p>
        </ion-label>
      </ion-item>
    </ion-card>
  </div>


  <!-- Conversations list -->

  <div *ngIf="showConversationsList">
    <ion-list>
      <ion-item button *ngFor="let conversation of conversations; trackBy: trackFn"
        (click)="openConversation(conversation)" button detail="true" style="padding-top: 0px;">
        <ion-thumbnail slot="start"
          [style.background-color]="(conversation.sender == 'bot' || !conversation.is_assigned) ? '#005C72' : 'lightgray'"
          style="border-radius: 50%;">
          <img *ngIf="conversation.sender == 'bot' || !conversation.is_assigned" src="assets/img/chatbot.svg" width="40"
            height="40" />

          <img *ngIf="conversation.is_assigned" imageType="support"
            imageUrl="staff-thumbnails/{{conversation.support_user_id}}-100x100.webp" imageDate="''" imageHeight="100"
            imageWidth="100" placeholderImage="''" style="border-radius: 50%;">
        </ion-thumbnail>

        <!-- Name -->

        <ion-grid class="ion-no-padding ion-no-margin" style="padding-top: 10px;">
          <ion-row class="ion-no-padding ion-no-margin ion-no-wrap">
            <ion-col class="ion-no-padding ion-no-margin">
              <table style="width: 100%;">
                <tbody>
                  <tr style="line-height: 22px;">
                    <td class="ellipsis">
                      <span style="font-size: 18px;">
                        {{((conversation.messages[conversation.messages.length-1].sender == 'support') ?
                        'label_support':'') | translate}}
                        {{((conversation.messages[conversation.messages.length-1].sender == 'bot') ? 'label_bot' : '') |
                        translate}}
                        {{((conversation.messages[conversation.messages.length-1].sender != 'support' &&
                        conversation.messages[conversation.messages.length-1].sender != 'bot') ? 'label_you' : '') |
                        translate}}
                      </span>
                    </td>
                    <td style="max-width: 100px; float: right;">
                      <div style="margin-bottom: 3px;">
                        <span style="font-size: 14px; padding-right: 6px; color: #4F4F4F;">
                          {{conversation.date_updated | dateTimeFromIso | dateTimeToFormat:((conversation.date_updated |
                            lxDifference:null:'days') > -1) ? 'HH:mm':'DD/MM/YY'}}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ion-col>
          </ion-row>

          <!-- Message -->

          <ion-row class="ion-no-padding ion-no-margin ion-no-wrap" style="padding-top: 2px;">
            <ion-col class="ion-no-padding ion-no-margin">
              <table style="width: 100%; margin-bottom: 4px;">
                <tbody>
                  <tr>
                    <td *ngIf="conversation.messages[conversation.messages.length-1].text.length > 0" class="ellipsis">
                      <span style="font-size: 16px; vertical-align:middle;"
                        [style.color]="(plt.is('android')) ? '#666' : '#8e9093'"
                        [innerHTML]="((conversation.messages[conversation.messages.length-1].sender != 'support' && conversation.messages[conversation.messages.length-1].sender != 'bot') ? 
                              conversation.messages[conversation.messages.length-1].text : conversation.messages[conversation.messages.length-1].translation) | emoji"></span>
                    </td>
                    <td *ngIf="conversation.messages[conversation.messages.length-1].text.length == 0" class="ellipsis">
                      <span *ngIf="conversation.messages[conversation.messages.length-1].message_type == 'gif'"
                        style="font-size: 16px; vertical-align:middle;"
                        [style.color]="(plt.is('android')) ? '#666' : '#8e9093'">
                        {{'text_gif' | translate }}
                      </span>

                      <span *ngIf="conversation.messages[conversation.messages.length-1].message_type == 'picture'"
                        style="font-size: 20px; vertical-align:middle;"
                        [style.color]="(plt.is('android')) ? '#666' : '#8e9093'">
                        <ion-icon ios="camera" md="camera" style="margin: 0px 4px 0px 4px;"></ion-icon>
                      </span>

                      <span *ngIf="conversation.messages[conversation.messages.length-1].message_type == 'video'"
                        style="font-size: 20px; vertical-align:middle;"
                        [style.color]="(plt.is('android')) ? '#666' : '#8e9093'">
                        <ion-icon ios="videocam" md="videocam" style="margin: 0px 4px 0px 4px;"></ion-icon>
                      </span>
                    </td>
                    <td style="width: 1px; vertical-align: middle; padding-top: 2px;">
                      <ion-badge *ngIf="conversation.user_messages_unread > 0" color="primary"
                        style="margin-right: 6px;" slot="end" mode="ios">
                        {{conversation.user_messages_unread}}</ion-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>
  </div>


  <!-- Conversation -->

  <div *ngIf="showConversationDetails">
    <ion-list lines="none" *ngIf="conversation" style="margin-bottom: 0px; margin-top: 10px;">
      <ion-item-sliding #slidingItem
        *ngFor="let message of conversation.messages; let index = index; let first = first; let last = last; trackBy: trackFn"
        disabled="{{plt.is('ios') ? 'false' : 'true'}}">

        <!-- Date Separator -->

        <ion-row
          *ngIf="first || ((message.date_created | lxDifference: conversation.messages[index-1].date_created :'minutes') > 10)"
          style="padding-bottom: 12px; padding-top: 12px;">
          <ion-col class="ion-text-center">
            <ion-badge style="padding: 8px; font-weight: 500; color: white; text-align: center;" color="badge-gray">
              {{message.date_created | dateTimeFromIso | dateTimeToRelativeCalendar }}
            </ion-badge>
          </ion-col>
        </ion-row>

        <!-- Instant message - Receiver -->

        <ion-item *ngIf="message.sender == 'support' || message.sender == 'bot'" class="ion-text-wrap hammer-scroll-fix"
          detail="false" style="border: none; --padding-bottom: 8px; --padding-top: 8px;">
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">

              <!-- User picture -->

              <ion-col style="margin-right: 12px; min-width: 24px;"
                class="ion-no-padding {{(plt.is('desktop') || plt.is('tablet')) ? 'avatar-tablet' : 'avatar-mobile'}}">

                <img *ngIf="first || (conversation.messages[index-1].sender != 'bot' && message.sender == 'bot')"
                  src="assets/img/chatbot.svg" width="36" height="36" />

                <img
                  *ngIf="first || (conversation.messages[index-1].sender != 'support' && message.sender == 'support' && conversation.is_assigned)"
                  imageType="support" imageUrl="staff-thumbnails/{{conversation.support_user_id}}-100x100.webp"
                  imageDate="''" imageHeight="100" imageWidth="100" placeholderImage="''" style="border-radius: 50%;">

                <img
                  *ngIf="first || (conversation.messages[index-1].sender != 'support' && message.sender == 'support' && !conversation.is_assigned)"
                  src="assets/img/user-thumbnail-blue-white-{{(plt.is('ios')) ? 'ios' : 'md'}}.svg">
              </ion-col>

              <ion-col class="ion-no-padding">
                <ion-grid class="ion-no-padding" style="float: left; text-align: left;">

                  <!-- Text message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'text' || !message.message_type">
                    <ion-col class="ion-no-padding instant-message-same-language-receiver">
                      <span style="font-size: 16px; color: #4A4A4A; font-weight: 300; white-space: pre-line;"
                        [innerHTML]="message.translation | emoji"></span>
                    </ion-col>
                  </ion-row>

                  <!-- Emoji message -->

                  <ion-row class="ion-no-padding" *ngIf="(message.message_type == 'emoji')">
                    <ion-col class="ion-no-padding">
                      <span style="color: #000;"
                        [style.font-size]="(plt.is('desktop') || plt.is('tablet')) ? '60px' : '54px'"
                        [innerHTML]="message.text | emoji:true"></span>
                    </ion-col>
                  </ion-row>

                  <!-- URL message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'url'">
                    <ion-col class="ion-no-padding">

                      <!-- File view -->

                      <div
                        *ngIf="!message.params.oembed.meta.media && message.params.oembed.links.file && !message.params.oembed.meta.title"
                        (click)="openUrl(message)" style="float: left;" [style.max-width]="defaultItemWidth+'px'"
                        class="instant-message-url-file-view">
                        <ion-grid class="ion-no-padding">
                          <ion-row class="ion-no-padding">
                            <ion-col class="ion-no-padding ion-text-left" style="max-width: 15px; min-width: 15px;">
                              <ion-icon style="color: white; font-size: 22px;" ios="document" md="document-sharp">
                              </ion-icon>
                            </ion-col>
                            <ion-col class="ion-no-padding ion-text-left"
                              style="margin-top: 3px !important; margin-left: 8px;">
                              <span
                                style="font-size: 16px; color: white;">{{message.text.split('/')[message.text.split('/').length
                                - 1]}}</span>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </div>

                      <!-- Social view -->

                      <div style="float: left; cursor: pointer;" [style.width]="defaultItemWidth+'px'"
                        (click)="openUrl(message)"
                        *ngIf="!message.params.oembed.meta.media && message.params.oembed.links && message.params.oembed.meta.title">
                        <div *ngIf="message.params.oembed.links && message.params.oembed.links.thumbnail"
                          style="position: relative;">
                          <ion-icon *ngIf="message.params.oembed.links && message.params.oembed.links.player" ios="play"
                            md="play-sharp" color="white" [ngClass]="'instant-message-video-play-button-' + platform"
                            [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                          <img [style.width]="defaultItemWidth+'px'" [style.height]="(defaultItemHeight - 100)+'px'"
                            style="border-radius: 10px 10px 0px 0px; object-fit: cover;" imageType="url"
                            placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''" imageHeight="''"
                            imageWidth="''" />
                        </div>
                        <div *ngIf="message.params.oembed.links && !message.params.oembed.links.thumbnail"
                          class="instant-message-url-social-view-icon" [style.width]="defaultItemWidth+'px'">
                          <img *ngIf="message.params.oembed.links.icon" [style.max-width]="defaultItemWidth+'px'"
                            [style.height]="(defaultItemHeight - 100)+'px'" style="object-fit: cover;" imageType="url"
                            placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''" imageHeight="''"
                            imageWidth="''" />
                        </div>
                        <div class="instant-message-url-social-view-desc"
                          *ngIf="message.params.oembed.meta.description">
                          <div *ngIf="message.params.oembed.meta.title"
                            class="instant-message-url-social-view-desc-title">
                            <strong>{{message.params.oembed.meta.title}}</strong>
                          </div>
                          <div *ngIf="message.params.oembed.meta.description"
                            class="instant-message-url-social-view-desc-two">
                            {{message.params.oembed.meta.description}}
                          </div>
                          <div *ngIf="message.params.oembed.meta.site" class="instant-message-url-social-view-footer">
                            <ion-grid class="ion-no-padding">
                              <ion-row class="ion-no-padding">
                                <ion-col class="ion-no-padding ion-text-left">
                                  {{'@'+ message.params.oembed.meta.site}}
                                </ion-col>
                                <ion-col class="ion-no-padding ion-text-right">
                                  {{message.params.oembed.meta.date | dateTimeFromIso | dateTimeToFormat:'ll'}}
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                        </div>
                      </div>

                      <!-- Reader view -->

                      <div style="float: left; cursor: pointer;" [style.width]="defaultItemWidth+'px'"
                        (click)="openUrl(message)"
                        *ngIf="message.params.oembed.meta.media && message.params.oembed.meta.media == 'reader'">
                        <div *ngIf="message.params.oembed.links && message.params.oembed.links.thumbnail"
                          style="position: relative;">
                          <ion-icon *ngIf="message.params.oembed.links && message.params.oembed.links.player" ios="play"
                            md="play-sharp" color="white" [ngClass]="'instant-message-video-play-button-' + platform"
                            [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                          <img [style.width]="defaultItemWidth+'px'" [style.height]="(defaultItemHeight - 100)+'px'"
                            style="border-radius: 10px 10px 0px 0px; object-fit: cover;" imageType="url"
                            placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''" imageHeight="''"
                            imageWidth="''" />
                        </div>
                        <div class="instant-message-url-reader-view-content-{{platform}}"
                          *ngIf="message.params.oembed.meta.description">
                          <div *ngIf="message.params.oembed.meta.title"
                            class="instant-message-url-reader-view-content-title">
                            <strong>{{message.params.oembed.meta.title}}</strong>
                          </div>
                          <div *ngIf="message.params.oembed.meta.description"
                            class="instant-message-url-reader-view-content-desc">
                            {{message.params.oembed.meta.description}}
                          </div>
                          <div *ngIf="message.params.oembed.meta.site" class="instant-message-url-reader-view-footer">
                            <ion-grid class="ion-no-padding">
                              <ion-row class="ion-no-padding">
                                <ion-col class="ion-no-padding ion-text-left">
                                  {{'@' + message.params.oembed.meta.site}}
                                </ion-col>
                                <ion-col class="ion-no-padding ion-text-right">
                                  {{message.params.oembed.meta.date | dateTimeFromIso | dateTimeToFormat:'ll'}}
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                        </div>
                      </div>

                      <!-- Player view -->

                      <div style="float: left; cursor: pointer;" [style.width]="defaultItemWidth+'px'"
                        (click)="openUrl(message)"
                        *ngIf="message.params.oembed.meta.media && message.params.oembed.meta.media == 'player'">
                        <div *ngIf="message.params.oembed.links && message.params.oembed.links.player"
                          [style.width]="defaultItemWidth+'px'"
                          style="background-color: black; border-radius: 10px 10px 0px 0px;">
                          <div *ngIf="message.params.oembed.links.thumbnail" style="position: relative;">
                            <ion-icon *ngIf="message.params.oembed.links && message.params.oembed.links.player"
                              ios="play" md="play-sharp" color="white"
                              [ngClass]="'instant-message-video-play-button-' + platform"
                              [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                            <img [style.width]="defaultItemWidth+'px'" [style.height]="(defaultItemHeight - 100)+'px'"
                              style="border-radius: 10px 10px 0px 0px; object-fit: cover;" imageType="url"
                              placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''"
                              imageHeight="{{defaultItemWidth}}" imageWidth="''" />
                          </div>
                          <div *ngIf="!message.params.oembed.links.thumbnail" [style.width]="defaultItemWidth+'px'"
                            style="position: relative; height: 200px;">
                            <ion-icon ios="play" md="play-sharp" color="white"
                              [ngClass]="'instant-message-video-play-button-' + platform"
                              [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                          </div>
                        </div>
                        <div class="instant-message-url-player-view-content">
                          <div *ngIf="message.params.oembed.meta.title"
                            class="instant-message-url-player-view-content-title">
                            <strong>{{message.params.oembed.meta.title}}</strong>
                          </div>
                          <div *ngIf="message.params.oembed.meta.site" class="instant-message-url-player-view-footer">
                            <ion-grid class="ion-no-padding">
                              <ion-row class="ion-no-padding">
                                <ion-col class="ion-no-padding ion-text-left">
                                  {{'@' + message.params.oembed.meta.site}}
                                </ion-col>
                                <ion-col class="ion-no-padding ion-text-right">
                                  {{message.params.oembed.meta.date | dateTimeFromIso | dateTimeToFormat:'ll'}}
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                          <div *ngIf="!message.params.oembed.meta.site"
                            class="instant-message-url-player-view-footer-text">
                            <strong>{{message.text.split('/')[message.text.split('/').length - 1]}}</strong>
                          </div>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>

                  <!-- Gif message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'gif'">
                    <ion-col class="ion-no-padding">
                      <div class="instant-message-gif-{{platform}}" style="float: left;">
                        <img imageType="gif" imageUrl="{{message.message_type_id}}" imageDate="{{message.date_updated}}"
                          imageHeight="{{defaultItemHeight - 100}}" imageWidth="{{defaultItemWidth}}"
                          placeholderImage="''" width="{{defaultItemWidth}}" height="{{defaultItemHeight - 100}}"
                          class="instant-message-gif-picture">
                        <div [style.width]="defaultItemWidth" class="instant-message-gif-logo-giphy-{{platform}}">
                          <img src="assets/img/logo-giphy.svg" style="height: 16px;">
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>

                  <!-- Picture message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'picture'">
                    <ion-col class="ion-no-padding">
                      <div [style.width]="defaultItemWidth" [style.height]="defaultItemHeight"
                        style="border-radius: 10px; float: left; cursor: pointer;">
                        <img imageType="support" imageUrl="uploads/{{message.message_type_id}}.webp"
                          (click)="downloadAttachment(message.message_type_id + '.webp')"
                          imageDate="{{message.date_updated}}" placeholderImage="''" imageHeight="300" imageWidth="300"
                          width="{{defaultItemWidth}}" height="{{defaultItemHeight}}"
                          style="border-radius: 10px; float: left; object-fit: cover;">
                      </div>
                    </ion-col>
                  </ion-row>

                  <!-- Video message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'video'">
                    <ion-col class="ion-no-padding">
                      <div [style.width]="defaultItemWidth" [style.height]="defaultItemHeight"
                        style="border-radius: 10px; float: left; position: relative; cursor: pointer;">
                        <ion-icon (click)="downloadAttachment(message.message_type_id + '.mp4')" ios="play"
                          md="play-sharp" color="white" [ngClass]="'instant-message-video-play-button-' + platform"
                          [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                        <video imageType="support" imageUrl="uploads/{{message.message_type_id}}.mp4"
                          (click)="downloadAttachment(message.message_type_id + '.mp4')"
                          imageDate="{{message.date_updated}}" placeholderImage="''" imageHeight="300" imageWidth="300"
                          width="{{defaultItemWidth}}" height="{{defaultItemHeight}}"
                          style="border-radius: 10px; float: left; background: black; object-fit: cover;"></video>
                      </div>
                    </ion-col>
                  </ion-row>

                  <ion-row class="ion-no-padding" style="padding-top: 20px;"
                    *ngIf="(message.message_type == 'picture' || message.message_type == 'video') && message.text.length > 0">
                    <ion-col class="ion-no-padding instant-message-same-language-receiver">
                      <span style="font-size: 16px; color: #4A4A4A; font-weight: 300; white-space: pre-line;"
                        [innerHTML]="message.translation | emoji"></span>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>

        <!-- Instant message - Sender -->

        <ion-item *ngIf="message.sender != 'support' && message.sender != 'chat'" class="ion-text-wrap ion-text-right"
          detail="false" style="border: none; --padding-bottom: 8px; --padding-top: 8px;">
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
              <ion-col class="ion-no-padding">

                <!-- Text message -->

                <ion-grid class="ion-no-padding" style="float: right; text-align: right;">
                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'text' || !message.message_type">
                    <ion-col style="width: 100%;" class="ion-no-padding instant-message-same-language-sender">
                      <span style="font-size: 16px; color: #4A4A4A; font-weight: 300; white-space: pre-line;"
                        [innerHTML]="message.text | emoji"></span>
                    </ion-col>
                  </ion-row>

                  <!-- Emoji message -->

                  <ion-row class="ion-no-padding" *ngIf="(message.message_type == 'emoji')">
                    <ion-col class="ion-no-padding">
                      <span style="color: #000;"
                        [style.font-size]="(plt.is('desktop') || plt.is('tablet')) ? '60px' : '54px'"
                        [innerHTML]="message.text | emoji:true"></span>
                    </ion-col>
                  </ion-row>

                  <!-- URL message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'url'">
                    <ion-col class="ion-no-padding">

                      <!-- File view -->

                      <div
                        *ngIf="!message.params.oembed.meta.media && message.params.oembed.links.file && !message.params.oembed.meta.title"
                        (click)="openUrl(message)" style="float: right;" [style.max-width]="defaultItemWidth+'px'"
                        class="instant-message-url-file-view">
                        <ion-grid class="ion-no-padding">
                          <ion-row>
                            <ion-col class="ion-no-padding ion-text-right"
                              style="margin-top: 3px !important; margin-right: 8px;">
                              <span
                                style="font-size: 16px; color: white;">{{message.text.split('/')[message.text.split('/').length
                                - 1]}}</span>
                            </ion-col>
                            <ion-col style="max-width: 15px; min-width: 15px;" class="ion-no-padding ion-text-right">
                              <ion-icon style="color: white; font-size: 22px;" ios="document" md="document-sharp">
                              </ion-icon>
                            </ion-col>
                          </ion-row>
                        </ion-grid>
                      </div>

                      <!-- Social view -->

                      <div style="float: right; cursor: pointer;" [style.width]="defaultItemWidth+'px'"
                        (click)="openUrl(message)"
                        *ngIf="!message.params.oembed.meta.media && message.params.oembed.links && message.params.oembed.meta.title">
                        <div *ngIf="message.params.oembed.links && message.params.oembed.links.thumbnail"
                          style="position: relative;">
                          <ion-icon *ngIf="message.params.oembed.links && message.params.oembed.links.player" ios="play"
                            md="play-sharp" color="white" [ngClass]="'instant-message-video-play-button-' + platform"
                            [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                          <img [style.width]="defaultItemWidth+'px'" [style.height]="(defaultItemHeight - 100)+'px'"
                            style="border-radius: 10px 10px 0px 0px; object-fit: cover;" imageType="url"
                            placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''"
                            imageHeight="300" imageWidth="300" />
                        </div>
                        <div *ngIf="message.params.oembed.links && !message.params.oembed.links.thumbnail"
                          class="instant-message-url-social-view-icon" [style.width]="defaultItemWidth+'px'">
                          <img *ngIf="message.params.oembed.links.icon" [style.max-width]="defaultItemWidth+'px'"
                            [style.height]="(defaultItemHeight - 100)+'px'" style="object-fit: cover;" imageType="url"
                            placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''"
                            imageHeight="300" imageWidth="300" />
                        </div>
                        <div class="instant-message-url-social-view-desc"
                          *ngIf="message.params.oembed.meta.description">
                          <div *ngIf="message.params.oembed.meta.title"
                            class="instant-message-url-social-view-desc-title">
                            <strong>{{message.params.oembed.meta.title}}</strong>
                          </div>
                          <div *ngIf="message.params.oembed.meta.description"
                            class="instant-message-url-social-view-desc-two">
                            {{message.params.oembed.meta.description}}
                          </div>
                          <div *ngIf="message.params.oembed.meta.site" class="instant-message-url-social-view-footer">
                            <ion-grid class="ion-no-padding">
                              <ion-row class="ion-no-padding">
                                <ion-col class="ion-no-padding ion-text-left">
                                  {{'@' + message.params.oembed.meta.site}}
                                </ion-col>
                                <ion-col class="ion-no-padding ion-text-right">
                                  {{message.params.oembed.meta.date | dateTimeFromIso | dateTimeToFormat:'ll'}}
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                        </div>
                      </div>

                      <!-- Reader view -->

                      <div style="float: right; cursor: pointer;" [style.width]="defaultItemWidth+'px'"
                        (click)="openUrl(message)"
                        *ngIf="message.params.oembed.meta.media && message.params.oembed.meta.media == 'reader'">
                        <div *ngIf="message.params.oembed.links && message.params.oembed.links.thumbnail"
                          style="position: relative;">
                          <ion-icon *ngIf="message.params.oembed.links && message.params.oembed.links.player" ios="play"
                            md="play-sharp" color="white" [ngClass]="'instant-message-video-play-button-' + platform"
                            [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                          <img [style.width]="defaultItemWidth+'px'" [style.height]="(defaultItemHeight - 100)+'px'"
                            style="border-radius: 10px 10px 0px 0px; object-fit: cover;" imageType="url"
                            placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''"
                            imageHeight="300" imageWidth="300" />
                        </div>
                        <div class="instant-message-url-reader-view-content-{{platform}}"
                          *ngIf="message.params.oembed.meta.description">
                          <div *ngIf="message.params.oembed.meta.title"
                            class="instant-message-url-reader-view-content-title">
                            <strong>{{message.params.oembed.meta.title}}</strong>
                          </div>
                          <div *ngIf="message.params.oembed.meta.description"
                            class="instant-message-url-reader-view-content-desc">
                            {{message.params.oembed.meta.description}}
                          </div>
                          <div *ngIf="message.params.oembed.meta.site" class="instant-message-url-reader-view-footer">
                            <ion-grid class="ion-no-padding">
                              <ion-row class="ion-no-padding">
                                <ion-col class="ion-no-padding ion-text-left">
                                  {{'@' + message.params.oembed.meta.site}}
                                </ion-col>
                                <ion-col class="ion-no-padding ion-text-right">
                                  {{message.params.oembed.meta.date | dateTimeFromIso | dateTimeToFormat:'ll'}}
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                        </div>
                      </div>

                      <!-- Player view -->

                      <div style="float: right; cursor: pointer;" [style.width]="defaultItemWidth+'px'"
                        (click)="openUrl(message)"
                        *ngIf="message.params.oembed.meta.media && message.params.oembed.meta.media == 'player'">
                        <div *ngIf="message.params.oembed.links && message.params.oembed.links.player"
                          [style.width]="defaultItemWidth+'px'"
                          style="background-color: black; border-radius: 10px 10px 0px 0px;">
                          <div *ngIf="message.params.oembed.links.thumbnail" style="position: relative;">
                            <ion-icon *ngIf="message.params.oembed.links && message.params.oembed.links.player"
                              ios="play" md="play-sharp" color="white"
                              [ngClass]="'instant-message-video-play-button-' + platform"
                              [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                            <img [style.width]="defaultItemWidth+'px'" [style.height]="(defaultItemHeight - 100)+'px'"
                              style="border-radius: 10px 10px 0px 0px; object-fit: cover;" imageType="url"
                              placeholderImage="''" imageUrl="{{message.message_type_id}}" imageDate="''"
                              imageHeight="300" imageWidth="300" />
                          </div>
                          <div *ngIf="!message.params.oembed.links.thumbnail" [style.width]="defaultItemWidth+'px'"
                            style="position: relative; height: 200px;">
                            <ion-icon ios="play" md="play-sharp" color="white"
                              [ngClass]="'instant-message-video-play-button-' + platform"
                              [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                          </div>
                        </div>
                        <div class="instant-message-url-player-view-content">
                          <div *ngIf="message.params.oembed.meta.title"
                            class="instant-message-url-player-view-content-title">
                            <strong>{{message.params.oembed.meta.title}}</strong>
                          </div>
                          <div *ngIf="message.params.oembed.meta.site" class="instant-message-url-player-view-footer">
                            <ion-grid class="ion-no-padding">
                              <ion-row class="ion-no-padding">
                                <ion-col class="ion-no-padding ion-text-left">
                                  {{'@' + message.params.oembed.meta.site}}
                                </ion-col>
                                <ion-col class="ion-no-padding ion-text-right">
                                  {{message.params.oembed.meta.date | dateTimeFromIso | dateTimeToFormat:'ll'}}
                                </ion-col>
                              </ion-row>
                            </ion-grid>
                          </div>
                          <div *ngIf="!message.params.oembed.meta.site"
                            class="instant-message-url-player-view-footer-text">
                            <strong>{{message.text.split('/')[message.text.split('/').length - 1]}}</strong>
                          </div>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>

                  <!-- Gif message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'gif'">
                    <ion-col class="ion-no-padding">
                      <div class="instant-message-gif-{{platform}}" style="float: right;">
                        <img imageType="gif" imageUrl="{{message.message_type_id}}" imageDate="{{message.date_updated}}"
                          imageHeight="{{defaultItemWidth}}" imageWidth="{{defaultItemHeight - 100}}"
                          width="{{defaultItemWidth}}" height="{{defaultItemHeight - 100}}" placeholderImage="''"
                          class="instant-message-gif-picture">
                        <div [style.width]="defaultItemWidth" class="instant-message-gif-logo-giphy-{{platform}}">
                          <img src="assets/img/logo-giphy.svg" style="height: 16px;">
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>

                  <!-- Picture message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'picture'">
                    <ion-col class="ion-no-padding">
                      <div [style.width]="defaultItemWidth" [style.height]="defaultItemHeight"
                        style="border-radius: 10px; float: right; cursor: pointer;">
                        <img imageType="support" imageUrl="uploads/{{message.message_type_id}}.webp"
                          (click)="downloadAttachment(message.message_type_id + '.webp')"
                          imageDate=" {{message.date_updated}}" placeholderImage="''" imageHeight="300" imageWidth="300"
                          width="{{defaultItemWidth}}" height="{{defaultItemHeight}}"
                          style="border-radius: 10px; float: right; object-fit: cover;">
                      </div>
                    </ion-col>
                  </ion-row>

                  <!-- Video message -->

                  <ion-row class="ion-no-padding" *ngIf="message.message_type == 'video'">
                    <ion-col class="ion-no-padding">
                      <div [style.width]="defaultItemWidth" [style.height]="defaultItemHeight"
                        style="border-radius: 10px; float: right; position: relative; cursor: pointer;">
                        <ion-icon (click)="downloadAttachment(message.message_type_id + '.mp4')" ios="play"
                          md="play-sharp" color="white" [ngClass]="'instant-message-video-play-button-' + platform"
                          [style.font-size]="(defaultItemWidth == 250) ? '24px' : '34px'"></ion-icon>
                        <video imageType="support" imageUrl="uploads/{{message.message_type_id}}.mp4"
                          (click)="downloadAttachment(message.message_type_id + '.mp4')"
                          imageDate=" {{message.date_updated}}" placeholderImage="''" imageHeight="300" imageWidth="300"
                          width="{{defaultItemWidth}}" height="{{defaultItemHeight}}"
                          style="border-radius: 10px; float: right; background: black; object-fit: cover;"></video>
                      </div>
                    </ion-col>
                  </ion-row>

                  <ion-row class="ion-no-padding" style="padding-top: 20px; float: right;"
                    *ngIf="(message.message_type == 'picture' || message.message_type == 'video') && message.text.length > 0">
                    <ion-col size="auto" style="width: 100%; text-align: left;"
                      class="ion-no-padding instant-message-same-language-sender">
                      <span style="font-size: 16px; color: #4A4A4A; font-weight: 300; white-space: pre-line;"
                        [innerHTML]="message.text | emoji"></span>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>

              <!-- Message status -->

              <ion-col style="text-align: center; margin-left: 6px; margin-top: 0px;"
                class="ion-no-padding {{(plt.is('desktop') || plt.is('tablet')) ? 'avatar-tablet' : 'avatar-mobile'}}">
                <ion-icon ios="checkmark-circle" md="checkmark-circle-sharp" color="primary" *ngIf="message.is_read">
                </ion-icon>
                <ion-icon name="checkmark-circle-outline" color="{{(!message.is_received) ? 'medium': 'primary'}}"
                  *ngIf="!message.is_read"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-item-sliding>
    </ion-list>
  </div>
</ion-content>

<!-- Toolbar -->

<ion-footer [hidden]="showConversationDetails == false"
  [ngClass]="'im-footer-' + ((plt.is('ios')) ? 'ios' : 'android') + ' ion-no-border'">

  <!-- Attachments -->

  <div *ngIf="newAttachments.length > 0" class="new-email-attachment">
    <ion-chip (click)="deleteNewAttachment(index)" *ngFor="let attachment of newAttachments; let index = index;"
      color="medium">
      <ion-label style="margin-top: 2px;">{{attachment.name}}</ion-label>
      <ion-icon name="close-circle-outline" (click)="deleteNewAttachment(index)">
      </ion-icon>
    </ion-chip>
  </div>

  <ion-toolbar [class.im-footer-transparent]="!plt.is('ios')">
    <ion-grid [ngClass]="'ion-no-padding im-footer-grid-' + ((plt.is('ios')) ? 'ios' : 'android')">
      <ion-row [hidden]="conversation && conversation.is_resolved"
        [ngClass]="'ion-no-padding im-footer-grid-row-' + ((plt.is('ios')) ? 'ios' : 'android')"
        style="border-radius: 24px;" [style.background-color]="'white'">

        <!-- Attachment icon -->

        <ion-col class="ion-no-padding" style="max-width: 46px; padding-left: 4px;"
          [style.padding-top]="(plt.is('ios')) ? '3px' : '5px'">
          <ion-button (click)="addAttachment()" color="medium" style="margin: 0px; width: 46px; height: 40px;"
            expand="full" class="ion-no-padding" fill="clear">
            <ion-icon ios="attach" md="attach-sharp" slot="icon-only"
              [style.font-size]="(plt.is('ios')) ? '2rem' : '2rem'"></ion-icon>
          </ion-button>
        </ion-col>

        <!-- Text input -->

        <ion-col class="ion-no-padding">
          <ion-list lines="none" style="margin: 0; padding: 0px;">
            <ion-item detail="false" class="ion-no-padding" style="--ion-border-color: white;">
              <div style="width:100%;">
                <elastic-textarea #txtChat (click)="messageInputSelectedAction()"
                  (ionChange)="changeMessageText($event)" (keyup.enter)="onTextareaEnterPressed()"
                  placeholder="{{'placeholder_type_message' | translate}}" lineHeight="38" class="messageTextarea">
                </elastic-textarea>
              </div>
            </ion-item>
          </ion-list>
        </ion-col>

        <!-- Emoji Toolbar -->

        <ion-col class="ion-no-padding" style="max-width: 46px;"
          [style.padding-top]="(plt.is('ios') && !plt.is('ipad')) ? '4px' : '6px'">
          <ion-button (click)="emojiToolbarAction()" color="medium" style="margin: 0px; width: 40px; height: 40px;"
            expand="full" class="ion-no-padding" fill="clear">
            <ion-icon *ngIf="!showEmojiToolbar" slot="icon-only" name="happy-outline"></ion-icon>
            <img *ngIf="showEmojiToolbar" src="assets/img/icon-keyboard.svg"
              style="max-width: 70%; margin-bottom: 2px;" />
          </ion-button>
        </ion-col>

        <!-- Submit button -->

        <ion-col class="ion-no-padding" style="text-align: center;"
          [style.max-width]="(plt.is('ios')) ? '47px' : '47px'" [style.padding-top]="(plt.is('ios')) ? '3px' : '3px'">
          <ion-button (click)="send()" style="margin: 0px; box-shadow: none;"
            [style.width]="(plt.is('ios')) ? '38px' : '42px'" [style.height]="(plt.is('ios')) ? '38px' : '42px'"
            [style.margin-right]="(plt.is('ios')) ? '0px' : '1px'" color="primary" class="ion-no-padding" shape="round">
            <ion-icon name="send-sharp" [style.color]="'white'" slot="icon-only"
              [style.margin-top]="(plt.is('ios')) ? '2px' : '3px'"
              [style.font-size]="(plt.is('ios')) ? '1.5rem' : '1.4rem'"
              [style.margin-left]="(plt.is('ios')) ? '-2px' : '5px'"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="conversation && conversation.is_resolved"
        [ngClass]="'ion-no-padding im-footer-grid-row-' + ((plt.is('ios')) ? 'ios' : 'android')"
        style="border-radius: 24px; color: black; height: 48px; padding-top: 18px; color: #989aa2;"
        [style.background-color]="'white'">
        <ion-col class="ion-no-padding" style="text-align: center;">
          {{'label_conversation_ended' | translate}}
        </ion-col>
      </ion-row>
    </ion-grid>

    <!-- Emoji nav bar -->

    <ion-row *ngIf="showEmojiToolbar" style="background-color: #005C72; padding: 0px 10px 0px 10px;"
      class="ion-no-padding">
      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(0)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/img/icon-time-{{(plt.is('android')) ? 'md' : 'ios'}}.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 0) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(1)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/people.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 1) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(2)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/nature.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 2) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(3)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/foods.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 3) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(4)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/activity.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 4) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(5)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/travel.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 5) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(6)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/objects.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 6) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(7)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/symbols.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 7) ? '1' : '.7'" />
        </ion-button>
      </ion-col>

      <ion-col style="padding: 7px;" class="ion-no-padding">
        <ion-button (click)="selectEmojiCategory(8)"
          style="margin: 0px; box-shadow: none; --padding-start: 0px; --padding-end: 0px;" color="primary" expand="full"
          icon-only fill="clear">
          <img src="assets/lib/joypixels/other/category_icons/flags.svg" style="max-width: 100%;"
            [style.opacity]="(sliderIndex == 8) ? '1' : '.7'" />
        </ion-button>
      </ion-col>
    </ion-row>

    <!-- Emoji list -->

    <swiper #swiper [pagination]="false" *ngIf="showEmojiToolbar" (slideChange)="onSlideChanged()"
      style="background-color: #F9F9F9; width: 100%;">
      <ng-template swiperSlide>
        <ion-content color="emoji-toolbar"
          class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 0" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.recent; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, true)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 1" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['people']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 2" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['nature']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.png" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 3" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['food']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 4" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['activity']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 5" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['travel']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 6" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['objects']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 7" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['symbols']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>

      <ng-template swiperSlide>
        <ion-content class="emoji-list-{{platform}} {{(plt.is('tablet')) ? 'emoji-list-tablet-' + platform : ''}}">
          <ul *ngIf="sliderIndex == 8" class="flex-container wrap">
            <li class="flex-item" *ngFor="let emoji of emojiService.emojisByCategories['flags']; let index = index;">
              <ion-button (click)="selectEmoji(emoji.id, $event, false)" color="emoji-toolbar"
                style="margin: 0px; background-color: #F9F9F9; box-shadow: none; height: 100%;" class="ion-no-padding"
                expand="full" fill="clear">
                <img src="assets/lib/noto-emoji/svg/emoji_u{{emoji.id}}.svg" style="width: 100%; padding: 4px;" />
              </ion-button>
            </li>
          </ul>
        </ion-content>
      </ng-template>
    </swiper>
  </ion-toolbar>
</ion-footer>