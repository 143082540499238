import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'lxDifference'
})
export class LuxonDifferencePipe implements PipeTransform {

  transform(start: string, end: string, as: 'days' | 'minutes' | 'hours' | 'seconds'): number {
    if (end == null) {
      end = DateTime.now().toISO();
    }
    if (start) {
      start = DateTime.now().toISO();
    }

    return DateTime.fromISO(start).diff(DateTime.fromISO(end)).as(as);
  }

}
