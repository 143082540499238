import { ToastController, Platform, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import { DateTime, Duration } from 'luxon';
import { timer } from 'rxjs';

import { ConnectivityService } from './connectivity.service';
import { KeychainService } from './keychain.service';
import { GlobalService } from './global.service';
import { RecentService } from './recent.service';
import { LoggerService } from './logger.service';
import { Events } from './events.service';

import { Store } from '@ngrx/store';
import { UiState } from '../ngrx/ui-state';
import { ApplicationState } from '../ngrx/application-state';
import { ContactsData, RecentData, AccountData } from '../ngrx/store-data';
import { SupportModalPage } from '../pages/support-modal/support-modal.page';

declare let Notification: any;

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  private friendIsTypingTimer: any;

  private friendsTypingTimer = {};

  private friendIsTypingTimerSeconds = 0;

  private showCallRequestScreen = false;

  private connectivityService: ConnectivityService;

  private recentService: RecentService;

  private loggerService: LoggerService;

  private keychainService: KeychainService;

  private globalService: GlobalService;

  constructor(private events: Events, private router: Router, private translate: TranslateService, private plt: Platform,
    private store: Store<ApplicationState>, private toastCtrl: ToastController,
    private injector: Injector, private modalCtrl: ModalController) { }

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.recentService = this.injector.get(RecentService);
        this.loggerService = this.injector.get(LoggerService);
        this.keychainService = this.injector.get(KeychainService);
        this.globalService = this.injector.get(GlobalService);
      }

      const notificationService = this.connectivityService.feathers.service('user-notifications');

      // Service events

      notificationService.removeListener('created');
      notificationService.on('created', (item) => {
        this.loggerService.logMessage('Notification created', item, 'notifications.service', 'info');

        // Handle notification

        this.loadNotificationCounter(item.id, item);

        // Play sound

        setTimeout(() => {
          if (item.type == 'instant_message') {
            try {
              new Audio('assets/sounds/message_received.mp3').play();
            } catch (e) { }
          }
        }, 200);
      });

      notificationService.removeListener('removed');
      notificationService.on('removed', (item) => {
        Sentry.addBreadcrumb({
          message: 'Notification removed',
          category: 'notifications.service',
          data: item,
          level: 'info'
        });

        // Update message counter

        if (item.notification_type == 'instant_message') {
          this.store.dispatch({
            type: 'REMOVE_UNREAD_MESSAGE_ACTION',
            payload: item
          });
        }
      });

      // Load history data

      notificationService.find().then(async (notifications) => {

        // Reset notifications

        this.store.dispatch({
          type: 'RESET_UNREAD_MESSAGES_ACTION',
          payload: undefined
        });

        // Generate notification counter

        if (notifications && notifications.length > 0) {
          for (const key in notifications) {
            const notification = notifications[key];
            await this.loadNotificationCounter(notification.id, notification);
          }
        }

        console.log('Notifications synced');
        resolve({});
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  async loadNotificationCounter(key, notification): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('Notification: ' + notification.notification_type);
        console.log(notification);

        const contactsData: ContactsData = await this.store.select('contactsData').pipe(first()).toPromise();
        const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
        const recentData: RecentData = await this.store.select('recentData').pipe(first()).toPromise();
        const accountData = await this.store.select('accountData').pipe(first()).toPromise();

        if (notification.notification_type == 'instant_message') {

          // Display local web notification

          if (uiState.appIsRunningInBackground) {
            this.showLocalNotification(notification);
          }

          this.store.dispatch({
            type: 'REMOVE_FRIEND_TYPING_ACTION',
            payload: notification.data.sender
          });

          if (notification.data.sender == recentData.selectedContact.id) {
            this.events.publish('chat:scroll', {});

            // Mark message as read

            if (!notification.data.is_read) {
              try {
                await this.recentService.markMessageAsRead([notification.data.message_id]);
                this.deleteNotification(key);
              } catch (error) {
                console.error(error);
              }
            }

          } else {

            // Subtotal counter

            this.store.dispatch({
              type: 'ADD_UNREAD_MESSAGE_ACTION',
              payload: notification
            });

            // Mark message as received

            if (!notification.data.is_received && !notification.data.is_read) {
              try {
                await this.recentService.markMessageAsReceived(notification.data.message_id);
              } catch (error) {
                console.log('Error markMessageAsReceived:');
                console.error(error);
              }
            }
          }

        } else if (notification.notification_type == 'instant_message_recording_transcript') {

          this.events.publish('instant-message:audio-transcript', notification);

        } else if (notification.notification_type == 'friend_started_typing') {

          const diff = DateTime.now().diff(DateTime.fromISO(notification.date_created)).as('seconds');

          // Process only when not older than 10 seconds

          if (contactsData.list[notification.sender] && diff < 10) {

            this.store.dispatch({
              type: 'ADD_FRIEND_TYPING_ACTION',
              payload: notification
            });

            // Create timer

            if (this.friendIsTypingTimerSeconds == 0) {
              this.friendsTypingTimer[notification.sender] = 0;
              this.friendIsTypingTimer = timer(1000, 1000).subscribe((t) => {
                let activeTimers = 0;

                Object.keys(this.friendsTypingTimer).forEach((key) => {
                  if (this.friendsTypingTimer[key] == 59) {
                    delete this.friendsTypingTimer[key];
                    this.store.dispatch({
                      type: 'REMOVE_FRIEND_TYPING_ACTION',
                      payload: key
                    });
                  } else {
                    this.friendsTypingTimer[key] = this.friendsTypingTimer[key] + 1;
                    activeTimers++;
                  }
                });

                if (activeTimers == 0) {
                  this.friendIsTypingTimerSeconds = 0;
                  this.friendIsTypingTimer.unsubscribe();
                }
              });
            }
          }

        } else if (notification.notification_type == 'friend_ended_typing') {

          this.store.dispatch({
            type: 'REMOVE_FRIEND_TYPING_ACTION',
            payload: notification.sender
          });

          if (this.friendsTypingTimer[notification.sender]) {
            delete this.friendsTypingTimer[notification.sender];
          }

          // if (contactsData.list[notification.sender].is_online) {
          //   this.events.publish('im:typing:ended', notification);
          // }

          // Stop timer

          const keys = Object.keys(this.friendsTypingTimer);
          if (keys.length == 0 && this.friendIsTypingTimer) {
            this.friendIsTypingTimer.unsubscribe();
            this.friendIsTypingTimerSeconds = 0;
          }

        } else if (notification.notification_type == 'voip_client_connected') {

          this.store.dispatch({
            type: 'SET_UI_VARIABLE_ACTION',
            payload: {
              variable: 'voiceCallId',
              value: notification.params.id
            }
          });

        } else if (notification.notification_type == 'voice_call_status_in_progress') {

          this.events.publish('voice-call:in-progress', notification);

        } else if (notification.notification_type == 'voice_call_status_busy') {

          this.events.publish('voice-call:status-busy', notification);

        } else if (notification.notification_type == 'voice_call_status_failed') {

          this.events.publish('voice-call:status-failed', notification);

        } else if (notification.notification_type == 'voice_call_status_no_answer') {

          this.events.publish('voice-call:status-no-answer', notification);

        } else if (notification.notification_type == 'voice_call_status_completed') {

          this.events.publish('voice-call:status-completed', notification);

        } else if (notification.notification_type == 'voice_call_dictation_tempoary_results') {

          this.events.publish('voice-call:dictation-temporary-results', notification);

        } else if (notification.notification_type == 'voice_call_dictation_results') {

          this.events.publish('voice-call:dictation-results', notification);

        } else if (notification.notification_type == 'voice_call_dictation_ended_with_error') {

          this.events.publish('voice-call:dictation-ended-with-error', notification);

        } else if (notification.notification_type == 'voip_call_request_send') {

          // Check if request is not older than 60 seconds

          const diff = DateTime.now().diff(DateTime.fromISO(notification.date_created)).as('seconds');
          if (diff < 60) {
            this.showCallRequestScreen = true;
            setTimeout(() => {
              if (this.showCallRequestScreen) {

                // Display local web notification

                if (uiState.appIsRunningInBackground) {
                  this.showLocalNotification(notification);
                }

                // Open voip call dialog

                this.store.dispatch({
                  type: 'SET_BATCH_UI_VARIABLE_ACTION',
                  payload: {
                    voipCallRequestIsMadeByUser: false,
                    voipCallFriendId: notification.friend_id,
                    voipCallIsAudioOnly: notification.audio_only,
                    voipCallIsActive: false,
                    voipCallDialogIsActive: true,
                    voipCallId: notification.call_id,
                    voipCallIceServers: notification.ice_servers
                  }
                });

                this.router.navigate(['/voip-call']);
              }
            }, 1000);
          }

        } else if (notification.notification_type == 'support_message') {

          this.deleteNotification(key);
          if (!uiState.supportWindowIsOpen) {
            let unreadSupportMessages = await this.keychainService.get('unreadSupportMessages');
            unreadSupportMessages = notification.data.user_messages_unread;
            await this.keychainService.set('unreadSupportMessages', unreadSupportMessages);

            this.store.dispatch({
              type: 'SET_UI_VARIABLE_ACTION',
              payload: {
                variable: 'supportMessagesUnread',
                value: unreadSupportMessages
              }
            });

            // Display local web notification

            // if (uiState.appIsRunningInBackground) {
            //   this.showLocalNotification(notification);
            // }
          }

        } else if (notification.notification_type == 'topup_successful' || notification.notification_type == 'topup_failed'
          || notification.notification_type == 'refund_complete' || notification.notification_type == 'transcription_complete'
          || notification.notification_type == 'transcription_failed' || notification.notification_type == 'transcription_shared'
          || notification.notification_type == 'media_effects_completed' || notification.notification_type == 'media_effects_failed'
          || notification.notification_type == 'system_notification_general' || notification.notification_type == 'system_notification_alert'
          || notification.notification_type == 'system_notification_completed') {

          this.store.dispatch({
            type: 'ADD_SYSTEM_MESSAGE_ACTION',
            payload: notification
          });

          if (!this.plt.is('capacitor')) {
            if (uiState.appIsRunningInBackground) {
              let content = '', title = '';
              switch (notification.notification_type) {
                case 'topup_successful': content = this.translate.instant('payment_status_message_successful', {
                  currency: (notification.currency == 'eur') ? '€' : '$',
                  amount: notification.amount,
                  paymentMethod: this.translate.instant('label_transaction_' + notification.payment_method)
                });
                  title = this.translate.instant('title_payment_status_message_successful');
                  break;
                case 'topup_failed': content = this.translate.instant('payment_status_message_failed', {
                  currency: (accountData.details.currency == 'eur') ? '€' : '$',
                  amount: notification.amount,
                  paymentMethod: this.translate.instant('label_transaction_' + notification.payment_method)
                });
                  title = this.translate.instant('title_payment_status_message_failed');
                  break;
                case 'refund_complete': content = this.translate.instant('payment_status_message_refund_completed', {
                  currency: (notification.currency == 'eur') ? '€' : '$',
                  amount: notification.amount,
                  paymentMethod: this.translate.instant('label_transaction_' + notification.payment_method)
                });
                  title = this.translate.instant('title_payment_status_message_refund_completed');
                  break;
                case 'transcription_complete': content = this.translate.instant('transcription_status_message_completed', {
                  file: notification.file
                });
                  title = this.translate.instant('title_transcription_status_message_completed');
                  break;
                case 'transcription_failed': content = this.translate.instant('transcription_status_message_failed', {
                  file: notification.file
                });
                  title = this.translate.instant('title_transcription_status_message_failed');
                  break;
                case 'transcription_shared': content = this.translate.instant('transcription_status_message_shared', {
                  file: notification.file, user: notification.user
                });
                  title = this.translate.instant('title_transcription_status_message_shared');
                  break;
                case 'media_effects_completed': content = this.translate.instant('transcription_status_message_media_effect_completed', {
                  file: notification.file
                });
                  title = this.translate.instant('title_transcription_status_message_media_effect_completed');
                  break;
                case 'media_effects_failed': content = this.translate.instant('transcription_status_message_media_effect_failed', {
                  file: notification.file
                });
                  title = this.translate.instant('title_transcription_status_message_media_effect_failed');
                  break;
                case 'system_notification_general': content = notification.content; title = notification.title;
                  break;
                case 'system_notification_alert': content = notification.content; title = notification.title;
                  break;
                case 'system_notification_completed': content = notification.content; title = notification.title;
                  break;
              }

              const hasPushNotificationsEnabled = await this.keychainService.get('hasPushNotificationsEnabled');
              if (hasPushNotificationsEnabled) {
                const options = {
                  body: content,
                  icon: 'https://storage.googleapis.com/eu.app.lingu.social/assets/logo-notification.png',
                  image: '',
                  tag: notification.id,
                  data: notification
                };

                // Display notification

                const n = new Notification(title, options);
                setTimeout(n.close.bind(n), 8000);
              } else {
                // const toast = await this.toastCtrl.create({
                //   message: (notification.notification_type == 'topup_successful') ? this.translate.instant('text_topup_successful') : this.translate.instant('text_topup_failed'),
                //   duration: 8000,
                //   position: 'top',
                //   buttons: [
                //     {
                //       text: this.translate.instant('popup_ok_button_label'),
                //       role: 'cancel',
                //       handler: () => { }
                //     }
                //   ]
                // });
                // toast.present();
              }
            }

          } else {

            // Send notification to back to settings

            // setTimeout(() => {
            //   this.events.publish('topup:' + ((notification.notification_type == 'topup_successful') ? 'successful' : 'failed'), notification);
            // }, 1000);

            // Close browser window

            // this.globalService.closeInAppBrowser();

            // setTimeout(async () => {
            //   const toast = await this.toastCtrl.create({
            //     message: (notification.notification_type == 'topup_successful') ? this.translate.instant('text_topup_successful') : this.translate.instant('text_topup_failed'),
            //     duration: 8000,
            //     position: 'top',
            //     buttons: [
            //       {
            //         text: this.translate.instant('popup_ok_button_label'),
            //         role: 'cancel',
            //         handler: () => { }
            //       }
            //     ]
            //   });
            //   toast.present();
            // }, 1000);
          }

          this.deleteNotification(key);

        } else {
          this.deleteNotification(key);
        }

        resolve();
      } catch (error) {
        console.log(error);
      }
    });
  }

  async showLocalNotification(notification) {
    try {
      const hasPushNotificationsEnabled: any = await this.keychainService.get('hasPushNotificationsEnabled');
      const contactsData = await this.store.select('contactsData').pipe(first()).toPromise();
      const recentData = await this.store.select('recentData').pipe(first()).toPromise();

      let title, icon, options;
      if (hasPushNotificationsEnabled) {

        // Web notification

        if (!this.plt.is('capacitor')) {
          if (notification.notification_type == 'instant_message') {

            title = contactsData.list[notification.data.recent_id].full_name;
            icon = (contactsData.list[notification.data.recent_id].has_picture) ? 'https://lingusocial-media-eu.ams3.digitaloceanspaces.com/users/' +
              notification.data.recent_id + '-100x100.webp' : 'https://dev.lingu.social/assets/img/logo-512x512.png';

            options = {
              body: notification.data.translation,
              icon: icon,
              image: '',
              tag: notification.id,
              data: notification
            };

            switch (notification.data.message_type) {
              case 'location':
                if (notification.data.params.name.length > 0) {
                  options.body = notification.data.params.name;
                } else if (notification.data.params.address.length > 0) {
                  options.body = notification.data.params.address;
                } else {
                  options.body = this.translate.instant('label_location');
                }
                options.image = 'https://lingusocial-media-eu.ams3.digitaloceanspaces.com/media/' + notification.data.message_id + '.webp';
                break;
              case 'gif':
                options.body = this.translate.instant('text_gif');
                options.image = 'https://media.giphy.com/media/' + notification.data.message_type_id + '/giphy.gif';
                break;
              case 'audio':
                options.body = (notification.data.translation.length > 0) ? notification.data.translation : this.translate.instant('text_audio');
                break;
              case 'video':
                options.body = (notification.data.translation.length > 0) ? notification.data.translation : this.translate.instant('text_video');
                break;
              case 'picture':
                options.body = (notification.data.translation.length > 0) ? notification.data.translation : this.translate.instant('text_picture');
                options.image = 'https://lingusocial-media-eu.ams3.digitaloceanspaces.com/media/' + notification.message_id + '.webp';
                break;
            }

          } else if (notification.notification_type == 'support_message') {

            title = this.translate.instant('label_customer_support');
            icon = 'https://dev.lingu.social/assets/img/logo-512x512.png';

            options = {
              body: notification.data.translation,
              icon: icon,
              image: '',
              tag: notification.id,
              data: notification
            };

          } else if (notification.notification_type == 'voip_call_request_send') {

            title = contactsData.list[notification.friend_id].full_name;
            icon = (contactsData.list[notification.friend_id].has_picture) ? 'https://lingusocial-media-eu.ams3.digitaloceanspaces.com/users/' +
              notification.friend_id + '-100x100.webp' : 'https://dev.lingu.social/assets/img/logo-512x512.png';

            options = {
              body: this.translate.instant('label_is_calling_you'),
              icon: icon,
              image: '',
              tag: notification.id,
              data: notification
            };
          }

          // Display notification

          const n = new Notification(title, options);
          n.onclick = async (error) => {
            if (n.data.notification_type == 'instant_message') {

              console.log(n);
              console.log(n.data);

              this.store.dispatch({
                type: 'RESET_CONTACT_SELECTION_ACTION',
                payload: ''
              });

              this.store.dispatch({
                type: 'SELECT_RECENT_CONTACT_ACTION',
                payload: {
                  type: 'contact',
                  id: n.data.data.recent_id,
                  actionType: 'text',
                  locale: contactsData.list[n.data.data.recent_id].language
                }
              });

              if (!this.plt.is('desktop') && !this.plt.is('tablet')) {
                this.router.navigate(['/instant-messages']);
              } else {
                this.events.publish('chat:selected', { previousId: recentData.selectedContact.id });
              }

            } else if (n.data.notification_type == 'support') {

              const modal = await this.modalCtrl.create({
                component: SupportModalPage,
                cssClass: (this.plt.is('desktop') || this.plt.is('tablet')) ? 'custom-modal' : '',
              });
              await modal.present();

            } else if (n.data.notification_type == 'voip_call_request_send') {

              setTimeout(() => {
                const hasAccepted = (n.action == 'accept') ? true : false;
                this.events.publish('voip-call-p2p:push-notification-action', { hasAccepted: hasAccepted });
              }, 1000);
            }

            window.focus();
            error.target.close();
          };
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  deleteNotification(id): Promise<any> {
    return this.connectivityService.feathers.service('user-notifications').remove(id, {});
  }
}
