import { ConfigData, INITIAL_CONFIG_DATA } from '../store-data';
import { DateTime } from 'luxon';

import {
  UPDATE_CONFIG_ACTION, RESET_CONFIG_ACTION, UpdateConfigAction, ResetConfigAction
} from '../actions/config';
import { Action } from '@ngrx/store';

export function configData(state: ConfigData, action: Action): ConfigData {
  switch (action.type) {
    case UPDATE_CONFIG_ACTION:
      return handleUpdateConfigAction(state, <any>action);
    case RESET_CONFIG_ACTION:
      return handleResetConfigAction(state, <any>action);
    default:
      return state;
  }
}

function handleUpdateConfigAction(state: ConfigData, action: UpdateConfigAction) {
  const newState = (state) ? Object.assign({}, state) : INITIAL_CONFIG_DATA;
  let newLastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_CONFIG_DATA.lastUpdatedTimestamp;

  for (const key in action.payload) {
    newState[action.payload[key].id] = action.payload[key];
    if (action.payload[key].date_updated > newLastUpdatedTimestamp || newLastUpdatedTimestamp == undefined) {
      newLastUpdatedTimestamp = action.payload[key].date_updated;
    }
  }

  console.log('DATE UPDATED: ' + DateTime.now().toISO());

  newState.lastUpdatedTimestamp = DateTime.now().toISO();
  return newState;
}

function handleResetConfigAction(state: ConfigData, action: ResetConfigAction) {
  return INITIAL_CONFIG_DATA;
}
