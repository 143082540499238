import { Action } from '@ngrx/store';

export const ADD_SUPPORT_ITEM_ACTION = 'ADD_SUPPORT_ITEM_ACTION';
export const SET_SUPPORT_ITEMS_ACTION = 'SET_SUPPORT_ITEMS_ACTION';
export const RESET_SUPPORT_ACTION = 'RESET_SUPPORT_ACTION';

export const ADD_UNREAD_MESSAGE_ACTION = 'ADD_UNREAD_MESSAGE_ACTION';
export const REMOVE_UNREAD_MESSAGE_ACTION = 'REMOVE_UNREAD_MESSAGE_ACTION';
export const RESET_UNREAD_MESSAGES_ACTION = 'RESET_UNREAD_MESSAGES_ACTION';

export class AddSupportItemAction implements Action {
  readonly type = ADD_SUPPORT_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class SetSupportItemsAction implements Action {
  readonly type = SET_SUPPORT_ITEMS_ACTION;
  constructor(public payload?: any) { }
}

export class ResetSupportAction implements Action {
  readonly type = RESET_SUPPORT_ACTION;
  constructor(public payload?: any) { }
}

export class AddUnreadMessageAciton implements Action {
  readonly type = ADD_UNREAD_MESSAGE_ACTION;
  constructor(public payload?: any) { }
}

export class RemoveUnreadMessageAction implements Action {
  readonly type = REMOVE_UNREAD_MESSAGE_ACTION;
  constructor(public payload?: any) { }
}

export class ResetUnreadMessagesAction implements Action {
  readonly type = RESET_UNREAD_MESSAGES_ACTION;
  constructor(public payload?: any) { }
}
