import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';
import { first } from 'rxjs/operators';

import { Device } from '@capacitor/device';
import { KeychainService } from './keychain.service';
import { AccountService } from './account.service';
import { ContactsService } from './contacts.service';
import { RecentService } from './recent.service';
import { PricingService } from './pricing.service';
import { PhrasebookService } from './phrasebook.service';
import { NotificationsService } from './notifications.service';
import { GlobalizationService } from './globalization.service';
import { ConnectivityService } from './connectivity.service';
import { TranscriptionService } from './transcription.service';
import { TranscriptionsExplorerService } from './transcriptions-explorer.service';

import { ApplicationState } from '../ngrx/application-state';
import { AccountData } from '../ngrx/store-data';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class DataSynchronizationService {

  private connectivityService: ConnectivityService;

  private keychainService: KeychainService;

  private accountService: AccountService;

  private contactsService: ContactsService;

  private recentService: RecentService;

  private pricingService: PricingService;

  private phrasebookService: PhrasebookService;

  private notificationsService: NotificationsService;

  private globalizationService: GlobalizationService;

  private transcriptionService: TranscriptionService;

  private transcriptionsExplorerService: TranscriptionsExplorerService;

  constructor(private store: Store<ApplicationState>, private plt: Platform, private injector: Injector, private http: HttpClient) { }

  async loadAllData(): Promise<void> {
    if (!this.connectivityService) {
      this.connectivityService = this.injector.get(ConnectivityService);
      this.keychainService = this.injector.get(KeychainService);
      this.accountService = this.injector.get(AccountService);
      this.contactsService = this.injector.get(ContactsService);
      this.recentService = this.injector.get(RecentService);
      this.pricingService = this.injector.get(PricingService);
      this.phrasebookService = this.injector.get(PhrasebookService);
      this.notificationsService = this.injector.get(NotificationsService);
      this.globalizationService = this.injector.get(GlobalizationService);
      this.transcriptionService = this.injector.get(TranscriptionService);
      this.transcriptionsExplorerService = this.injector.get(TranscriptionsExplorerService);
    }

    const appIsFirstSync = await this.keychainService.get('appIsFirstSync');
    if (appIsFirstSync) {
      await this.keychainService.set('appIsFirstSync', false);
      try {

        // Set default language

        await this.accountService.load();
        const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();
        if (accountData.details.language.length > 0) {
          this.globalizationService.setDefaultLanguage('');
        }

        await this.contactsService.load();
        await this.recentService.load();
        await this.transcriptionService.load();
        await this.transcriptionsExplorerService.load();
        await this.pricingService.load();
        // await this.phrasebookService.load();
        await this.notificationsService.load();
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    } else {

      try {
        await Promise.all([
          this.notificationsService.load(),
          // this.phrasebookService.load(),
          this.pricingService.load(),
          this.recentService.load(),
          this.transcriptionService.load(),
          this.transcriptionsExplorerService.load(),
          this.contactsService.load(),
          this.accountService.load()
        ]);

        const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();
        if (accountData.details.language.length > 0) {
          this.globalizationService.setDefaultLanguage('');
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    }

    // Update metadata

    this.updateMetadata();
  }

  async updateMetadata() {

    // Update device infos

    this.sendDeviceInfo();

    // Update Onesignal tracking details

    const userId = await this.keychainService.get('user_id');
    if (this.plt.is('capacitor')) {
      // OneSignal.setExternalUserId(userId);
    } else {
      // this.oneSignal.setExternalUserId(userId);
    }
  }
  async sendDeviceInfo() {
    try {
      const devices = this.connectivityService.feathers.service('user-devices');
      const deviceId = await this.keychainService.get('device_id');
      const info = await Device.getInfo();

      const deviceInfo = {
        device_id: deviceId,
        model: info.model,
        platform: info.platform,
        version: info.osVersion,
        browser: window.navigator.userAgent,
        ip_address: this.connectivityService.ip
      };

      Sentry.addBreadcrumb({
        message: 'sendDeviceInfo',
        category: 'data-synchronization.service',
        data: {
          deviceInfo: deviceInfo
        },
        level: 'info'
      });

      await devices.create(deviceInfo);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  }

  resetDataStorage() {
    // this.store.dispatch({
    //   type: 'RESET_UI_VARIABLES_ACTION',
    //   payload: ''
    // });

    this.store.dispatch({
      type: 'RESET_ACCOUNT_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_CONFIG_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_CONTACTS_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_HISTORY_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_NOTIFICATIONS_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_PHRASEBOOK_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_RECENT_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_SUPPORT_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_LIVE_EVENTS_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_LIVE_VOCABULARY_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_TRANSCRIPTS_EXPLORER_ACTION',
      payload: ''
    });

    this.store.dispatch({
      type: 'RESET_TRANSCRIPTS_ACTION',
      payload: ''
    });
  }
}
