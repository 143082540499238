import { TranscriptsData, INITIAL_TRANSCRIPTS_DATA } from '../store-data';
import {
  ADD_TRANSCRIPT_ITEM_ACTION, UPDATE_TRANSCRIPT_ITEM_ACTION, DELETE_TRANSCRIPT_ITEM_ACTION, RESET_TRANSCRIPTS_ACTION,
  AddTranscriptItemAction, UpdateTranscriptItemAction, DeleteTranscriptItemAction, ResetTransacriptsAction
} from '../actions/transcripts';

import { Action } from '@ngrx/store';
import _ from 'lodash';

export function transcriptsData(state: TranscriptsData, action: Action): TranscriptsData {
  switch (action.type) {
    case ADD_TRANSCRIPT_ITEM_ACTION:
      return handleAddTranscriptItemAction(state, <any>action);
    case UPDATE_TRANSCRIPT_ITEM_ACTION:
      return handleUpdateTranscriptItemAction(state, <any>action);
    case DELETE_TRANSCRIPT_ITEM_ACTION:
      return handleDeleteTranscriptItemAction(state, <any>action);
    case RESET_TRANSCRIPTS_ACTION:
      return handleResetTranscriptsAction(state, <any>action);
    default:
      return state;
  }
}

function handleAddTranscriptItemAction(state: TranscriptsData, action: AddTranscriptItemAction) {
  const newData = (state) ? Object.assign({}, state.data) : INITIAL_TRANSCRIPTS_DATA.data;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_TRANSCRIPTS_DATA.lastUpdatedTimestamp;
  action = Object.assign({}, action);

  for (const key in action.payload) {
    newData[action.payload[key].id] = action.payload[key];
    if (action.payload[key].date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
      lastUpdatedTimestamp = action.payload[key].date_updated;
    }
  }

  const newStoreState: TranscriptsData = {
    data: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleUpdateTranscriptItemAction(state: TranscriptsData, action: UpdateTranscriptItemAction) {
  const newData = (state) ? Object.assign({}, state.data) : INITIAL_TRANSCRIPTS_DATA.data;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_TRANSCRIPTS_DATA.lastUpdatedTimestamp;

  if (newData[action.payload.id]) {
    newData[action.payload.id] = action.payload;
    if (action.payload.date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
      lastUpdatedTimestamp = action.payload.date_updated;
    }
  }

  const newStoreState: TranscriptsData = {
    data: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleDeleteTranscriptItemAction(state: TranscriptsData, action: DeleteTranscriptItemAction) {
  const newData = (state) ? Object.assign({}, state.data) : INITIAL_TRANSCRIPTS_DATA.data,
    lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_TRANSCRIPTS_DATA.lastUpdatedTimestamp;

  if (newData[action.payload.id]) {
    delete newData[action.payload.id];
  }

  const newStoreState: TranscriptsData = {
    data: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleResetTranscriptsAction(state: TranscriptsData, action: ResetTransacriptsAction) {
  return INITIAL_TRANSCRIPTS_DATA;
}