
import { Injectable, Injector } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';

import { Events } from './events.service';
import { LoggerService } from './logger.service';
import { KeychainService } from './keychain.service';
import { ConnectivityService } from './connectivity.service';

import { ApplicationState } from '../ngrx/application-state';
import { RecentData, HistoryData } from '../ngrx/store-data';

@Injectable({
  providedIn: 'root',
})
export class RecentService {

  private connectivityService: ConnectivityService;

  private loggerService: LoggerService;

  private keychainService: KeychainService;

  constructor(private events: Events, private store: Store<ApplicationState>, private injector: Injector) { }

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.keychainService = this.injector.get(KeychainService);
        this.loggerService = this.injector.get(LoggerService);
      }

      try {
        const history = this.connectivityService.feathers.service('user-history');
        const recent = this.connectivityService.feathers.service('user-recent');

        // Recent event listener

        recent.removeListener('removed');
        recent.removeListener('updated');
        recent.on('updated', (data) => {
          this.loggerService.logMessage('Recent updated ', data, 'recent.service', 'info');
          this.store.dispatch({
            type: 'UPDATE_RECENT_ACTION',
            payload: data
          });
        });

        recent.on('removed', (data) => {
          this.loggerService.logMessage('Recent removed ', data, 'recent.service', 'info');
          this.store.dispatch({
            type: 'REMOVE_RECENT_ACTION',
            payload: data
          });
        });

        // Sync recent data

        const recentData: RecentData = await this.store.select('recentData').pipe(first()).toPromise();
        const historyData: HistoryData = Object.assign({}, await this.store.select('historyData').pipe(first()).toPromise());
        const userId = await this.keychainService.get('user_id');

        console.log(recentData);

        let results = await recent.get(userId);
        if (!recentData.lastUpdatedTimestamp || results.date_updated > recentData.lastUpdatedTimestamp) {
          this.store.dispatch({
            type: 'UPDATE_RECENT_ACTION',
            payload: results
          });
        }

        console.log('Recent synced');

        // History event listener

        history.removeListener('created');
        history.on('created', async (data) => {
          this.loggerService.logMessage('History item created', data, 'recent.service', 'info');
          this.store.dispatch({
            type: 'ADD_HISTORY_ITEM_ACTION',
            payload: [data]
          });

          const recentData: RecentData = await this.store.select('recentData').pipe(first()).toPromise();
          if (recentData.selectedContact.id == data.recent_id) {
            this.events.publish('chat:scroll', undefined);
          }
        });

        history.removeListener('updated');
        history.on('updated', (data) => {
          this.loggerService.logMessage('History item updated', data, 'recent.service', 'info');
          this.store.dispatch({
            type: 'UPDATE_HISTORY_ITEM_ACTION',
            payload: data
          });
        });

        history.removeListener('removed');
        history.on('removed', (data) => {
          this.loggerService.logMessage('History item removed', data, 'recent.service', 'info');
          this.store.dispatch({
            type: 'DELETE_HISTORY_ITEM_ACTION',
            payload: data
          });
        });

        // Sync history data

        if (!historyData.lastUpdatedTimestamp) {
          historyData.lastUpdatedTimestamp = '';
        }

        results = await history.find((!historyData || !historyData.lastUpdatedTimestamp) ? {} : {
          query: {
            date_updated: {
              $gt: historyData.lastUpdatedTimestamp
            }
          }
        });

        if (results && results.data.length > 0) {
          this.store.dispatch({
            type: 'ADD_HISTORY_ITEM_ACTION',
            payload: results.data
          });
        }

        console.log('History synced');
        resolve({});
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  markMessageAsReceived(messageId): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'markMessageAsReceived',
      category: 'recent.service',
      data: {
        action: 'mark-message-as-received',
        messageId: messageId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-recent').create({
      action: 'mark-message-as-received',
      message_id: messageId
    }, {});
  }

  markMessageAsRead(messageIds = []): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'markMessageAsRead',
      category: 'recent.service',
      data: {
        action: 'mark-message-as-read',
        message_ids: messageIds
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-recent').create({
      action: 'mark-message-as-read',
      message_ids: messageIds
    }, {});
  }

  deleteItemFromHistory(messageId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      Sentry.addBreadcrumb({
        message: 'deleteItemFromHistory',
        category: 'recent.service',
        data: {
          action: 'delete-item',
          message_id: messageId
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('user-recent').create({
        action: 'delete-item',
        message_id: messageId
      }, {}).then(() => {
        // this.events.publish('im:message:deleted', '');
        resolve({});
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  deleteFullHistory(friendId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      Sentry.addBreadcrumb({
        message: 'deleteFullHistory',
        category: 'recent.service',
        data: {
          action: 'delete-history',
          friend_id: friendId
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('user-recent').create({
        action: 'delete-history',
        friend_id: friendId
      }, {}).then(() => {

        // Delete notifications

        // if (this.subtotalUnreadMessages[this.selectedContact.id]) {
        //   for (let key in this.subtotalUnreadMessageItems[this.selectedContact.id]) {
        //     NotificationsService.deleteNotification(key);
        //   }
        // }

        resolve({});
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }
}
