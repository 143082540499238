import { ContactsData, INITIAL_CONTACTS_DATA } from '../store-data';
import { Contact } from '../../models/contact';
import {
  SYNC_CONTACTS_ACTION, UPDATE_CONTACT_ACTION, REMOVE_CONTACT_ACTION, CONTACT_STARTED_TYPING_ACTION, CONTACT_FINISHED_TYPING_ACTION, RESET_CONTACTS_ACTION,
  SyncContactsAction, UpdateContactAction, ContactStartedTypingAction, ContactFinishedTypingAction, ResetContactsAction
} from '../actions/contacts';
import { Action } from '@ngrx/store';
import * as _ from 'lodash';

export function contactsData(state: ContactsData, action: Action): ContactsData {
  switch (action.type) {
    case SYNC_CONTACTS_ACTION:
      return handleSyncedContactsAction(state, <any>action);
    case UPDATE_CONTACT_ACTION:
      return handleUpdatedContactAction(state, <any>action);
    case REMOVE_CONTACT_ACTION:
      return handleRemovedContactAction(state, <any>action);
    case CONTACT_STARTED_TYPING_ACTION:
      return handleContactStartedTypingAction(state, <any>action);
    case CONTACT_FINISHED_TYPING_ACTION:
      return handleContactFinishedTypingAction(state, <any>action);
    case RESET_CONTACTS_ACTION:
      return handleResetContactsAction(state, <any>action);
    default:
      return state;
  }
}

function handleSyncedContactsAction(state: ContactsData, action: SyncContactsAction) {
  const newContacts = (state) ? Object.assign({}, state.list) : INITIAL_CONTACTS_DATA.list,
    newContactsWithPhoneNumbers = (state) ? Object.assign({}, state.listWithPhoneNumbers) : INITIAL_CONTACTS_DATA.listWithPhoneNumbers;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_CONTACTS_DATA.lastUpdatedTimestamp;

  for (const key in action.payload) {
    const contact: Contact = _.cloneDeep(action.payload[key]);
    contact.is_editing = (newContacts[contact.id]) ? newContacts[contact.id].is_editing : false;
    contact.is_typing = (newContacts[contact.id]) ? newContacts[contact.id].is_typing : false;

    newContacts[action.payload[key].id] = contact;
    newContactsWithPhoneNumbers[action.payload[key].phone_number] = contact;
    if (action.payload[key].date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
      lastUpdatedTimestamp = action.payload[key].date_updated;
    }
  }

  const newStoreState: ContactsData = {
    list: newContacts,
    listWithPhoneNumbers: newContactsWithPhoneNumbers,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleUpdatedContactAction(state: ContactsData, action: UpdateContactAction) {
  const newContacts = (state) ? Object.assign({}, state.list) : INITIAL_CONTACTS_DATA.list,
    newContactsWithPhoneNumbers = (state) ? Object.assign({}, state.listWithPhoneNumbers) : INITIAL_CONTACTS_DATA.listWithPhoneNumbers,
    contact: Contact = _.cloneDeep(action.payload);
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_CONTACTS_DATA.lastUpdatedTimestamp;

  contact.is_editing = (newContacts[contact.id]) ? newContacts[contact.id].is_editing : false;
  contact.is_typing = (newContacts[contact.id]) ? newContacts[contact.id].is_typing : false;
  newContacts[action.payload.id] = contact;
  newContactsWithPhoneNumbers[action.payload.phone_number] = contact;
  lastUpdatedTimestamp = action.payload.date_updated;

  const newStoreState: ContactsData = {
    list: newContacts,
    listWithPhoneNumbers: newContactsWithPhoneNumbers,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleRemovedContactAction(state: ContactsData, action: UpdateContactAction) {
  const newContacts = (state) ? Object.assign({}, state.list) : INITIAL_CONTACTS_DATA.list,
    newContactsWithPhoneNumbers = (state) ? Object.assign({}, state.listWithPhoneNumbers) : INITIAL_CONTACTS_DATA.listWithPhoneNumbers;
  const lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_CONTACTS_DATA.lastUpdatedTimestamp;
  delete newContacts[action.payload.id];

  const newStoreState: ContactsData = {
    list: newContacts,
    listWithPhoneNumbers: newContactsWithPhoneNumbers,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleContactStartedTypingAction(state: ContactsData, action: ContactStartedTypingAction) {
  if (state) {
    const newList = Object.assign({}, state.list);
    if (newList[action.payload]) {
      newList[action.payload].is_typing = true;
    }

    const newStoreState: ContactsData = {
      list: newList,
      listWithPhoneNumbers: state.listWithPhoneNumbers,
      lastUpdatedTimestamp: state.lastUpdatedTimestamp
    };

    return newStoreState;
  } else {
    return INITIAL_CONTACTS_DATA;
  }
}

function handleContactFinishedTypingAction(state: ContactsData, action: ContactFinishedTypingAction) {
  if (state) {
    const newList = Object.assign({}, state.list);
    if (newList[action.payload]) {
      newList[action.payload].is_typing = false;
    }

    const newStoreState: ContactsData = {
      list: newList,
      listWithPhoneNumbers: state.listWithPhoneNumbers,
      lastUpdatedTimestamp: state.lastUpdatedTimestamp
    };

    return newStoreState;
  } else {
    return INITIAL_CONTACTS_DATA;
  }
}

function handleResetContactsAction(state: ContactsData, action: ResetContactsAction) {
  return INITIAL_CONTACTS_DATA;
}
