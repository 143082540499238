import { UiState, INITIAL_UI_STATE } from '../ui-state';
import {
  SET_UI_VARIABLE_ACTION, SET_BATCH_UI_VARIABLE_ACTION, RESET_UI_VARIABLES_ACTION,
  SetUiVariableAction, SetBatchUiVariableAction, ResetUiVariableAction
} from '../actions/ui';

import * as _ from 'lodash';

export function uiState(state: UiState = INITIAL_UI_STATE, action: any): UiState {
  switch (action.type) {
    case SET_UI_VARIABLE_ACTION:
      return handleSetUiVariableAction(state, <any>action);
    case SET_BATCH_UI_VARIABLE_ACTION:
      return handleSetBatchUiVariableAction(state, <any>action);
    case RESET_UI_VARIABLES_ACTION:
      return handleResetUiVariableAction(state, <any>action);
    default:
      return state;
  }
}

function handleSetUiVariableAction(state: UiState, action: SetUiVariableAction) {
  const newState = (state) ? Object.assign({}, state) : INITIAL_UI_STATE;
  newState[action.payload.variable] = action.payload.value;
  return newState;
}

function handleSetBatchUiVariableAction(state: UiState, action: SetBatchUiVariableAction) {
  const newState = (state) ? Object.assign({}, state) : INITIAL_UI_STATE;
  const keys = _.keys(action.payload);
  for (const key in keys) {
    newState[keys[key]] = action.payload[keys[key]];
  }
  return newState;
}

function handleResetUiVariableAction(state: UiState, action: ResetUiVariableAction) {
  return INITIAL_UI_STATE;
}
