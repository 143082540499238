import { Preferences } from '@capacitor/preferences';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KeychainService {

  private internalDataCache: any = {};

  constructor() { }

  get(key): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.internalDataCache[key]) {
        const values = await Preferences.get({ key: key });
        if (values.value === null) {
          resolve(undefined);
        } else if (values.value.indexOf('STRING_') > -1) {
          resolve(values.value.replace('STRING_', ''));
        } else if (values.value.indexOf('BOOL_') > -1) {
          resolve((values.value.replace('BOOL_', '') === 'true') ? true : false);
        } else if (values.value.indexOf('NUMBER_') > -1) {
          resolve(parseFloat(values.value.replace('NUMBER_', '')));
        } else if (values.value.indexOf('OBJECT_') > -1) {
          resolve(JSON.parse(values.value.replace('OBJECT_', '')));
        }
      } else {
        resolve(this.internalDataCache[key]);
      }
    });
  }

  set(key, value) {
    return new Promise(async (resolve, reject) => {
      let valueString;
      if (typeof value === 'string') {
        valueString = 'STRING_' + value;
      } else if (typeof value === 'boolean') {
        valueString = 'BOOL_' + value.toString();
      } else if (typeof value === 'number') {
        valueString = 'NUMBER_' + value.toString();
      } else if (typeof value === 'object') {
        valueString = 'OBJECT_' + JSON.stringify(value);
      }
      this.internalDataCache[key] = value;
      await Preferences.set({ key: key, value: valueString });
      resolve({});
    });
  }

  flush(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.internalDataCache = {};
      Preferences.clear();
      resolve({});
    });
  }
}
