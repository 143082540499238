import { PhrasebookData } from '../store-data';
import { Phrasebook } from '../../models/phrasebook';
import {
  ADD_PHRASEBOOK_ACTION, REMOVE_PHRASEBOOK_ACTION, RESET_PHRASEBOOK_ACTION,
  AddPhrasebookAction, RemovePhrasebookAction, ResetPhrasebookAction
} from '../actions/phrasebook';
import { Action } from '@ngrx/store';

export function phrasebookData(state: PhrasebookData, action: Action): PhrasebookData {
  switch (action.type) {
    case ADD_PHRASEBOOK_ACTION:
      return handleAddPhrasebookAction(state, <any>action);
    case REMOVE_PHRASEBOOK_ACTION:
      return handleRemovePhrasebookAction(state, <any>action);
    case RESET_PHRASEBOOK_ACTION:
      return handleResetPhrasebookAction(state, <any>action);
    default:
      return state;
  }
}

function handleAddPhrasebookAction(state: PhrasebookData, action: AddPhrasebookAction) {
  const newPhrasebook = (state) ? Object.assign({}, state.list) : {};
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : undefined;

  for (const key in action.payload) {
    const phrasebook: Phrasebook = action.payload[key];
    newPhrasebook[action.payload[key].id] = phrasebook;
    if (action.payload[key].date_created > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
      lastUpdatedTimestamp = action.payload[key].date_created;
    }
  }

  const newStoreState: PhrasebookData = {
    list: newPhrasebook,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleRemovePhrasebookAction(state: PhrasebookData, action: RemovePhrasebookAction) {
  const newPhrasebook = (state) ? Object.assign({}, state.list) : {},
    lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : undefined;

  delete newPhrasebook[action.payload];

  const newStoreState: PhrasebookData = {
    list: newPhrasebook,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleResetPhrasebookAction(state: PhrasebookData, action: ResetPhrasebookAction) {
  const newStoreState: PhrasebookData = {
    list: {},
    lastUpdatedTimestamp: undefined
  };

  return newStoreState;
}
