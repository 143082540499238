import { Action } from '@ngrx/store';

export const UPDATE_ACCOUNT_DETAILS_ACTION = 'UPDATE_ACCOUNT_DETAILS_ACTION';
export const UPDATE_ACCOUNT_PROFILE_ACTION = 'UPDATE_ACCOUNT_PROFILE_ACTION';
export const UPDATE_ACCOUNT_TRANSACTIONS_ACTION = 'UPDATE_ACCOUNT_TRANSACTIONS_ACTION';
export const RESET_ACCOUNT_ACTION = 'RESET_ACCOUNT_ACTION';

export class UpdateAccountDetailsAction implements Action {
  readonly type = UPDATE_ACCOUNT_DETAILS_ACTION;
  constructor(public payload?: any) { }
}

export class UpdateAccountProfileAction implements Action {
  readonly type = UPDATE_ACCOUNT_PROFILE_ACTION;
  constructor(public payload?: any) { }
}

export class UpdateAccountTranscationsAction implements Action {
  readonly type = UPDATE_ACCOUNT_TRANSACTIONS_ACTION;
  constructor(public payload?: any) { }
}

export class ResetAccountAction implements Action {
  readonly type = RESET_ACCOUNT_ACTION;
  constructor(public payload?: any) { }
}
