import { Filesystem, Directory } from '@capacitor/filesystem';
import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import AwsS3 from '@uppy/aws-s3';
import { round } from 'lodash';
import Uppy from '@uppy/core';
import { nanoid } from 'nanoid';

import { ApplicationState } from '../ngrx/application-state';
import { GlobalService } from './global.service';
import { Events } from './events.service';

@Injectable({
  providedIn: 'root',
})
export class FileManagerService {

  private cache: Cache;

  private uppy: Uppy;

  private files = {};

  private globalService: GlobalService;

  constructor(private plt: Platform, private injector: Injector, private events: Events, private store: Store<ApplicationState>) { }

  private _initClient() {
    this.uppy = new Uppy({
      debug: true, autoProceed: false
    });

    this.uppy.use(AwsS3, {
      endpoint: 'https://upload-europe1.lingu.social',
      retryDelays: null
    });

    this.uppy.on('upload-error', (file: any, error, response) => {
      console.log('error with file:', file.id);
      console.log('error message:', error);
      this.files[file.data.id].hasError = true;
      this.files[file.data.id].isCompleted = true;
    });

    this.uppy.on('upload-progress', async (file: any, progress) => {
      const percentage = round((progress.bytesUploaded / (progress.bytesTotal / 100) * 100) / 100);
      if (percentage == 100 && !this.files[file.data.id].isCompleted) {
        this.files[file.data.id].isCompleted = true;
      }
    });
  }

  upload(file, extension, isResumable): Promise<{ id: string, ext: string }> {
    return new Promise(async (resolve, reject) => {
      try {
        Sentry.addBreadcrumb({
          message: 'uploadFile',
          category: 'file-upload.service',
          data: { file: file, extension: extension },
          level: 'info'
        });

        if (!this.uppy) {
          this._initClient();
        }

        const fileId = nanoid(22);
        const uppyFile: any = new File([file], fileId + '-' + file.name, { type: file.type });
        uppyFile.id = fileId; this.uppy.addFile(uppyFile);
        this.files[fileId] = { isCompleted: false, hasError: false };
        this.uppy.upload();

        // Create poll that checks if the file is uploaded

        const poll = setInterval(async () => {
          if (this.files[fileId].isCompleted) {
            if (!this.files[fileId].hasError) {
              clearInterval(poll);
              resolve({ id: fileId + '-' + file.name, ext: extension });
            } else {
              clearInterval(poll);
              reject();
            }
          }
        }, 1000);
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject();
      }
    });
  }

  download(url: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.plt.is('capacitor')) {
          if (!this.cache) {
            this.cache = await window.caches.open('lingusocial');
          }
          await this.cache.add(url);
          resolve(url);
        } else {
          const name = url.replace('https://', '').replace(/\//g, '-');
          const file = await this.downloadviaXHR(url, name);
          resolve(file);
        }
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  }

  get(url: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.plt.is('capacitor')) {
          if (!this.cache) {
            this.cache = await window.caches.open('lingusocial');
          }
          const response = await this.cache.match(url);
          resolve(response);
        } else {
          resolve({});
        }
      } catch (error) {
        console.error(error);
        resolve({});
      }
    });
  }

  private downloadviaXHR(url, name): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.globalService) {
          this.globalService = this.injector.get(GlobalService);
        }

        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = async (event) => {

          // Save to file

          const data = await this.globalService.convertBlobToBase64(xhr.response);
          await Filesystem.writeFile({
            path: name,
            data: data,
            directory: Directory.Cache
          });

          const localPath = await Filesystem.getUri({ path: name, directory: Directory.Cache });
          resolve(Capacitor.convertFileSrc(localPath.uri));
        };
        xhr.open('GET', url);
        xhr.send();
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  }
}
