export enum SupportChannels {
    Chat = 'chat',
    Email = 'email',
    Web = 'web',
    Whatsapp = 'whatsapp'
}

export enum SupportMessageType {
    Text = 'text',
    Emoji = 'emoji',
    URL = 'url',
    Video = 'video',
    Audio = 'audio',
    Picture = 'picture',
    Email = 'email',
    Gif = 'gif'
}

export enum SupportPriority {
    High = 1,
    Normal = 2,
    Low = 3
}

export interface SupportConversation {
    date_created: string;
    date_updated: string;
    id: string;
    is_assigned: boolean;
    language: string;
    messages: SupportConversationMessage[];
    support_user_id: string;
    support_full_name: string;
    user_id: string;
    priority: SupportPriority;
    is_snoozed: boolean;
    is_resolved: boolean;
    notes: SupportConversationNote[];
    channel: SupportChannels;
    user_messages_unread: number;
    support_messages_unread: number;
    full_name: string;
    tags: string[];
    is_anonymous: boolean;
    email: string;
    ticket_id: string;
}

export interface SupportConversationMessage {
    date_created: string;
    date_updated: string;
    id: string;
    is_received: boolean;
    is_read: boolean;
    from: string;
    to: string;
    message_type: SupportMessageType;
    message_type_id: string;
    receiver: string;
    sender: string;
    text: string;
    translation: string;
    data?: SupportConversationMessageEmail | any[];
}

export interface SupportConversationMessageEmail {
    id: string;
    type: 'email';
    subject: string;
    body: string;
    from: string;
    to: string;
    attachments: string[];
}

export interface SupportConversationNote {
    date_created: string;
    date_updated: string;
    id: string;
    message: string;
    user_id: string;
    full_name: string;
}
