import { Action } from '@ngrx/store';

export const ADD_UNREAD_MESSAGE_ACTION = 'ADD_UNREAD_MESSAGE_ACTION';
export const REMOVE_UNREAD_MESSAGE_ACTION = 'REMOVE_UNREAD_MESSAGE_ACTION';
export const RESET_UNREAD_MESSAGES_ACTION = 'RESET_UNREAD_MESSAGES_ACTION';
export const RESET_NOTIFICATIONS_ACTION = 'RESET_NOTIFICATIONS_ACTION';
export const ADD_FRIEND_TYPING_ACTION = 'ADD_FRIEND_TYPING_ACTION';
export const REMOVE_FRIEND_TYPING_ACTION = 'REMOVE_FRIEND_TYPING_ACTION';
export const ADD_SYSTEM_MESSAGE_ACTION = 'ADD_SYSTEM_MESSAGE_ACTION';
export const RESET_SYSTEM_NOTIFICATIONS_ACTION = 'RESET_SYSTEM_NOTIFICATIONS_ACTION';
export const RESET_SYSTEM_NOTIFICATIONS_COUNTER_ACTION = 'RESET_SYSTEM_NOTIFICATIONS_COUNTER_ACTION';

export class AddUnreadMessageAction implements Action {
  readonly type = ADD_UNREAD_MESSAGE_ACTION;
  constructor(public payload?: any) { }
}

export class RemoveUnreadMessageAction implements Action {
  readonly type = REMOVE_UNREAD_MESSAGE_ACTION;
  constructor(public payload?: any) { }
}

export class ResetUnreadMessagseAction implements Action {
  readonly type = RESET_UNREAD_MESSAGES_ACTION;
  constructor(public payload?: any) { }
}

export class ResetNotificationsAction implements Action {
  readonly type = RESET_NOTIFICATIONS_ACTION;
  constructor(public payload?: any) { }
}

export class AddFriendTypingAction implements Action {
  readonly type = ADD_FRIEND_TYPING_ACTION;
  constructor(public payload?: any) { }
}

export class RemoveFriendTypingAction implements Action {
  readonly type = REMOVE_FRIEND_TYPING_ACTION;
  constructor(public payload?: any) { }
}

export class AddSystemMessageAction implements Action {
  readonly type = ADD_SYSTEM_MESSAGE_ACTION;
  constructor(public payload?: any) { }
}

export class ResetSystemNotificationsCounterAction implements Action {
  readonly type = RESET_SYSTEM_NOTIFICATIONS_COUNTER_ACTION;
  constructor(public payload?: any) { }
}

export class ResetSystemNotificationsAction implements Action {
  readonly type = RESET_SYSTEM_NOTIFICATIONS_ACTION;
  constructor(public payload?: any) { }
}