import { HistoryData, INITIAL_HISTORY_DATA } from '../store-data';
import {
  ADD_HISTORY_ITEM_ACTION, UPDATE_HISTORY_ITEM_ACTION, DELETE_HISTORY_ITEM_ACTION, DELETE_FULL_HISTORY_ACTION, RESET_HISTORY_ACTION,
  AddHistoryItemAction, UpdateHistoryItemAction, DeleteHistoryItemAction, DeleteFullHistoryAction, ResetHistoryAction
} from '../actions/history';
import { Action } from '@ngrx/store';

import _ from 'lodash';

export function historyData(state: HistoryData, action: Action): HistoryData {
  switch (action.type) {
    case ADD_HISTORY_ITEM_ACTION:
      return handleAddHistoryItemAction(state, <any>action);
    case UPDATE_HISTORY_ITEM_ACTION:
      return handleUpdateHistoryItemAction(state, <any>action);
    case DELETE_HISTORY_ITEM_ACTION:
      return handleDeleteHistoryItemAction(state, <any>action);
    case DELETE_FULL_HISTORY_ACTION:
      return handleDeleteFullHistoryAction(state, <any>action);
    case RESET_HISTORY_ACTION:
      return handleResetHistoryAction(state, <any>action);
    default:
      return state;
  }
}

function handleAddHistoryItemAction(state: HistoryData, action: AddHistoryItemAction) {
  const newData = (state) ? Object.assign({}, state.data) : INITIAL_HISTORY_DATA.data;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_HISTORY_DATA.lastUpdatedTimestamp;
  action = Object.assign({}, action);
  action.payload =  _.orderBy(action.payload, 'date_created', 'asc');

  for (const key in action.payload) {
    if (!newData[action.payload[key].recent_id]) {
      newData[action.payload[key].recent_id] = [];
    }

    const index = _.findIndex(newData[action.payload[key].recent_id], (o: any) => { return o.id == action.payload[key].data.id; });
    if (index > -1) {
      newData[action.payload[key].recent_id][index] = action.payload[key].data;
    } else {
      newData[action.payload[key].recent_id].push(action.payload[key].data);
    }

    if (action.payload[key].date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
      lastUpdatedTimestamp = action.payload[key].date_updated;
    }
  }

  const newStoreState: HistoryData = {
    data: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleUpdateHistoryItemAction(state: HistoryData, action: UpdateHistoryItemAction) {
  const newData = (state) ? Object.assign({}, state.data) : INITIAL_HISTORY_DATA.data;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_HISTORY_DATA.lastUpdatedTimestamp;

  if (newData[action.payload.recent_id]) {
    const index = _.findIndex(newData[action.payload.recent_id], (o: any) => { return o.id == action.payload.data.id; });
    if (index > -1) {
      newData[action.payload.recent_id][index] = action.payload.data;
      if (action.payload.date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
        lastUpdatedTimestamp = action.payload.date_updated;
      }
    }
  }

  const newStoreState: HistoryData = {
    data: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleDeleteHistoryItemAction(state: HistoryData, action: DeleteHistoryItemAction) {
  const newData = (state) ? Object.assign({}, state.data) : INITIAL_HISTORY_DATA.data,
    lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_HISTORY_DATA.lastUpdatedTimestamp;

  if (newData[action.payload.recent_id]) {
    if (action.payload.removal_type == 'single') {

      // Delete single item

      const newHistory = _.remove(newData[action.payload.recent_id], (n: any) => {
        return n.id != action.payload.data.id;
      });
      newData[action.payload.recent_id] = newHistory;

    } else {

      // Delete all items

      delete newData[action.payload.recent_id];
    }
  }

  const newStoreState: HistoryData = {
    data: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleDeleteFullHistoryAction(state: HistoryData, action: DeleteFullHistoryAction) {
  // let newData = (state) ? Object.assign({}, state.data) : INITIAL_HISTORY_DATA.data,
  //   lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_HISTORY_DATA.lastUpdatedTimestamp;

  return state;
}

function handleResetHistoryAction(state: HistoryData, action: ResetHistoryAction) {
  return INITIAL_HISTORY_DATA;
}
