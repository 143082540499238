import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { KeychainService } from '../services/keychain.service';
import { ConnectivityService } from './connectivity.service';
import { Events } from './events.service';

import { Store } from '@ngrx/store';
import { ApplicationState } from '../ngrx/application-state';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationQrCodeService {

  private isInitiated = false;

  private connectivityService: ConnectivityService;

  private keychainService: KeychainService;

  constructor(private store: Store<ApplicationState>, private events: Events, private injector: Injector) { }

  init(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.keychainService = this.injector.get(KeychainService);
      }

      if (!this.isInitiated) {
        this.store.dispatch({
          type: 'SET_BATCH_UI_VARIABLE_ACTION', payload: {
            appIsAuthenticating: true
          }
        });

        try {
          await this.connectivityService.initializeWebsockets();
          this.connectivityService.feathers.service('qr-auth').on('created', async (data) => {
            Sentry.addBreadcrumb({
              message: 'on.created',
              category: 'auth-qr.service',
              data: data,
              level: 'info'
            });

            // Logout anonymously

            this.connectivityService.feathers.logout();

            // Autheticate user

            await this.connectivityService.feathers.authenticate({
              strategy: 'jwt',
              accessToken: data.token,
              type: 'jwt'
            });

            await this.keychainService.set('jwt', data.token);
            await this.keychainService.set('phone_number', data.phone_number);
            await this.keychainService.set('user_id', data.user_id);
            this.events.publish('login:successful', {});
            this.destroy();
          });

          this.isInitiated = true;
          resolve({});
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          reject(error);
        }
      } else {
        resolve({});
      }
    });
  }

  public destroy(): void {
    this.connectivityService.feathers.service('qr-auth').removeListener('created');
    this.isInitiated = false;
    this.store.dispatch({
      type: 'SET_UI_VARIABLE_ACTION', payload: {
        variable: 'appIsAuthenticating',
        value: false
      }
    });
  }

  doesNumberExist(number): Promise<any> {
    return new Promise((resolve, reject) => {
      Sentry.addBreadcrumb({
        message: 'doesNumberExist',
        category: 'auth.service',
        data: {
          action: 'check-if-new-number',
          phone_number: number
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('auth').create({
        action: 'check-if-new-number',
        phone_number: number
      }, {}).then((data) => {
        resolve(data.doesExist);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  authenticate(code) {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.keychainService = this.injector.get(KeychainService);
      }

      const deviceId = await this.keychainService.get('device_id');
      const userId = await this.keychainService.get('user_id');

      Sentry.addBreadcrumb({
        message: 'authenticate',
        category: 'auth-qr.service',
        data: {
          token: code,
          user_id: userId,
          device_id: deviceId
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('qr-auth').create({
        token: code,
        user_id: userId,
        device_id: deviceId
      }, {}).then((data) => {
        resolve(data);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  doesRequireTwilioVerification(phoneNumber) {
    return new Promise((resolve, reject) => {
      Sentry.addBreadcrumb({
        message: 'check-verification-status',
        category: 'auth-qr.service',
        data: {
          action: 'check-verification-status',
          phone_number: phoneNumber
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('auth').create({
        action: 'check-verification-status',
        phone_number: phoneNumber
      }, {}).then((data) => {
        resolve(data.needs_verification);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  initiateTwilioVerification(phoneNumber) {
    return new Promise(async (resolve, reject) => {
      Sentry.addBreadcrumb({
        message: 'start-verification',
        category: 'auth-qr.service',
        data: {
          action: 'start-verification',
          phone_number: phoneNumber
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('auth').create({
        action: 'start-verification',
        phone_number: phoneNumber
      }, {}).then((data) => {
        resolve(data.needs_verification);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }
}
