import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { DateTime } from 'luxon';

import { KeychainService } from '../services/keychain.service';
import { ConnectivityService } from './connectivity.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {

  private streamId = '';

  private keychainService: KeychainService;

  private connectivityService: ConnectivityService;

  constructor(private injector: Injector) {
    this.connectivityService = this.injector.get(ConnectivityService);
    this.keychainService = this.injector.get(KeychainService);
  }

  sendMessage(friendId, text, type, typeId, fileId, params): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'sendMessage',
      category: 'messaging.service',
      data: {
        friendId: friendId,
        text: text,
        type: type,
        typeId: typeId,
        fileId: fileId,
        params: params
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('messages').create({
      'action': 'send-instant-message',
      'friend_id': friendId,
      'text': text,
      'type': type,
      'type_id': typeId,
      'file_id': fileId,
      'params': params
    }, {});
  }

  forwardMessage(friendId, message): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'forwardMessage',
      category: 'messaging.service',
      data: {
        friendId: friendId,
        message: message
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('messages').create({
      'action': 'forward-message',
      'friend_id': friendId,
      'message': message
    }, {});
  }

  async messageTypingStarted(friendId): Promise<any> {
    const userId = await this.keychainService.get('user_id');

    Sentry.addBreadcrumb({
      message: 'messageTypingStarted',
      category: 'messaging.service',
      data: {
        friendId: friendId,
        userId: userId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-notifications').create({
      notification_type: 'friend_started_typing',
      date_created: DateTime.now().toISO(),
      sender: userId,
      user_id: friendId
    }, {});
  }

  async messageTypingEnded(friendId): Promise<any> {
    const userId = await this.keychainService.get('user_id');

    Sentry.addBreadcrumb({
      message: 'messageTypingEnded',
      category: 'messaging.service',
      data: {
        friendId: friendId,
        userId: userId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-notifications').create({
      'notification_type': 'friend_ended_typing',
      'sender': userId,
      'user_id': friendId
    }, {});
  }

  async generateMessageReplySuggestions(friendId: string): Promise<any> {
    const userId = await this.keychainService.get('user_id');

    console.log('generateMessageReplySuggestions');

    Sentry.addBreadcrumb({
      message: 'generate-message-replies',
      category: 'messaging.service',
      data: {
        friendId: friendId,
        userId: userId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('messages').create({
      'action': 'generate-message-replies',
      'friend_id': friendId
    }, {});
  }

  async sendBinary(streamId, token, data, tokenWit): Promise<any> {
    const hasNewStreamId = (this.streamId != streamId) ? true : false;
    if (hasNewStreamId) {
      this.streamId = streamId;
    }

    const userId = await this.keychainService.get('user_id');
    return this.connectivityService.socket.emit('instant-messages-send-binary', {
      'user_id': userId,
      'token': token,
      'stream_id': streamId,
      'data': data,
      'token_wit': (hasNewStreamId) ? tokenWit : ''
    });
  }

  async sendingBinaryCompleted(streamId, token): Promise<any> {
    const userId = await this.keychainService.get('user_id');
    return this.connectivityService.socket.emit('instant-messages-sending-binary-completed', {
      'user_id': userId,
      'stream_id': streamId,
      'token': token
    });
  }
}
