import { ModalController, AlertController, Platform, IonContent, PopoverController, ActionSheetController } from '@ionic/angular';
import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { FileManagerService } from '../../services/file-manager.service';
import { KeychainService } from '../../services/keychain.service';
import { SupportService } from '../../services/support.service';
import { GlobalService } from '../../services/global.service';
import { EmojiService } from '../../services/emoji.service';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '../../services/events.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import getUrls from 'get-urls';

import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use([Pagination]);

import { ConnectivityService } from '../../services/connectivity.service';
import { ApplicationState } from '../../ngrx/application-state';
import { SupportData } from 'src/app/ngrx/store-data';
import { AccountData } from '../../ngrx/store-data';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { nanoid } from 'nanoid';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

import { ImEmojiPopoverPage } from '../../popovers/im-emoji-popover/im-emoji-popover.page';
import { SupportMessageType, SupportChannels, SupportConversation } from '../../interfaces/support';

import { Browser } from '@capacitor/browser';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

interface NewAttachment {
  file: string;
  name: string;
}

@Component({
  selector: 'support-modal',
  templateUrl: './support-modal.page.html',
  styleUrls: ['./support-modal.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SupportModalPage implements OnInit {

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  @ViewChild(IonContent, { static: true }) content: IonContent;

  @ViewChild('txtChat', { static: true }) txtChat: any;

  showDismissButton = true;

  defaultItemWidth = 250;

  defaultItemHeight = 250;

  showConversationDetails = false;

  showConversationsList = false;

  conversations = [];

  messages = [];

  systemStatus = 'UP';

  systemDate = DateTime.now().toISO();

  news = [];

  faqItems = [];

  isSearchingfaq = false;

  faqSearchParam = '';

  email = '';

  conversation: SupportConversation;

  platform = '';

  showEmojiToolbar = false;

  sliderIndex: any = 0;

  newAttachments: NewAttachment[] = [];

  contentIsReady = true;

  private hasFileSelectionListener = false;

  private translations: any;

  private messageText: string = '';

  private isAnonymous = true;

  private fullName = '';

  constructor(private modalCtrl: ModalController, private translate: TranslateService, private events: Events, private store: Store<ApplicationState>,
    private alertCtrl: AlertController, private zone: NgZone, private supportService: SupportService, public plt: Platform, private cd: ChangeDetectorRef,
    private keychainService: KeychainService, public emojiService: EmojiService, private popoverCtrl: PopoverController, private router: Router,
    private fileManagerService: FileManagerService, private globalService: GlobalService, private connectivityService: ConnectivityService) {
  }

  ngOnInit() {
    if (this.router.url == '/support/support') {
      this.showDismissButton = false;
      this.events.subscribe('app:ready', async (data) => {
        try {
          await this.connectivityService.initializeWebsockets();
          await this.initSupportService();
        } catch (error) {
          console.error(error);
        }
      });
    }
  }

  async ionViewWillEnter() {
    if (this.plt.is('desktop')) {
      this.platform = 'desktop';
    } else if (this.plt.is('ios')) {
      this.platform = 'ios';
    } else if (this.plt.is('android')) {
      this.platform = 'android';
    }

    if (this.plt.is('desktop') || this.plt.is('tablet')) {
      this.defaultItemWidth = 300;
      this.defaultItemHeight = 300;
    }
  }

  async ionViewDidEnter() {
    this.translations = await this.translate.get(['error_message_general_header', 'popup_ok_button_label', 'label_general_connection_error']).toPromise();

    this.events.subscribe('support-conversation:created', (data: SupportConversation) => {
      this.zone.run(() => {
        this.conversation = data;
      });
    });

    this.events.subscribe('support-conversation:updated', (data: SupportConversation) => {
      this.zone.run(async () => {
        this.conversations = this.supportService.conversations;
        if (this.conversation && data.id == this.conversation.id) {

          // Mark messages as read

          this.conversation = data;
          if (data.user_messages_unread > 0) {
            await this.supportService.update(data.id, { is_received: true, is_read: true });
            const index = _.findIndex(this.conversations, (o: any) => { return o.id == data.id; });
            if (index > -1) {
              this.conversations[index].user_messages_unread = 0;
            }
          }

          setTimeout(async () => {
            await this.content.scrollToBottom(0);
          }, 100);

        } else {

          // Mark messages as received

          await this.supportService.update(data.id, { is_received: true, is_read: false });
        }
      });
    });

    if (this.router.url != '/support/support') {
      await this.initSupportService();
    }

    const userId = await this.keychainService.get('user_id');
    if (userId) {
      this.isAnonymous = false;
      const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();
      this.fullName = accountData.profile.full_name;
      this.email = accountData.profile.email;

      await this.keychainService.set('unreadSupportMessages', 0);
      this.store.dispatch({
        type: 'SET_UI_VARIABLE_ACTION',
        payload: {
          variable: 'supportMessagesUnread',
          value: 0
        }
      });
    }

    if (this.router.url != '/support/support') {
      this.store.dispatch({
        type: 'SET_BATCH_UI_VARIABLE_ACTION',
        payload: {
          supportWindowIsOpen: true
        }
      });
    }
  }

  ionViewWillLeave() {
    if (this.router.url != '/support/support') {
      this.store.dispatch({
        type: 'SET_UI_VARIABLE_ACTION',
        payload: {
          variable: 'supportWindowIsOpen',
          value: false
        }
      });
    }
    this.supportService.destroy();
  }

  async ionViewDidLeave() {
    this.events.unsubscribe('support-conversation:created', undefined);
    this.events.unsubscribe('support-conversation:updated', undefined);
    if (this.router.url == '/support/support') {
      this.events.unsubscribe('app:ready', undefined);
    }
  }

  trackFn(index, item): string | null {
    return item ? item.id : null;
  }

  async initSupportService() {
    await this.supportService.init();
    this.systemStatus = this.supportService.systemStatus;
    this.conversations = this.supportService.conversations;
    this.news = this.supportService.news;

    // Mark messages as received

    for (let i = 0; i < this.conversations.length; i++) {
      if (this.conversations[i].user_messages_unread > 0) {
        await this.supportService.update(this.conversations[i].id, { is_received: true, is_read: false });
      }
    }
  }

  dismiss(): void {
    if (this.showConversationDetails) {
      this.conversation = undefined;
      this.showConversationDetails = false;
    } else if (this.showConversationsList) {
      this.showConversationsList = false;
    } else {
      this.modalCtrl.dismiss();
    }
  }

  async listConversations() {
    this.showConversationsList = !this.showConversationsList;
    this.showConversationDetails = false;
  }

  startNewConversation() {
    this.showConversationDetails = true;
    this.showConversationsList = false;
  }

  async openConversation(conversation) {
    this.conversation = conversation;
    this.showConversationDetails = true;
    this.showConversationsList = false;
    this.contentIsReady = false;

    // Mark messages as read

    if (this.conversation.user_messages_unread > 0) {
      await this.supportService.update(this.conversation.id, { is_received: true, is_read: true });
      const index = _.findIndex(this.conversations, (o: any) => { return o.id == this.conversation.id; });
      if (index > -1) {
        this.conversations[index].user_messages_unread = 0;
      }
    }

    setTimeout(async () => {
      await this.content.scrollToBottom(0);
      this.contentIsReady = true;
    }, 400);
  }

  changeMessageText(event): void {
    setTimeout(() => {
      this.messageText = this.txtChat.content;
    }, 50);
  }

  onTextareaEnterPressed() {
    if (this.plt.is('desktop')) {
      this.send();
    }
  }

  openFAQ(item?) {
    if (item) {
      // Browser.open({ url: 'https://www.lingu.social/faq' });
    } else {
      // Browser.open({ url: 'https://www.lingu.social/faq' });
    }
  }

  openStatusPage() {
    Browser.open({ url: 'https://lingusocial.instatus.com/' });
  }

  async openUrl(item) {
    const url = item.text;
    Browser.open({ url: url });
  }

  openNews(newsItem) {
    // Browser.open({ url: 'https://www..lingu.social/news' });
  }

  faqSearchCancelled() {
    this.isSearchingfaq = false;
    this.faqSearchParam = '';
  }

  async searchFaq(event) {
    if (event.target.value.length > 2) {
      this.faqSearchParam = event.target.value;
      this.isSearchingfaq = true;

      const results: any = await this.supportService.find({
        query: {
          type: 'faq',
          search: this.faqSearchParam
        }
      });

      this.faqItems = results.results.data;
    } else {
      this.isSearchingfaq = false;
      this.faqSearchParam = '';
    }
  }

  async emojiToolbarAction() {
    const emojis: any = await this.keychainService.get('emojis');
    this.showEmojiToolbar = (!this.showEmojiToolbar) ? true : false;
    this.sliderIndex = 0;
    setTimeout(async () => {
      if (this.showEmojiToolbar && !emojis) {
        try {
          this.swiper.swiperRef.slideTo(1);
        } catch (error) {
          console.log(error);
        }
      }
    }, 200);
  }

  async onSlideChanged() {
    this.sliderIndex = this.swiper.swiperRef.activeIndex;
  }

  async selectEmojiCategory(pos) {

    // Haptic feedback

    if (this.plt.is('capacitor') && this.plt.is('android')) {
      Haptics.impact({ style: ImpactStyle.Medium });
    }

    try {
      this.swiper.swiperRef.slideTo(pos);
    } catch (error) {
      console.log(error);
    }
  }

  async messageInputSelectedAction() {
    if (this.showEmojiToolbar && this.plt.is('capacitor')) {
      this.showEmojiToolbar = false;
    }
  }

  async selectEmoji(emoji, event, isRecent) {

    // Haptic feedback

    if (this.plt.is('capacitor') && this.plt.is('android')) {
      Haptics.impact({ style: ImpactStyle.Medium });
    }

    // Add emoji or show skin color selection modal

    if (this.emojiService.emojis[emoji].diversities.length > 0 &&
      this.emojiService.emojis[this.emojiService.emojis[emoji].diversities[0]] && !isRecent) {
      const skinTones = this.emojiService.emojis[emoji].diversities;
      skinTones.splice(0, 0, emoji);
      const popover = await this.popoverCtrl.create({
        component: ImEmojiPopoverPage,
        componentProps: { data: skinTones },
        event: event,
        cssClass: (this.plt.is('tablet') || this.plt.is('desktop')) ? 'popover-emoji-tablet' : 'popover-emoji-smartphone'
      });
      await popover.present();
      const { data } = await popover.onWillDismiss();
      if (data) {
        this.emojiService.addToRecent(data, 'emoji');
        this.messageText += this.emojiService.emojis[data].shortname;
        this.txtChat.content += this.emojiService.emojis[data].shortname;
        this.txtChat.onChange(this.txtChat.content);
      }
    } else {
      this.emojiService.addToRecent(emoji, 'emoji');
      this.messageText += this.emojiService.emojis[emoji].shortname;
      this.txtChat.content += this.emojiService.emojis[emoji].shortname;
      this.txtChat.onChange(this.txtChat.content);
    }
  }

  async addAttachment() {
    try {
      if (this.newAttachments.length > 0) {
        this.newAttachments = [];
      }

      if (!this.plt.is('capacitor')) {

        // Open file selection dialog

        document.getElementById('fileSelector').click();

        // Watch file selection dialog

        if (!this.hasFileSelectionListener) {
          this.hasFileSelectionListener = true;
          document.getElementById('fileSelector').addEventListener('change', async (event) => {
            const target = <any>event.target;
            if (target.files.length > 0) {
              const file = target.files[0];
              const fileName = file.name;
              const fileType = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
            
              if (file.size < 5000000) {
                if (fileType == 'mp4' || fileType == 'jpg') {
                  const result = await this.fileManagerService.upload(file, fileType, false);
                  this.newAttachments.push({
                    file: result.id,
                    name: fileName
                  });
                } else {
                  const alert = await this.alertCtrl.create({
                    header: this.translations.error_message_general_header,
                    message: this.translations.error_video_not_mp4,
                    buttons: [this.translations.popup_ok_button_label]
                  });
                  await alert.present();
                }
              } else {
                const alert = await this.alertCtrl.create({
                  header: this.translations.error_message_general_header,
                  message: this.translations.error_video_not_mp4,
                  buttons: [this.translations.popup_ok_button_label]
                });
                await alert.present();
              }
            }
          }, false);
        }

      } else {

        /**
         * @todo Implement native file upload
         *
         */
      }
    } catch (error) {
      console.error(error);
    }
  }

  deleteNewAttachment(index: number) {
    this.newAttachments.splice(index, 1);
  }

  async downloadAttachment(attachment) {
    const url = await this.globalService.getStorageFileURL('support/uploads/' + attachment);
    await this.fileManagerService.download(url);
    if (!this.plt.is('capacitor')) {
      const response = await this.fileManagerService.get(url);

      const reader = response.body.getReader();
      const { done, value } = await reader.read();

      const blob = new Blob([value], { type: (attachment.indexOf('.webp') > -1) ? 'image/webp' : 'video/mp4' });
      saveAs(blob, attachment);

    } else {

      /**
       * @todo Do native file download
       *
       */
    }
  }

  async send(): Promise<void> {
    this.messageText = this.txtChat.content;
    this.messageText = (<any>window).joypixels.shortnameToUnicode(this.messageText);
    if (this.messageText.length > 1 || this.newAttachments.length > 0) {
      try {
        let messageType = SupportMessageType.Text;
        if (this.newAttachments.length > 0 && this.newAttachments[0].name.indexOf('.webp') > -1) {
          messageType = SupportMessageType.Picture;
        }

        if (this.newAttachments.length > 0 && this.newAttachments[0].name.indexOf('.mp4') > -1) {
          messageType = SupportMessageType.Video;
        }

        if (this.emojiService.isEmojisOnly(this.messageText)) {
          messageType = SupportMessageType.Emoji;
        }

        // Send detected urls as separate messages

        const urls = getUrls(this.messageText, { normalizeProtocol: false, stripWWW: false, stripAuthentication: false, removeTrailingSlash: false, removeSingleSlash: false, sortQueryParameters: false });
        for (const url of urls) {
          this.messageText = this.messageText.replace(url, '');
          await this.supportService.create({
            text: this.messageText,
            message_type: SupportMessageType.URL,
            message_type_id: '',
            is_anonymous: this.isAnonymous,
            conversation_id: (!this.conversation) ? '' : this.conversation.id,
            full_name: this.fullName,
            channel: (this.router.url == '/support/support') ? SupportChannels.Web : SupportChannels.Chat,
            email: this.email,
            data: {}
          });
        }

        // Send message

        if (this.messageText.length > 1 || this.newAttachments.length > 0) {
          await this.supportService.create({
            text: this.messageText,
            message_type: messageType,
            message_type_id: (this.newAttachments.length == 1) ? this.newAttachments[0].file : '',
            is_anonymous: this.isAnonymous,
            conversation_id: (!this.conversation) ? '' : this.conversation.id,
            full_name: this.fullName,
            channel: (this.router.url == '/support/support') ? SupportChannels.Web : SupportChannels.Chat,
            email: this.email,
            data: {}
          });
        }

        this.messageText = '';
        this.txtChat.clearInput();
        this.showEmojiToolbar = false;
        this.newAttachments = [];

        // Play sound

        try {
          new Audio('assets/sounds/message_sent.mp3').play();
        } catch (e) { }

      } catch (error) {
        const alert = await this.alertCtrl.create({
          header: this.translations.error_message_general_header,
          message: this.translations.label_general_connection_error,
          buttons: [this.translations.popup_ok_button_label]
        })
        await alert.present();
      }
    }
  }
}
