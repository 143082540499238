import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardService } from './services/authguard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'introduction',
    loadChildren: () => import('./pages/introduction-app/introduction-app.module').then(m => m.IntroductionAppPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'recents',
    loadChildren: () => import('./pages/recents/recents.module').then(m => m.RecentsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'recents/:id',
    loadChildren: () => import('./pages/recents/recents.module').then(m => m.RecentsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/authentication-login/authentication-login.module').then(m => m.AuthenticationLoginPageModule),
  },
  {
    path: 'dialpad',
    loadChildren: () => import('./pages/dialpad/dialpad.module').then(m => m.DialpadPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs-page/tabs-page.module').then(m => m.TabsModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'phrasebook',
    loadChildren: () => import('./pages/phrasebook/phrasebook.module').then(m => m.PhrasebookPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'voice-call',
    loadChildren: () => import('./pages/voice-call/voice-call.module').then(m => m.VoiceCallPageModule),
    // canActivate: [AuthguardService]
  },
  {
    path: 'voip-call',
    loadChildren: () => import('./pages/voip-call-p2p/voip-call-p2p.module').then(m => m.VoipCallP2PPageModule),
    // canActivate: [AuthguardService]
  },
  {
    path: 'meeting/:id',
    loadChildren: () => import('./pages/voip-call-public/voip-call-public.module').then(m => m.VoipCallPublicPageModule),
  },
  {
    path: 'instant-messages',
    loadChildren: () => import('./pages/instant-messages/instant-messages.module').then(m => m.InstantMessagesPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'instant-messages-location',
    loadChildren: () => import('./pages/instant-messages-location-modal/instant-messages-location-modal.module').then(m => m.InstantMessagesLocationModalPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'check-permissions',
    loadChildren: () => import('./pages/authentication-request-permissions/authentication-request-permissions.module').then(m => m.AuthenticationRequestPermissionsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'voip-call-devices', loadChildren: () => import('./pages/voip-call-devices/voip-call-devices.module').then(m => m.VoipCallDevicesPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'voip-call-details-selection-modal', loadChildren: () => import('./pages/voip-call-details-selection-modal/voip-call-details-selection-modal.module').then(m => m.VoipCallDetailsSelectionModalPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'security-settings',
    loadChildren: () => import('./pages/security-settings/security-settings.module').then(m => m.SecuritySettingsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'appearance-settings',
    loadChildren: () => import('./pages/appearance-settings/appearance-settings.module').then(m => m.AppearanceSettingsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'transcripts',
    loadChildren: () => import('./pages/transcripts/transcripts.module').then(m => m.TranscriptsPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'transcripts-nested',
    loadChildren: () => import('./pages/transcripts-nested/transcripts-nested.module').then(m => m.TranscriptsNestedPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support-modal/support-modal.module').then(m => m.SupportModalPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'editor/:id/:subdoc',
    loadChildren: () => import('./pages/transcript-editor/transcript-editor.module').then(m => m.TranscriptEditorPageModule)
  },
  {
    path: 'subscription-management',
    loadChildren: () => import('./pages/subscription-management/subscription-management.module').then(m => m.SubscriptionManagementPageModule),
    canActivate: [AuthguardService]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule),
    canActivate: [AuthguardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
