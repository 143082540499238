export interface UiState {
  appAnonymousUserId: string;
  appPhoneNumber: string;
  appIsRunningInBackground: boolean;
  appHasLoadedRemoteLibraries: boolean;
  appWebsocketsAreInitialized: boolean;
  appIsAuthenticating: boolean;
  appIsRTL: boolean;
  appIsFirstSync: boolean;
  appIsOnline: boolean;
  appIsReady: boolean;
  appUserCountry: string;
  appPlatform: string;
  appDeviceType: string;
  appPlatformIsCordova: boolean;
  appPlatformIsChrome: boolean;
  appEnvironment: string;
  appEnvironmentUrl: string;
  appVersion: string;
  appChromeVersion: number;
  appEnvironmentRegion: string;
  appEnvironmentLocation: string;
  appPwaIsInstallable: boolean;
  voiceCallIsActive: boolean;
  voiceCallId: string;
  voiceCallTargetNumber: string;
  voiceCallSourceLanguage: string;
  voiceCallTargetLanguage: string;
  voiceCallIsTranslated: boolean;
  voiceCallCustomVocabularyId: string;
  voiceCallFriendId: string;
  voiceCallPricing: any;
  voiceCallIsSetup: boolean;
  voiceCallTtsGenderSender: string;
  voiceCallTtsGenderReceiver: string;
  voipCallId: string;
  voipCallIsActive: boolean;
  voipCallRequestIsMadeByUser: boolean;
  voipCallIsAudioOnly: boolean;
  voipCallFriendId: string;
  voipCallDialogIsActive: boolean;
  voipCallIceServers: object;
  voipCallServerDetails: object;
  supportWindowIsOpen: boolean;
  supportMessagesUnread: number;
}

export const INITIAL_UI_STATE: UiState = {
  appAnonymousUserId: undefined,
  appPhoneNumber: undefined,
  appIsRunningInBackground: false,
  appHasLoadedRemoteLibraries: false,
  appWebsocketsAreInitialized: false,
  appIsAuthenticating: false,
  appIsRTL: false,
  appIsFirstSync: false,
  appIsOnline: false,
  appIsReady: false,
  appUserCountry: '',
  appPlatform: '',
  appDeviceType: '',
  appPlatformIsCordova: false,
  appPlatformIsChrome: false,
  appEnvironment: 'local',
  appEnvironmentUrl: 'https://api.lingu.social',
  appVersion: '1.0.0',
  appChromeVersion: 0,
  appEnvironmentRegion: 'eu',
  appEnvironmentLocation: 'europe-west4',
  appPwaIsInstallable: false,
  voiceCallIsActive: false,
  voiceCallId: undefined,
  voiceCallTargetNumber: undefined,
  voiceCallSourceLanguage: undefined,
  voiceCallTargetLanguage: undefined,
  voiceCallIsTranslated: false,
  voiceCallFriendId: undefined,
  voiceCallPricing: {},
  voiceCallTtsGenderSender: 'male',
  voiceCallTtsGenderReceiver: 'male',
  voiceCallCustomVocabularyId: undefined,
  voiceCallIsSetup: false,
  voipCallId: undefined,
  voipCallIsActive: false,
  voipCallRequestIsMadeByUser: false,
  voipCallIsAudioOnly: false,
  voipCallFriendId: undefined,
  voipCallDialogIsActive: false,
  voipCallIceServers: {},
  voipCallServerDetails: {},
  supportWindowIsOpen: false,
  supportMessagesUnread: 0
};
