import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emoji' })
export class EmojiPipe implements PipeTransform {

  private animojis = [
    '1f3b6', '1f4a5', '1f4a9', '1f4aa', '1f4aa_1f3fb', '1f4aa_1f3fc', '1f4aa_1f3fd', '1f4aa_1f3fe', '1f4aa_1f3ff', '1f4ab', '1f4af', '1f4b8', '1f5a4', '1f6a8', '1f6ce', '1f9e1',
    '1f31e', '1f31f', '1f37b', '1f37e', '1f38a', '1f44b', '1f44b_1f3fb', '1f44b_1f3fc', '1f44b_1f3fd', '1f44b_1f3fe', '1f44b_1f3ff', '1f44d', '1f44d_1f3fb', '1f44d_1f3fc',
    '1f44d_1f3fd', '1f44d_1f3fe', '1f44d_1f3ff', '1f44f', '1f44f_1f3fb', '1f44f_1f3fc', '1f44f_1f3fd', '1f44f_1f3fe', '1f44f_1f3ff', '1f47b', '1f48b', '1f48e', '1f49a',
    '1f49b', '1f49c', '1f49e', '1f60a', '1f60b', '1f60c', '1f60d', '1f60e', '1f60f', '1f61a', '1f61b', '1f61c', '1f61d', '1f61e', '1f62a', '1f62b', '1f62c', '1f62d', '1f64a',
    '1f64c', '1f64c_1f3fb', '1f64c_1f3fc', '1f64c_1f3fd', '1f64c_1f3fe', '1f64c_1f3ff', '1f64f', '1f64f_1f3fb', '1f64f_1f3fc', '1f64f_1f3fd', '1f64f_1f3fe', '1f64f_1f3ff',
    '1f68', '1f90d', '1f90e', '1f91e', '1f91e_1f3fb', '1f91e_1f3fc', '1f91e_1f3fd', '1f91e_1f3fe', '1f91e_1f3ff', '1f92a', '1f92b', '1f92d', '1f92f', '1f97a', '1f98b', '1f192',
    '1f308', '1f331', '1f339', '1f340', '1f382', '1f386', '1f388', '1f389', '1f419', '1f422', '1f440', '1f493', '1f494', '1f495', '1f496', '1f497', '1f499', '1f514', '1f525',
    '1f600', '1f601', '1f602', '1f603', '1f604', '1f605', '1f606', '1f607', '1f608', '1f609', '1f610', '1f611', '1f612', '1f613', '1f614', '1f615', '1f616', '1f618', '1f620',
    '1f621', '1f622', '1f623', '1f624', '270c_1f3ff', '2639', '2763', '2764', '2764_1f525', '2764_1fa79', '270c_1f3fb', '270c_1f3fc', '270c_1f3fd', '270c_1f3fe', '270c',
    '1f625', '1f629', '1f631', '1f633', '1f634', '1f635_1f4ab', '1f636', '1f642', '1f643', '1f644', '1f648', '1f649', '1f911', '1f914', '1f917', '1f920', '1f923', '1f924',
    '1f928', '1f929', '1f970', '1f971', '1f972', '1f973', '1f979', '1f980', '1fae0', '1fae1', '1fae2', '26bd', '261d_1f3fb', '261d_1f3fc', '261d_1f3fd', '261d_1f3fe',
    '261d_fe0f', '263a', '1f304', '1f305', '1f30b', '1f30d', '1f31b', '1f31c', '1f32c', '1f342', '1f345', '1f377', '1f379', '1f37f', '1f383', '1f393', '1f3a2', '1f3af',
    '1f402', '1f405', '1f407', '1f409', '1f40c', '1f40d', '1f40e', '1f410', '1f412', '1f413', '1f415', '1f416', '1f41c', '1f41d', '1f421', '1f423', '1f424', '1f425', '1f42c',
    '1f43f', '1f441', '1f44e_1f3fb', '1f44e_1f3fc', '1f44e_1f3fd', '1f44e_1f3fe', '1f44e_1f3ff', '1f44e', '1f463', '1f47d', '1f47f', '1f480', '1f483_1f3fb', '1f483_1f3fc',
    '1f483_1f3fe', '1f483_1f3ff', '1f483', '1f48c', '1f498', '1f49d', '1f4a1', '1f50b', '1f54a', '1f617', '1f619', '1f61f', '1f626', '1f627', '1f628', '1f62e', '1f62f',
    '1f637', '1f638', '1f639', '1f63a', '1f63b', '1f63c', '1f63d', '1f63e', '1f63f', '1f640', '1f641', '1f680', '1f6eb', '1f6ec', '1f6f8', '1f910', '1f912', '1f913', '1f915',
    '1f925', '1f927', '1f92c', '1f92e', '1f940', '1f941', '1f942', '1f974', '1f975', '1f976', '1f978', '1f984', '1f985', '1f987', '1f98e', '1f996', '1f998', '1f99a', '1f99f',
    '1f9be', '1f9bf', '1f9d0', '1fa75', '1fa76', '1fa77', '1fa78', '1fa87', '1faa9', '1faab', '1fabc', '1fabf', '1fac0', '1facf', '1fae3', '1fae4', '1fae5', '1fae6', '1fae8',
    '2604', '2615', '2648', '2649', '264a', '264b', '264c', '264d', '264e', '264f', '2650', '2651', '2652', '2653', '26a1', '26ce', '2705', '2728', '2744', '274c', '2795',
    '1f3bb', '1f3c1', '1f400', '261d_1f3ff', '1f433', '1f438', '1f43e', '1f483_1f3fd', '1f630', '1f632', '1f635', '1f916', '1f921', '1f922', '1f9a0', '1f9a6', '1f9ad',
    '203c', '23f0', '2602'
  ];

  transform(map: string, isAnimoji?: boolean): any {
    if (!map)
      return undefined;
    if ((<any>window).joypixels) {
      if (isAnimoji) {
        const find = (<any>window).joypixels.unicodeCharRegex();
        let shortname = (<any>window).joypixels.replaceAll(map, find);
        let nameArr = shortname.split('::');
        let images = '';
        if (nameArr.length > 0) {
          for (let i = 0; i < nameArr.length; i++) {
            const name = ':' + nameArr[i].replace(/:/g, '') + ':';
            images += this.getImage(name);
          }
        } else {
          images += this.getImage(name);
        }
        return images;
      } else {
        return (<any>window).joypixels.toImage(map);
      }
    } else {
      return map;
    }
  }

  private getImage(shortname) {
    if (!(<any>window).joypixels.emojiList[shortname]) {
      for (var emoji in (<any>window).joypixels.emojiList) {
        if (!(<any>window).joypixels.emojiList.hasOwnProperty(emoji) || (emoji === '')) continue;
        if ((<any>window).joypixels.emojiList[emoji].shortnames.indexOf(shortname) === -1) continue;
        shortname = emoji;
        break;
      }
    }

    const code = (<any>window).joypixels.emojiList[shortname].uc_full;
    if (this.animojis.indexOf(code) > -1) {
      return `<img class="joypixels animoji" src="assets/lib/note-animoji/${code}.webp"/>`;
    } else {
      return (<any>window).joypixels.shortnameToImage(shortname).replace('joypixels', 'joypixels animoji-no-animation');
    }
  }
}
