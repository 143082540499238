import { RecentData, INITIAL_RECENT_DATA } from '../store-data';
import {
  UPDATE_RECENT_ACTION, REMOVE_RECENT_ACTION, SELECT_RECENT_CONTACT_ACTION, RESET_CONTACT_SELECTION_ACTION, RESET_RECENT_ACTION, INCREASE_HISTORY_ITEMS_DISPLAY_ACTION,
  UpdateRecentAction, SelectRecentContactAction, ResetContactSelectionAction, ResetRecentAction, IncreaseHistoryItemsDisplayAction
} from '../actions/recent';
import { Action } from '@ngrx/store';

export function recentData(state: RecentData, action: Action): RecentData {
  switch (action.type) {
    case UPDATE_RECENT_ACTION:
      return handleUpdateRecentAction(state, <any>action);
    case REMOVE_RECENT_ACTION:
      return handleRemoveRecentAction(state, <any>action);
    case SELECT_RECENT_CONTACT_ACTION:
      return handleSelectRecentContactAction(state, <any>action);
    case RESET_CONTACT_SELECTION_ACTION:
      return handleResetContactSelectionAction(state, <any>action);
    case RESET_RECENT_ACTION:
      return handleResetRecentAction(state, <any>action);
    case INCREASE_HISTORY_ITEMS_DISPLAY_ACTION:
      return handleIncreaseHistoryItemsDisplayAction(state, <any>action);
    default:
      return state;
  }
}

function handleUpdateRecentAction(state: RecentData, action: UpdateRecentAction) {
  const newSelectedContact = (state) ? state.selectedContact : INITIAL_RECENT_DATA.selectedContact,
    newSelectedContactHistoryPos = (state) ? state.selectedContactHistoryPos : INITIAL_RECENT_DATA.selectedContactHistoryPos;
  let newRecent = (state) ? Object.assign({}, state.data) : INITIAL_RECENT_DATA.data,
    lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_RECENT_DATA.lastUpdatedTimestamp;

  if (!action.payload.type) {
    newRecent[action.payload.recent_id] = action.payload.data;
  } else {
    newRecent = action.payload.data;
  }

  if (action.payload.date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
    lastUpdatedTimestamp = action.payload.date_updated;
  }

  const newStoreState: RecentData = {
    data: newRecent,
    selectedContact: newSelectedContact,
    selectedContactHistoryPos: newSelectedContactHistoryPos,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleRemoveRecentAction(state: RecentData, action: UpdateRecentAction) {
  const newSelectedContact = (state) ? state.selectedContact : INITIAL_RECENT_DATA.selectedContact,
    newSelectedContactHistoryPos = (state) ? state.selectedContactHistoryPos : INITIAL_RECENT_DATA.selectedContactHistoryPos;
  const newRecent = (state) ? Object.assign({}, state.data) : INITIAL_RECENT_DATA.data;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_RECENT_DATA.lastUpdatedTimestamp;
  delete newRecent[action.payload.recent_id];

  if (action.payload.date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
    lastUpdatedTimestamp = action.payload.date_updated;
  }

  const newStoreState: RecentData = {
    data: newRecent,
    selectedContact: newSelectedContact,
    selectedContactHistoryPos: newSelectedContactHistoryPos,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleSelectRecentContactAction(state: RecentData, action: SelectRecentContactAction) {
  const newSelectedContact = action.payload, newSelectedContactHistoryPos = 20;

  const newStoreState: RecentData = {
    data: state.data,
    selectedContact: newSelectedContact,
    selectedContactHistoryPos: newSelectedContactHistoryPos,
    lastUpdatedTimestamp: state.lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleResetContactSelectionAction(state: RecentData, action: ResetContactSelectionAction) {
  const newSelectedContact = INITIAL_RECENT_DATA.selectedContact,
    newSelectedContactHistoryPos = INITIAL_RECENT_DATA.selectedContactHistoryPos;

  const newStoreState: RecentData = {
    data: state.data,
    selectedContact: newSelectedContact,
    selectedContactHistoryPos: newSelectedContactHistoryPos,
    lastUpdatedTimestamp: state.lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleResetRecentAction(state: RecentData, action: ResetRecentAction) {
  return INITIAL_RECENT_DATA;
}

function handleIncreaseHistoryItemsDisplayAction(state: RecentData, action: IncreaseHistoryItemsDisplayAction) {
  const newSelectedContactHistoryPos = (state) ? state.selectedContactHistoryPos + 20 : INITIAL_RECENT_DATA.selectedContactHistoryPos;

  const newStoreState: RecentData = {
    data: state.data,
    selectedContact: state.selectedContact,
    selectedContactHistoryPos: newSelectedContactHistoryPos,
    lastUpdatedTimestamp: state.lastUpdatedTimestamp
  };

  return newStoreState;
}
