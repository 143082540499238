import { CustomVocabulary } from '../interfaces/custom-vocabulary';
import { ConnectivityService } from './connectivity.service';
import { KeychainService } from './keychain.service';

import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Events } from './events.service';
import * as _ from 'lodash';

import { ApplicationState } from '../ngrx/application-state';
import { TranscriptsData } from '../ngrx/store-data';

@Injectable({
  providedIn: 'root',
})
export class TranscriptionService {

  private connectivityService: ConnectivityService;

  private keychainService: KeychainService;

  constructor(private events: Events, private injector: Injector, private store: Store<ApplicationState>) { }

  transcripts: any[] = [];

  init(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.connectivityService) {
          this.connectivityService = this.injector.get(ConnectivityService);
          this.keychainService = this.injector.get(KeychainService);
        }

        // Event listener

        this.connectivityService.feathers.service('user-transcripts').on('created', (data: CustomVocabulary) => {
          this.events.publish('user-transcripts:created', data);
        });

        this.connectivityService.feathers.service('user-transcripts').on('updated', (data: CustomVocabulary) => {
          this.events.publish('user-transcripts:updated', data);
        });

        this.connectivityService.feathers.service('user-transcripts').on('removed', (data: CustomVocabulary) => {
          this.events.publish('user-transcripts:removed', data);
        });

        // Sync data

        const transcripts = await this.connectivityService.feathers.service('user-transcripts').find({ query: {} });
        this.transcripts = transcripts;
        resolve({});
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.keychainService = this.injector.get(KeychainService);
      }

      try {
        const transcripts = this.connectivityService.feathers.service('user-transcripts');

        // Sync recent data

        const transcriptsData: TranscriptsData = Object.assign({}, await this.store.select('transcriptsData').pipe(first()).toPromise());
        const userId = await this.keychainService.get('user_id');

        // History event listener

        transcripts.removeListener('created');
        transcripts.on('created', async (data) => {
          this.store.dispatch({
            type: 'ADD_TRANSCRIPT_ITEM_ACTION',
            payload: [data]
          });
        });

        transcripts.removeListener('updated');
        transcripts.on('updated', (data) => {
          this.store.dispatch({
            type: 'UPDATE_TRANSCRIPT_ITEM_ACTION',
            payload: data
          });
        });

        transcripts.removeListener('removed');
        transcripts.on('removed', (data) => {
          this.store.dispatch({
            type: 'DELETE_TRANSCRIPT_ITEM_ACTION',
            payload: data
          });
        });

        // Sync history data

        const results = await transcripts.find({
          query: {
            date_updated: (!transcriptsData || !transcriptsData.lastUpdatedTimestamp) ? undefined : {
              $gt: transcriptsData.lastUpdatedTimestamp
            }
          }
        });

        console.log('TRANSCRIPTS');
        console.log(results);

        if (results && results.data.length > 0) {
          this.store.dispatch({
            type: 'ADD_TRANSCRIPT_ITEM_ACTION',
            payload: results.data
          });
        }

        console.log('Transcripts synced');
        resolve({});
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  get(id) {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
      }

      this.connectivityService.feathers.service('user-transcripts').get(id).then((results) => {
        resolve(results);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  find(params) {
    return new Promise(async (resolve, reject) => {
      this.connectivityService.feathers.service('user-transcripts').find(params).then((results) => {
        resolve(results);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  destroy(): void {
    this.connectivityService.feathers.service('user-transcripts').removeListener('created');
    this.connectivityService.feathers.service('user-transcripts').removeListener('updated');
    this.connectivityService.feathers.service('user-transcripts').removeListener('removed');
  }

  create(data): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'create',
      category: 'user-transcripts.service',
      data: data,
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-transcripts').create(data);
  }

  update(conversationId, data) {
    return this.connectivityService.feathers.service('user-transcripts').update(conversationId, data);
  }

  remove(id) {
    return this.connectivityService.feathers.service('user-transcripts').remove(id);
  }

  action(action: 'list-versions' | 'restore-version' | 'share-document' | 'translate-document' | 'add-vocabulary' | 'download-file', params: any) {
    return this.connectivityService.feathers.service('user-transcripts-actions').create({ action: action, params: params });
  }
}
