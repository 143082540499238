import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { AppComponent } from './app.component';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';

import { AccountService } from './services/account.service';
import { ConnectivityService } from './services/connectivity.service';
import { ContactsService } from './services/contacts.service';
import { GlobalService } from './services/global.service';
import { MessagingService } from './services/messaging.service';
import { NotificationsService } from './services/notifications.service';
import { PaymentsService } from './services/payments.service';
import { PhoneValidatorService } from './services/phone-validator.service';
import { PricingService } from './services/pricing.service';
import { RecentService } from './services/recent.service';
import { VoiceCallService } from './services/voice-call.service';
import { VoipCallP2PService } from './services/voip-call-p2p.service';
import { VoipCallPublicService } from './services/voip-call-public.service';
import { PhrasebookService } from './services/phrasebook.service';
import { EmojiService } from './services/emoji.service';
import { SupportService } from './services/support.service';
import { AuthentificationService } from './services/auth.service';
import { NavigationService } from './services/navigation.service';
import { AuthentificationQrCodeService } from './services/auth-qr.service';
import { KeychainService } from './services/keychain.service';
import { DataSynchronizationService } from './services/data-synchronization.service';
import { FileManagerService } from './services/file-manager.service';
import { GlobalizationService } from './services/globalization.service';
import { LoggerService } from './services/logger.service';
import { PushNotificationService } from './services/push-notifications.service';
import { TranscriptionService } from './services/transcription.service';
import { TranscriptionsExplorerService } from './services/transcriptions-explorer.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SwiperModule } from 'swiper/angular';
import { NgxTurnstileModule } from 'ngx-turnstile';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { StorageSyncEffects, storageSync } from './ngrx/ngrx-storage';
import { ApplicationState } from './ngrx/application-state';
import { uiState } from './ngrx/reducers/ui-state-reducer';
import { contactsData } from './ngrx/reducers/contacts-reducer';
import { accountData } from './ngrx/reducers/account-reducer';
import { notificationsData } from './ngrx/reducers/notifications-reducer';
import { phrasebookData } from './ngrx/reducers/phrasebook-reducer';
import { configData } from './ngrx/reducers/config-reducer';
import { recentData } from './ngrx/reducers/recent-reducer';
import { historyData } from './ngrx/reducers/history-reducer';
import { supportData } from './ngrx/reducers/support-reducer';
import { liveEventsData } from './ngrx/reducers/live-events-reducer';
import { liveVocabularyData } from './ngrx/reducers/live-vocabulary-reducer';
import { transcriptsData } from './ngrx/reducers/transcripts-reducer';
import { transcriptsExplorerData } from './ngrx/reducers/transcripts-explorer.reducer';
import { storeData } from './ngrx/reducers/ui-store-data-reducer';

import { CONTACT_STARTED_TYPING_ACTION, CONTACT_FINISHED_TYPING_ACTION } from './ngrx/actions/contacts';
import { SELECT_RECENT_CONTACT_ACTION, RESET_CONTACT_SELECTION_ACTION } from './ngrx/actions/recent';
import {
  TRANSCRIPTS_EXPLORER_ORDER_BY_ACTION, TRANSCRIPTS_EXPLORER_SORT_BY_ACTION, TRANSCRIPTS_EXPLORER_RESET_SELECTED_ITEM_ACTION,
  TRANSCRIPTS_EXPLORER_SELECT_TAB_ACTION, TRANSCRIPTS_EXPLORER_SELECT_ITEM_ACTION
} from './ngrx/actions/transcripts-explorer';

import { SET_UI_VARIABLE_ACTION, SET_BATCH_UI_VARIABLE_ACTION, RESET_UI_VARIABLES_ACTION } from './ngrx/actions/ui';
import { RESET_UNREAD_MESSAGES_ACTION } from './ngrx/actions/notifications';

import { NgxTiptapModule } from 'ngx-tiptap';
import * as Hammer from 'hammerjs';

// Setup syncing between ngrx and Ionic Storage

export const reducers: ActionReducerMap<ApplicationState> = {
  uiState: uiState,
  storeData: storeData,
  contactsData: contactsData,
  accountData: accountData,
  notificationsData: notificationsData,
  phrasebookData: phrasebookData,
  configData: configData,
  recentData: recentData,
  historyData: historyData,
  supportData: supportData,
  liveEventsData: liveEventsData,
  liveVocabularyData: liveVocabularyData,
  transcriptsData: transcriptsData,
  transcriptsExplorerData: transcriptsExplorerData
};

export function onSyncError(error) {
  console.log('SYNC ERROR');
  console.error(error);
}

export const storageSyncReducer = storageSync({
  keys: ['contactsData', 'accountData', 'notificationsData', 'supportData', 'phrasebookData', 'configData', 'transcriptsData', 'transcriptsExplorerData',
    'recentData', 'historyData', 'liveEventsData', 'liveVocabularyData'],
  ignoreActions: [
    CONTACT_STARTED_TYPING_ACTION,
    CONTACT_FINISHED_TYPING_ACTION,
    SELECT_RECENT_CONTACT_ACTION,
    RESET_CONTACT_SELECTION_ACTION,
    SET_UI_VARIABLE_ACTION,
    SET_BATCH_UI_VARIABLE_ACTION,
    RESET_UNREAD_MESSAGES_ACTION,
    RESET_UI_VARIABLES_ACTION,
    TRANSCRIPTS_EXPLORER_ORDER_BY_ACTION,
    TRANSCRIPTS_EXPLORER_SORT_BY_ACTION,
    TRANSCRIPTS_EXPLORER_SELECT_TAB_ACTION,
    TRANSCRIPTS_EXPLORER_SELECT_ITEM_ACTION,
    TRANSCRIPTS_EXPLORER_RESET_SELECTED_ITEM_ACTION
  ],
  hydratedStateKey: 'hydrated',
  onSyncError: onSyncError
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export const metaReducers: MetaReducer<any, any>[] = [storageMetaReducer];

// Load translation files

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    ScrollingModule,
    NgxTurnstileModule,
    NgxTiptapModule,
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    HttpClientModule,
    SwiperModule,
    IonicModule.forRoot({}),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false
      },
      initialState: {
        // hydrated: false
      }
    }),
    EffectsModule.forRoot([StorageSyncEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25 })
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true
      }),
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => { },
    //   deps: [Sentry.TraceService],
    //   multi: true
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router]
    // },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GlobalService,
    AccountService,
    ConnectivityService,
    ContactsService,
    MessagingService,
    NotificationsService,
    PaymentsService,
    PhoneValidatorService,
    PricingService,
    RecentService,
    VoiceCallService,
    VoipCallP2PService,
    VoipCallPublicService,
    PhrasebookService,
    EmojiService,
    SupportService,
    AuthentificationService,
    AuthentificationQrCodeService,
    KeychainService,
    DataSynchronizationService,
    FileManagerService,
    GlobalizationService,
    LoggerService,
    PushNotificationService,
    TranscriptionService,
    TranscriptionsExplorerService,
    NavigationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
