import { Platform, PopoverController } from '@ionic/angular';
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

@Component({
  selector: 'im-emoji-popover',
  templateUrl: './im-emoji-popover.page.html',
  styleUrls: ['./im-emoji-popover.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImEmojiPopoverPage implements OnInit {

  data;

  constructor(public plt: Platform, private popoverCtrl: PopoverController) { }

  ngOnInit() { }

  async select(item): Promise<void> {

    // Haptic feedback

    if (this.plt.is('capacitor') && this.plt.is('android')) {
      Haptics.impact({ style: ImpactStyle.Medium });
    }

    this.popoverCtrl.dismiss(item);
  }
}
