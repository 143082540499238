import { Component, ViewChild } from '@angular/core';
import { IonTextarea } from '@ionic/angular';

@Component({
  selector: 'elastic-textarea',
  inputs: ['placeholder', 'lineHeight'],
  template:
    `
  <ion-textarea #ionTxtArea 
    placeholder='{{placeholder}}'
    [(ngModel)]="content"
    (ngModelChange)='onChange($event)' style="--placeholder-color: #364152; margin-top: 0px; font-size: 16px; overflow: hidden; max-height: 44px; min-height: 44px;padding: 0px; font-family: 'Noto Color Emoji', sans-serif;"></ion-textarea>
  `
})
export class ElasticTextareaComponent {

  @ViewChild('ionTxtArea', { static: true }) ionTxtArea: IonTextarea;

  public txtArea: any;

  public content: string;

  public lineHeight: string;

  public placeholder: string;

  constructor() {
    this.content = '';
    // this.lineHeight = '38px';
  }

  public async ngAfterViewInit() {
    this.txtArea = await this.ionTxtArea.getInputElement();
    this.txtArea.style.height = this.lineHeight + 'px';
    this.txtArea.style.resize = 'none';
    this.txtArea.style.overflow = 'hidden';
  }

  public onChange(newValue) {
    this.content = this.content;
  }

  public async clearInput() {
    this.content = '';
    this.txtArea = await this.ionTxtArea.getInputElement();
    this.txtArea.style.height = this.lineHeight + 'px';
    // this.txtArea.style['padding-top'] = '9px';
  }

  public setFocus() {
    this.ionTxtArea.setFocus();
  }
}
