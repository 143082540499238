import { SupportData, INITIAL_SUPPORT_DATA } from '../store-data';
import {
  ADD_SUPPORT_ITEM_ACTION, RESET_SUPPORT_ACTION, SET_SUPPORT_ITEMS_ACTION,
  AddSupportItemAction, ResetSupportAction
} from '../actions/support';
import { Action } from '@ngrx/store';

import _ from 'lodash';

export function supportData(state: SupportData, action: Action): SupportData {
  switch (action.type) {
    case ADD_SUPPORT_ITEM_ACTION:
      return handleAddSupportItemAction(state, <any>action);
    case SET_SUPPORT_ITEMS_ACTION:
      return handleSetSupportItemsAction(state, <any>action);
    case RESET_SUPPORT_ACTION:
      return handleResetSupportAction(state, <any>action);
    default:
      return state;
  }
}

function handleAddSupportItemAction(state: SupportData, action: AddSupportItemAction) {
  const newAuthenticatedData = (state) ? Object.assign([], state.authenticatedData) : INITIAL_SUPPORT_DATA.authenticatedData,
    newAnonymousData = (state) ? Object.assign([], state.anonymousData) : INITIAL_SUPPORT_DATA.anonymousData;

  for (const key in action.payload.data) {
    if (!action.payload.isAnonymous) {
      const index = _.findIndex(newAuthenticatedData, (o: any) => { return o.id == action.payload.data[key].id; });
      if (index > -1) {
        newAuthenticatedData[index] = action.payload.data[key];
      } else {
        newAuthenticatedData.push(action.payload.data[key]);
      }


    } else {
      const index = _.findIndex(newAnonymousData, (o: any) => { return o.id == action.payload.data[key].id; });
      if (index > -1) {
        newAnonymousData[index] = action.payload.data[key];
      } else {
        newAnonymousData.push(action.payload.data[key]);
      }
    }
  }

  const newStoreState: SupportData = {
    authenticatedData: newAuthenticatedData,
    anonymousData: newAnonymousData,
    unreadMessagesTotal: 0,
    unreadMessagesList: []
  };

  return newStoreState;
}

function handleSetSupportItemsAction(state: SupportData, action: AddSupportItemAction) {
  const newAuthenticatedData = INITIAL_SUPPORT_DATA.authenticatedData, newAnonymousData = INITIAL_SUPPORT_DATA.anonymousData;
  for (let i = 0; i < action.payload.length; i++) {
    if (action.payload[i].is_anonymous) {
      newAnonymousData.push(action.payload[i]);
    } else {
      newAuthenticatedData.push(action.payload[i]);
    }
  }

  const newStoreState: SupportData = {
    authenticatedData: newAuthenticatedData,
    anonymousData: newAnonymousData,
    unreadMessagesTotal: 0,
    unreadMessagesList: []
  };

  return newStoreState;
}

function handleResetSupportAction(state: SupportData, action: ResetSupportAction) {
  return INITIAL_SUPPORT_DATA;
}
