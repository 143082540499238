import { Injectable, forwardRef, Inject, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';
import { first } from 'rxjs/operators';

import { KeychainService } from './keychain.service';
import { LoggerService } from './logger.service';
import { ConnectivityService } from './connectivity.service';

import { Store } from '@ngrx/store';
import { ApplicationState } from '../ngrx/application-state';
import { AccountData } from '../ngrx/store-data';
import { UiState } from '../ngrx/ui-state';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  private keepAliveTimerStarted = false;

  private interval;

  private connectivityService: ConnectivityService;

  private loggerService: LoggerService;

  private keychainService: KeychainService;

  constructor(private store: Store<ApplicationState>, private injector: Injector) { }

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.keychainService = this.injector.get(KeychainService);
        this.loggerService = this.injector.get(LoggerService);
      }

      const userId = await this.keychainService.get('user_id');
      const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();

      console.log('UserId: ' + userId);

      const account = this.connectivityService.feathers.service('user');
      const accountProfile = this.connectivityService.feathers.service('user-profiles');
      const accountTransactions = this.connectivityService.feathers.service('user-transactions');

      // Service events

      account.removeListener('updated');
      account.on('updated', async (data) => {
        this.loggerService.logMessage('Account updated', data, 'account.service', 'info');
        const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();
        if (accountData) {
          if (data.date_updated > accountData.details.date_updated) {
            this.store.dispatch({
              type: 'UPDATE_ACCOUNT_DETAILS_ACTION',
              payload: data
            });
          }

          // Logout user when marked as inactive

          if (data.is_active == false) {
            this.connectivityService.logout();
          }
        }
      });

      accountTransactions.removeListener('created');
      accountTransactions.on('created', (data) => {
        this.loggerService.logMessage('Account transaction created', data, 'account.service', 'info');
        this.store.dispatch({
          type: 'UPDATE_ACCOUNT_TRANSACTIONS_ACTION',
          payload: [data]
        });
      });

      // Keep alive timer to confirm online presence status

      if (!this.interval) {
        this.interval = setInterval(async () => {
          const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
          if (uiState.appIsOnline) {
            const deviceId = await this.keychainService.get('device_id');
            if (deviceId && deviceId.length > 0) {
              try {
                const geo = await this.connectivityService.feathers.service('user-devices').update(deviceId, { device_id: deviceId, ip_address: this.connectivityService.ip }, {});
                if (geo.country.length > 0) {
                  this.connectivityService.ipCity = geo.city;
                  this.connectivityService.ipCountry = geo.country;
                  this.connectivityService.ipTimeZone = geo.timezone;
                }
              } catch (error) {
                console.error(error);
              }
            }
          }
        }, 20000);
      }

      // Get user details

      try {
        const accountDetailsResults = await account.get(userId);
        if (accountDetailsResults) {
          Sentry.addBreadcrumb({
            message: 'accountDetailsResults',
            category: 'account',
            data: accountDetailsResults,
            level: 'info'
          });

          if (!accountData || accountDetailsResults.date_updated > accountData.details.date_updated) {
            this.store.dispatch({
              type: 'UPDATE_ACCOUNT_DETAILS_ACTION',
              payload: accountDetailsResults
            });
          }

          // Get profile

          const accountProfileResults = await accountProfile.get(userId);
          if (accountProfileResults) {
            Sentry.addBreadcrumb({
              message: 'accountProfileResults',
              category: 'account',
              data: accountProfileResults,
              level: 'info'
            });

            if (!accountData || accountProfileResults.date_updated > accountData.profile.date_updated) {
              this.store.dispatch({
                type: 'UPDATE_ACCOUNT_PROFILE_ACTION',
                payload: accountProfileResults
              });
            }

            // Get transactions

            const transactionResults = await accountTransactions.find({
              query: {
                date_created: (!accountData || !accountData.lastTransactionsUpdatedTimestamp) ? undefined : {
                  $gt: accountData.lastTransactionsUpdatedTimestamp
                }
              }
            });

            console.log('TRANSACTIONS');
            console.log(transactionResults);

            if (transactionResults && transactionResults.data.length > 0) {
              Sentry.addBreadcrumb({
                message: 'accountTransactions',
                category: 'account',
                data: transactionResults,
                level: 'info'
              });

              this.store.dispatch({
                type: 'UPDATE_ACCOUNT_TRANSACTIONS_ACTION',
                payload: transactionResults.data
              });
            }

            console.log('Account synced');
            resolve({});
          }
        } else {
          throw new Error('Unable to get account details!');
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  /**
   * Register new account
   * @param  Object data
   * @return Promise
   */
  create(data): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'create',
      category: 'account',
      data: data,
      level: 'info'
    });
    return this.connectivityService.feathers.service('account').create(data, {});
  }

  /**
   * Update account details
   * @param  Object data
   * @return Promise
   */
  update(userId, data): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'update',
      category: 'account',
      data: { userId: userId, data: data },
      level: 'info'
    });

    return this.connectivityService.feathers.service('account').update(userId, data, {});
  }

  /**
  * Delete account
  * @param  Object data
  * @return Promise
  */
  async remove(): Promise<any> {
    const userId = await this.keychainService.get('user_id');
    Sentry.addBreadcrumb({
      message: 'remove',
      category: 'account',
      data: { userId: userId },
      level: 'info'
    });
    return this.connectivityService.feathers.service('account').remove(userId, {});
  }
}
