import { AccountData, INITIAL_ACCOUNT_DATA } from '../store-data';
import {
  UPDATE_ACCOUNT_DETAILS_ACTION, UPDATE_ACCOUNT_PROFILE_ACTION, UPDATE_ACCOUNT_TRANSACTIONS_ACTION, RESET_ACCOUNT_ACTION,
  UpdateAccountDetailsAction, UpdateAccountProfileAction, UpdateAccountTranscationsAction, ResetAccountAction
} from '../actions/account';
import { Action } from '@ngrx/store';

export function accountData(state: AccountData, action: Action): AccountData {
  switch (action.type) {
    case UPDATE_ACCOUNT_DETAILS_ACTION:
      return handleUpdatedAccountDetailsAction(state, <any>action);
    case UPDATE_ACCOUNT_PROFILE_ACTION:
      return handleUpdatedAccountProfileAction(state, <any>action);
    case UPDATE_ACCOUNT_TRANSACTIONS_ACTION:
      return handleUpdatedAccountTransactionsAction(state, <any>action);
    case RESET_ACCOUNT_ACTION:
      return handleResetAccountAction(state, <any>action);
    default:
      return state;
  }
}

function handleUpdatedAccountDetailsAction(state: AccountData, action: UpdateAccountDetailsAction) {
  const newDetails = action.payload, newProfile = (state) ? Object.assign({}, state.profile) : INITIAL_ACCOUNT_DATA.profile,
    newTransactions = (state) ? Object.assign({}, state.transactions) : {},
    newLastTransactionsUpdatedTimestamp = (state) ? state.lastTransactionsUpdatedTimestamp : undefined;

  const newStoreState: AccountData = {
    details: newDetails,
    profile: newProfile,
    transactions: newTransactions,
    lastTransactionsUpdatedTimestamp: newLastTransactionsUpdatedTimestamp
  };

  return newStoreState;
}

function handleUpdatedAccountProfileAction(state: AccountData, action: UpdateAccountProfileAction) {
  const newDetails = (state) ? Object.assign({}, state.details) : INITIAL_ACCOUNT_DATA.details,
    newTransactions = (state) ? Object.assign({}, state.transactions) : INITIAL_ACCOUNT_DATA.profile,
    newLastTransactionsUpdatedTimestamp = (state) ? state.lastTransactionsUpdatedTimestamp : undefined;

  const newStoreState: AccountData = {
    details: newDetails,
    profile: action.payload,
    transactions: newTransactions,
    lastTransactionsUpdatedTimestamp: newLastTransactionsUpdatedTimestamp
  };

  return newStoreState;
}

function handleUpdatedAccountTransactionsAction(state: AccountData, action: UpdateAccountTranscationsAction) {
  const newDetails = (state) ? Object.assign({}, state.details) : INITIAL_ACCOUNT_DATA.details,
    newProfile = (state) ? Object.assign({}, state.profile) : INITIAL_ACCOUNT_DATA.profile,
    newTransactions = (state) ? Object.assign({}, state.transactions) : {};
  let newLastTransactionsUpdatedTimestamp = (state) ? state.lastTransactionsUpdatedTimestamp : undefined;

  for (const key in action.payload) {
    if (!newTransactions[action.payload[key].id]) {
      newTransactions[action.payload[key].id] = action.payload[key];
      if (action.payload[key].date_created > newLastTransactionsUpdatedTimestamp || newLastTransactionsUpdatedTimestamp === undefined) {
        newLastTransactionsUpdatedTimestamp = action.payload[key].date_created;
      }
    }
  }

  const newStoreState: AccountData = {
    details: newDetails,
    profile: newProfile,
    transactions: newTransactions,
    lastTransactionsUpdatedTimestamp: newLastTransactionsUpdatedTimestamp
  };

  return newStoreState;
}

function handleResetAccountAction(state: AccountData, action: ResetAccountAction) {
  return INITIAL_ACCOUNT_DATA;
}
