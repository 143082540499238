import { Action } from '@ngrx/store';

export const ADD_TRANSCRIPT_ITEM_ACTION = 'ADD_TRANSCRIPT_ITEM_ACTION';
export const UPDATE_TRANSCRIPT_ITEM_ACTION = 'UPDATE_TRANSCRIPT_ITEM_ACTION';
export const DELETE_TRANSCRIPT_ITEM_ACTION = 'DELETE_TRANSCRIPT_ITEM_ACTION';
export const RESET_TRANSCRIPTS_ACTION = 'RESET_TRANSCRIPTS_ACTION';

export class AddTranscriptItemAction implements Action {
  readonly type = ADD_TRANSCRIPT_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class UpdateTranscriptItemAction implements Action {
  readonly type = UPDATE_TRANSCRIPT_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class DeleteTranscriptItemAction implements Action {
  readonly type = DELETE_TRANSCRIPT_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class ResetTransacriptsAction implements Action {
  readonly type = RESET_TRANSCRIPTS_ACTION;
  constructor(public payload?: any) { }
}
