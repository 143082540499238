import { Observable, defer, from as fromPromise } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { Injectable } from '@angular/core';
import { ActionReducer } from '@ngrx/store';
import { createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';

const STORAGE_KEY = 'NSIS_APP_STATE';

// get/setNested inspired by
// https://github.com/mickhansen/dottie.js
function getNested(obj: any, path: string): any {
  if (obj !== null && path) {
    // Recurse into the object.
    const parts = path.split('.').reverse();
    while (obj != null && parts.length) {
      obj = obj[parts.pop()];
    }
  }
  return obj;
}

function setNested(obj: any, path: string, value: any): any {
  if (obj != null && path) {
    let pieces = path.split('.'),
      current = obj,
      piece, i,
      length = pieces.length;

    for (i = 0; i < length; i++) {
      piece = pieces[i];
      if (i === length - 1) {
        current[piece] = value;
      } else if (!current[piece]) {
        current[piece] = {};
      }
      current = current[piece];
    }
  }

  return obj;
}

function fetchState(): Promise<{}> {
  return new Promise(async (resolve, reject) => {
    try {
      const { value } = await Preferences.get({ key: STORAGE_KEY });
      resolve(JSON.parse(value) || {});
    } catch (error) {
      resolve({});
    }
  });
}

function saveState(state: any, keys: string[]): Promise<void> {
  return new Promise(async (resolve, reject) => {
    try {
      if (keys) {
        state = keys.reduce((acc, k) => {
          const val = getNested(state, k);
          if (val) {
            setNested(acc, k, val);
          }
          return acc;
        }, {});
      }

      await Preferences.set({ key: STORAGE_KEY, value: JSON.stringify(state) });
      resolve();
    } catch (error) {
      resolve();
    }
  });
}

export const StorageSyncActions = {
  HYDRATED: 'NSIS_APP_HYDRATED'
};

@Injectable()
export class StorageSyncEffects {
  hydrate$: Observable<any> = createEffect(() => defer(() =>
    fromPromise(fetchState()).pipe(map(state => ({
      type: StorageSyncActions.HYDRATED,
      payload: state
    })))));
}

export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
};

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  onSyncError: (err) => { }
}

export function storageSync(options?: StorageSyncOptions) {
  const { keys, ignoreActions, hydratedStateKey, onSyncError } = Object.assign({}, defaultOptions, options || {});
  const hydratedState: any = {};

  ignoreActions.push(StorageSyncActions.HYDRATED);
  ignoreActions.push('@ngrx/store/init');
  ignoreActions.push('@ngrx/effects/init');
  ignoreActions.push('@ngrx/store/update-reducers');

  return function storageSyncReducer(reducer: ActionReducer<any>) {
    return (state: any, action: any) => {
      const { type, payload } = action;

      if (type === StorageSyncActions.HYDRATED) {
        state = Object.assign({}, state, payload);
        if (hydratedStateKey) {
          hydratedState[hydratedStateKey] = true;
        }
      }

      const nextState = Object.assign({}, reducer(state, action), hydratedState);
      if (ignoreActions.indexOf(type) === -1) {
        saveState(nextState, keys).catch(err => onSyncError(err));
      }

      return nextState;
    };
  };
}
