import { Action } from '@ngrx/store';

export const UPDATE_TRANSCRIPTS_EXPLORER_ACTION = 'UPDATE_TRANSCRIPTS_EXPLORER_ACTION';
export const RESET_TRANSCRIPTS_EXPLORER_ACTION = 'RESET_TRANSCRIPTS_EXPLORER_ACTION';
export const TRANSCRIPTS_EXPLORER_ORDER_BY_ACTION = 'TRANSCRIPTS_EXPLORER_ORDER_BY_ACTION';
export const TRANSCRIPTS_EXPLORER_SORT_BY_ACTION = 'TRANSCRIPTS_EXPLORER_SORT_BY_ACTION';
export const TRANSCRIPTS_EXPLORER_SELECT_TAB_ACTION = 'TRANSCRIPTS_EXPLORER_SELECT_TAB_ACTION';
export const TRANSCRIPTS_EXPLORER_SELECT_ITEM_ACTION = 'TRANSCRIPTS_EXPLORER_SELECT_ITEM_ACTION';
export const TRANSCRIPTS_EXPLORER_RESET_SELECTED_ITEM_ACTION = 'TRANSCRIPTS_EXPLORER_RESET_SELECTED_ITEM_ACTION';

export class UpdateTranscriptsExplorerAction implements Action {
  readonly type = UPDATE_TRANSCRIPTS_EXPLORER_ACTION;
  constructor(public payload?: any) { }
}

export class ResettranscriptsExplorerAction implements Action {
  readonly type = RESET_TRANSCRIPTS_EXPLORER_ACTION;
  constructor(public payload?: any) { }
}

export class transcriptsExplorerOrderByAction implements Action {
  readonly type = TRANSCRIPTS_EXPLORER_ORDER_BY_ACTION;
  constructor(public payload?: any) { }
}

export class transcriptsExplorerSortByAction implements Action {
  readonly type = TRANSCRIPTS_EXPLORER_SORT_BY_ACTION;
  constructor(public payload?: any) { }
}

export class transcriptsExplorerSelectTabAction implements Action {
  readonly type = TRANSCRIPTS_EXPLORER_SELECT_TAB_ACTION;
  constructor(public payload?: any) { }
}

export class transcriptsExplorerSelectItemAction implements Action {
  readonly type = TRANSCRIPTS_EXPLORER_SELECT_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class transcriptsExplorerResetSelectedItemAction implements Action {
  readonly type = TRANSCRIPTS_EXPLORER_RESET_SELECTED_ITEM_ACTION;
  constructor(public payload?: any) { }
}