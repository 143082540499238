import { Injectable, Injector } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';

import { ConnectivityService } from './connectivity.service';
import { ApplicationState } from '../ngrx/application-state';
import { UiState } from '../ngrx/ui-state';

@Injectable({
  providedIn: 'root',
})
export class VoiceCallService {

  private connectivityService: ConnectivityService;

  constructor(private store: Store<ApplicationState>, private injector: Injector) {
    this.connectivityService = this.injector.get(ConnectivityService);
  }

  startCall(phoneNumber: string, fromLanguage: string, toLanguage: string, friendId: string, price: number,
    isTranslated: boolean, ttsGenderSender: string, ttsGenderReceiver: string, customVocabularyId: string) {
    Sentry.addBreadcrumb({
      message: 'startCall',
      category: 'voice-call.service',
      data: {
        'action': 'start-call',
        'phone_number': phoneNumber,
        'from': fromLanguage,
        'to': toLanguage,
        'friend_id': friendId,
        'price': price,
        'is_translated': isTranslated,
        'tts_gender_sender': ttsGenderSender,
        'tts_gender_receiver': ttsGenderReceiver,
        'custom_vocabulary_id': customVocabularyId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('voice-call').create({
      'action': 'start-call',
      'phone_number': phoneNumber,
      'from': fromLanguage,
      'to': toLanguage,
      'friend_id': friendId,
      'price': price,
      'is_translated': isTranslated,
      'tts_gender_sender': ttsGenderSender,
      'tts_gender_receiver': ttsGenderReceiver,
      'custom_vocabulary_id': customVocabularyId
    }, {});
  }

  async getPricingForCountry(country) {
    Sentry.addBreadcrumb({
      message: 'getPricingForCountry',
      category: 'voice-call.service',
      data: {
        'call_id': country
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('voice-call').create({
      'action': 'get-pricing',
      'country': country
    }, {});
  }
}
