import { TranscriptsExplorerData, INITIAL_TRANSCRIPTS_EXPLORER_DATA } from '../store-data';
import {
  UPDATE_TRANSCRIPTS_EXPLORER_ACTION, TRANSCRIPTS_EXPLORER_ORDER_BY_ACTION, TRANSCRIPTS_EXPLORER_SORT_BY_ACTION, TRANSCRIPTS_EXPLORER_SELECT_TAB_ACTION, TRANSCRIPTS_EXPLORER_SELECT_ITEM_ACTION,
  TRANSCRIPTS_EXPLORER_RESET_SELECTED_ITEM_ACTION, transcriptsExplorerResetSelectedItemAction, transcriptsExplorerSelectTabAction, transcriptsExplorerSelectItemAction,
  UpdateTranscriptsExplorerAction, transcriptsExplorerOrderByAction, transcriptsExplorerSortByAction,
} from '../actions/transcripts-explorer';

import { Action } from '@ngrx/store';
import _ from 'lodash';

export function transcriptsExplorerData(state: TranscriptsExplorerData, action: Action): TranscriptsExplorerData {
  switch (action.type) {
    case UPDATE_TRANSCRIPTS_EXPLORER_ACTION:
      return handleUpdateTranscriptsExplorerAction(state, <any>action);
    case TRANSCRIPTS_EXPLORER_SORT_BY_ACTION:
      return handleTranscriptsExplorerSortByAction(state, <any>action);
    case TRANSCRIPTS_EXPLORER_ORDER_BY_ACTION:
      return handleTranscriptsExplorerOrderByAction(state, <any>action);
    case TRANSCRIPTS_EXPLORER_SELECT_TAB_ACTION:
      return handleTranscriptsExplorerTabSelectionAction(state, <any>action);
    case TRANSCRIPTS_EXPLORER_SELECT_ITEM_ACTION:
      return handleTranscriptsExplorerSelectItemAction(state, <any>action);
    case TRANSCRIPTS_EXPLORER_RESET_SELECTED_ITEM_ACTION:
      return handleTranscriptsExplorerResetSelectedItemAction(state, <any>action);

    default:
      return state;
  }
}

function handleUpdateTranscriptsExplorerAction(state: TranscriptsExplorerData, action: UpdateTranscriptsExplorerAction) {
  if (!state) {
    state = INITIAL_TRANSCRIPTS_EXPLORER_DATA;
  }

  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_TRANSCRIPTS_EXPLORER_DATA.lastUpdatedTimestamp;
  if (action.payload.date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
    lastUpdatedTimestamp = action.payload.date_updated;
  }

  const newStoreState: TranscriptsExplorerData = {
    data: action.payload.data,
    lastUpdatedTimestamp: lastUpdatedTimestamp,
    selectedTab: state.selectedTab,
    sortByFilter: state.sortByFilter,
    sortByFilterAlpha: state.sortByFilterAlpha,
    breadcrumbs: state.breadcrumbs,
    selectedItem: state.selectedItem
  };

  return newStoreState;
}

function handleTranscriptsExplorerSortByAction(state: TranscriptsExplorerData, action: transcriptsExplorerSortByAction) {
  const newStoreState: TranscriptsExplorerData = state;
  newStoreState.sortByFilter = action.payload;
  return newStoreState;
}

function handleTranscriptsExplorerOrderByAction(state: TranscriptsExplorerData, action: transcriptsExplorerOrderByAction) {
  const newStoreState: TranscriptsExplorerData = state;
  newStoreState.sortByFilterAlpha = action.payload;
  return newStoreState;
}

function handleTranscriptsExplorerTabSelectionAction(state: TranscriptsExplorerData, action: transcriptsExplorerSelectTabAction) {
  const newStoreState: TranscriptsExplorerData = state;
  newStoreState.selectedTab = action.payload;
  return newStoreState;
}

function handleTranscriptsExplorerSelectItemAction(state: TranscriptsExplorerData, action: transcriptsExplorerSelectItemAction) {
  const newStoreState: TranscriptsExplorerData = state;
  newStoreState.selectedItem = action.payload;

  if(newStoreState.selectedItem.is_directory) {
    let breadcrumbs = [];
    if(newStoreState.breadcrumbs.length > 0) {  
      for (const elem of newStoreState.breadcrumbs) {
        if(elem.id != action.payload.id) {
          breadcrumbs.push(elem);
        } else {
          break;
        }
      };
    } 
  
    breadcrumbs.push(action.payload);
    newStoreState.breadcrumbs = breadcrumbs;
  }
 
  return newStoreState;
}

function handleTranscriptsExplorerResetSelectedItemAction(state: TranscriptsExplorerData, action: transcriptsExplorerResetSelectedItemAction) {
  const newStoreState: TranscriptsExplorerData = state;
  newStoreState.selectedItem = undefined;
  newStoreState.breadcrumbs = [];
  return newStoreState;
}
