import { ConnectivityService } from './connectivity.service';
import { KeychainService } from './keychain.service';
import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Events } from './events.service';
import { Store } from '@ngrx/store';

import { ApplicationState } from '../ngrx/application-state';

@Injectable({
  providedIn: 'root',
})
export class TranscriptionsExplorerService {

  private connectivityService: ConnectivityService;

  private keychainService: KeychainService;

  constructor(private events: Events, private injector: Injector, private store: Store<ApplicationState>) { }

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.connectivityService) {
          this.connectivityService = this.injector.get(ConnectivityService);
          this.keychainService = this.injector.get(KeychainService);
        }

        // Event listener

        this.connectivityService.feathers.service('user-transcripts-explorer').removeListener('updated');
        this.connectivityService.feathers.service('user-transcripts-explorer').on('updated', (data: any) => {
          this.store.dispatch({
            type: 'UPDATE_TRANSCRIPTS_EXPLORER_ACTION',
            payload: data
          });
        });

        // Sync data

        const userId = await this.keychainService.get('user_id');
        const data = await this.connectivityService.feathers.service('user-transcripts-explorer').get(userId);

        this.store.dispatch({
          type: 'UPDATE_TRANSCRIPTS_EXPLORER_ACTION',
          payload: data
        });
        resolve({});
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  async update(data) {
    const userId = await this.keychainService.get('user_id');
    return this.connectivityService.feathers.service('user-transcripts-explorer').update(userId, data);
  }
}
