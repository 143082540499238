import { Action } from '@ngrx/store';

export const ADD_HISTORY_ITEM_ACTION = 'ADD_HISTORY_ITEM_ACTION';
export const UPDATE_HISTORY_ITEM_ACTION = 'UPDATE_HISTORY_ITEM_ACTION';
export const DELETE_HISTORY_ITEM_ACTION = 'DELETE_HISTORY_ITEM_ACTION';
export const DELETE_FULL_HISTORY_ACTION = 'DELETE_FULL_HISTORY_ACTION';
export const RESET_HISTORY_ACTION = 'RESET_HISTORY_ACTION';

export class AddHistoryItemAction implements Action {
  readonly type = ADD_HISTORY_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class UpdateHistoryItemAction implements Action {
  readonly type = UPDATE_HISTORY_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class DeleteHistoryItemAction implements Action {
  readonly type = DELETE_HISTORY_ITEM_ACTION;
  constructor(public payload?: any) { }
}

export class DeleteFullHistoryAction implements Action {
  readonly type = DELETE_FULL_HISTORY_ACTION;
  constructor(public payload?: any) { }
}

export class ResetHistoryAction implements Action {
  readonly type = RESET_HISTORY_ACTION;
  constructor(public payload?: any) { }
}
