import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { first } from 'rxjs/operators';

import { Events } from './events.service';
import { LoggerService } from './logger.service';
import { KeychainService } from './keychain.service';
import { ConnectivityService } from './connectivity.service';
import { GlobalizationService } from './globalization.service';

import { ApplicationState } from '../ngrx/application-state';
import { AccountData, ContactsData } from '../ngrx/store-data';
import { UiState } from '../ngrx/ui-state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {

  private loggerService: LoggerService;

  private keychainService: KeychainService;

  private connectivityService: ConnectivityService;

  private globalizationService: GlobalizationService;

  constructor(private events: Events, private store: Store<ApplicationState>, private injector: Injector) { }

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.globalizationService) {
        this.globalizationService = this.injector.get(GlobalizationService);
        this.connectivityService = this.injector.get(ConnectivityService);
        this.keychainService = this.injector.get(KeychainService);
        this.loggerService = this.injector.get(LoggerService);
      }

      const profile = this.connectivityService.feathers.service('user-profiles');

      // Service events

      profile.removeListener('updated');
      profile.removeListener('removed');
      profile.on('updated', async (data) => {
        this.loggerService.logMessage('Contact updated ', data, 'contacts.service', 'info');
        const userId = await this.keychainService.get('user_id');
        if (data.id == userId) {
          const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();
          if (data.date_updated > accountData.profile.date_updated) {

            // Change language

            if (data.language != accountData.details.language) {
              this.globalizationService.setDefaultLanguage(data.language);
            }

            this.store.dispatch({
              type: 'UPDATE_ACCOUNT_PROFILE_ACTION',
              payload: data
            });

            // Syncing friend profiles

            this.syncFriendList();
          }

        } else {

          this.store.dispatch({
            type: 'UPDATE_CONTACT_ACTION',
            payload: data
          });

          // End active voip call if user is getting offline

          const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
          if (uiState.voipCallIsActive && uiState.voipCallFriendId == data.id) {
            this.events.publish('voip-call-p2p:disconnected', {
              type: 'voip_call_disconnected'
            });
          }
        }
      });

      profile.on('removed', async (data) => {
        this.loggerService.logMessage('Contact removed ', data, 'contacts.service', 'info');
        this.store.dispatch({
          type: 'REMOVE_CONTACT_ACTION',
          payload: data
        });
      });

      // Syncing friend profiles

      this.syncFriendList().then(() => {
        console.log('Contacts synced');
        resolve({});
      }).catch((error) => {
        reject(error);
      });
    });
  }

  private syncFriendList(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const contactsData: ContactsData = await this.store.select('contactsData').pipe(first()).toPromise();
      const keys = Object.keys(contactsData.list);
      const timestamp = (!contactsData || !contactsData.lastUpdatedTimestamp || keys.length <= 1) ? '' : contactsData.lastUpdatedTimestamp;

      Sentry.addBreadcrumb({
        message: 'syncFriendList',
        category: 'contacts.service',
        data: {
          action: 'sync',
          last_update: timestamp
        },
        level: 'info'
      });

      this.connectivityService.feathers.service('contacts').find({
        query: {
          action: 'sync',
          last_update: timestamp
        }
      }).then((contacts) => {
        if (contacts && contacts.length > 0) {
          this.store.dispatch({
            type: 'SYNC_CONTACTS_ACTION',
            payload: contacts
          });
        }
        resolve({});
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  hasFriendWithPhoneNumber(phoneNumber): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const contactsData: ContactsData = await this.store.select('contactsData').pipe(first()).toPromise();
      const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();
      if (contactsData.listWithPhoneNumbers[phoneNumber] && contactsData.listWithPhoneNumbers[phoneNumber].id != accountData.details.id) {
        resolve(contactsData.listWithPhoneNumbers[phoneNumber].id);
      } else {
        resolve(undefined);
      }
    });
  }

  find(param): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'find',
      category: 'contacts.service',
      data: {
        param: param
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('contacts').get(param);
  }

  findByList(list): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'findByList',
      category: 'contacts.service',
      data: {
        list: list
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('contacts').find({
      query: {
        action: 'search',
        list: list
      }
    });
  }

  add(friendId): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'add',
      category: 'contacts.service',
      data: {
        friendId: friendId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('contacts').create({
      friend_id: friendId
    });
  }

  update(friendId, isTranslated = false, isBlocked = false) {
    Sentry.addBreadcrumb({
      message: 'update',
      category: 'contacts.service',
      data: {
        friendId: friendId,
        isTranslated: isTranslated,
        isBlocked: isBlocked
      },
      level: 'info'
    });

    console.log(friendId);

    return this.connectivityService.feathers.service('contacts').update(friendId, { is_translated: isTranslated, is_blocked: isBlocked });
  }

  delete(friendId): Promise<any> {

    // Delete from instant message dialog

    // if (RecentService.selectedContact.type == 'contact' && RecentService.selectedContact.id == friendId) {
    //   RecentService.flushSelectedContact();
    // }

    Sentry.addBreadcrumb({
      message: 'delete',
      category: 'contacts.service',
      data: {
        friendId: friendId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('contacts').remove(friendId);
  }
}
