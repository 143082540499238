import { Platform, LoadingController, ToastController, AlertController } from '@ionic/angular';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { RewriteFrames } from '@sentry/integrations';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import flagsmith from 'flagsmith';
import posthog from 'posthog-js';

// import { NativeBiometric } from '@capacitor-community/biometric-authentication';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard } from '@capacitor/keyboard';

import { Events } from './services/events.service';
import { EmojiService } from './services/emoji.service';
import { GlobalService } from './services/global.service';
import { KeychainService } from './services/keychain.service';
import { ConnectivityService } from './services/connectivity.service';
import { GlobalizationService } from './services/globalization.service';
import { PushNotificationService } from './services/push-notifications.service';
import { DataSynchronizationService } from './services/data-synchronization.service';
import { NavigationService } from './services/navigation.service';

// import { uiState } from './ngrx/reducers/ui-state-reducer';
import { ApplicationState } from './ngrx/application-state';
import { AccountData } from './ngrx/store-data';
import { UiState } from './ngrx/ui-state';

interface DeviceInfo {
  appPlatform: string;
  appDeviceType: string;
  appPlatformIsCordova: boolean;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  applicationState$ = new Subscription();

  appIsReady = false;

  constructor(
    public plt: Platform, private translate: TranslateService, public loadingCtrl: LoadingController, private store: Store<ApplicationState>,
    private events: Events, public connectivityService: ConnectivityService, private globalService: GlobalService, private http: HttpClient,
    private keychainService: KeychainService, private swUpdate: SwUpdate, private emojiService: EmojiService, private alertCtrl: AlertController,
    private globalizationService: GlobalizationService, private dataSynchronizationService: DataSynchronizationService, private router: Router,
    private pushNotificationService: PushNotificationService, private toastCtrl: ToastController, private navService: NavigationService,

  ) {
    this.initApp();
  }

  ngOnInit() { }

  async initApp() {
    await this.plt.ready();

    // Get app environment details

    const versionData: any = await this.http.get('./assets/version.json', {}).toPromise();
    const appEnvironmentUrl = (versionData.env != 'production') ? 'https://api-staging.lingu.social' : 'https://api.lingu.social';
    const appVersion = versionData.version, appEnvironment = versionData.env;

    // Require JS

    (<any>window).requirejs.config({
      waitSeconds: 20,
      paths: {
        'stripe': 'https://js.stripe.com/v3?t=' + (new Date()).getTime(),
        'turnstile': 'https://challenges.cloudflare.com/turnstile/v0/api'
      }
    });

    // Init Firebase 

    const firebaseConfig = {
      apiKey: 'AIzaSyCBRhLnhDjv4mVqZRMS60nJpY56sqAo1no',
      authDomain: 'lingu-de7b9.firebaseapp.com',
      databaseURL: 'https://lingu-de7b9.firebaseio.com',
      projectId: 'lingu-de7b9',
      storageBucket: 'lingu-de7b9.appspot.com',
      messagingSenderId: '462748592216',
      appId: '1:462748592216:web:5e5f0c36e8136d7726022d',
      measurementId: 'G-XCJ3ZJF3X5'
    };

    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    const storage = getStorage(firebaseApp);

    // Init push notifications

    navigator.permissions.query({ name: 'notifications' }).then((status) => {
      status.onchange = async () => {
        if (status.state == 'granted') {

          // Delete existing token

          try {
            await deleteToken(messaging);
          } catch (e) { };

          // Update notification token when permission is granted

          try {
            const currentToken = await getToken(messaging, { vapidKey: 'BAUQWmbFnaiy35gNoI5YMlyj6F3MO4KcVFf9n6CcC6JhK-MLdjzM0S_Tjk5-u1TcKdY39w3ExkjHouHctJv-Kf8' });
            if (currentToken) {
              await this.pushNotificationService.updateNotificationToken(currentToken);
              console.log(`TOKEN: ${currentToken}`);
            }
          } catch (error) {
            Sentry.captureException(error);
            console.log(error);
          }
        }
      };
    });

    onMessage(messaging, (payload) => {
      // console.log('Message received. ', payload);
    });

    // Init emojis

    (<any>window).joypixels.ascii = true;
    (<any>window).joypixels.emojiSize = '128';
    (<any>window).joypixels.imagePathPNG = 'assets/lib/noto-emoji/svg/emoji_u';
    (<any>window).joypixels.fileExtension = '.svg';

    // Listen until data storage handler is ready

    this.applicationState$ = this.store.subscribe(async (applicationState: any) => {
      if (applicationState.hydrated && !this.appIsReady) {
        this.applicationState$.unsubscribe();
        this.appIsReady = true;

        console.log('App is ready!');

        // Check if data store is initiated

        const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();
        if (!accountData) {
          console.log('INITIATING DATA STORAGE');
          this.dataSynchronizationService.resetDataStorage();
        } else {
          this.store.dispatch({
            type: 'RESET_UI_VARIABLES_ACTION',
            payload: ''
          });
          this.store.dispatch({
            type: 'RESET_CONTACT_SELECTION_ACTION',
            payload: ''
          });
        }

        // Init connectivity

        this.store.dispatch({
          type: 'SET_BATCH_UI_VARIABLE_ACTION', payload: {
            appIsFirstSync: true,
            appIsReady: true
          }
        });

        await this.connectivityService.init();

        // Init localization

        const defaultLanguage = await this.globalizationService.init();

        console.log('Language: ' + defaultLanguage);

        // Init emojis

        this.emojiService.init();

        // Update global vars

        this.store.dispatch({
          type: 'SET_BATCH_UI_VARIABLE_ACTION', payload: {
            appVersion: appVersion,
            appEnvironment: appEnvironment,
            appEnvironmentUrl: appEnvironmentUrl
          }
        });

        // Init Sentry error tracking

        Sentry.init({
          dsn: 'https://722b4247edfa4f818d223b8e52eaf319@sentry.lingu.social/2',
          release: versionData.version,
          environment: 'production',
          integrations: [
            // new Sentry.BrowserTracing({
            //   tracingOrigins: ['localhost', 'https://staging.lingu.social', 'https://app.lingu.social', 'https://dev.lingu.social'],
            //   routingInstrumentation: Sentry.routingInstrumentation
            // }),
            // new RewriteFrames({ prefix: '~' })
          ],
          // tracesSampleRate: 1.0
        });

        // Init feature flags

        flagsmith.init({
          environmentID: '5QGjuL8BVRXwtQ2zDcBAJY',
          api: 'https://flagsmith.lingu.social/api/v1/',
          cacheFlags: true,
          enableAnalytics: true,
          onChange: (oldFlags, params) => {

            /**
             * @todo Add feature flag handling
             */

            console.log('Flags changed', oldFlags, params);
          },
        });

        // Init Posthog

        posthog.init('phc_eqlIGHOTyQjMRYtCsWprspC0Bzfa6cFLdSum5RYy6zC', { api_host: 'https://posthog.lingu.social' });

        // Set device id

        let deviceId = await this.keychainService.get('device_id');
        if (deviceId == undefined) {
          deviceId = uuidv4();
          await this.keychainService.set('device_id', deviceId);
          console.log('Device Id: ' + deviceId);
        }

        // Load device infos

        const deviceInfo = this.loadDeviceInfo();

        // Set dark theme

        const isDarkModeOn = await this.keychainService.get('dark_mode_is_on');
        if (isDarkModeOn && isDarkModeOn == true) {
          document.body.classList.toggle('dark', true);
          document.documentElement.style.setProperty('color-scheme', 'dark');
        }

        // Route handler

        this.router.events.subscribe((events) => {
          // if (events && events instanceof NavigationEnd) {
          if (events) {
            // console.log(events);
            // this.popoverController.getTop().then(popover => {
            //   return popover ? this.popoverController.dismiss() : null;
            // });
          }
        });

        // Background mode

        App.addListener('appStateChange', async (state) => {
          if (this.navService.getCurrentUrl().indexOf('meeting/') == -1) {
            if (!state.isActive) {

              // const taskId = BackgroundTask.beforeExit(async () => {
              //   BackgroundTask.finish({
              //     taskId
              //   });
              // });

              console.log('App pause');

              const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
              const userId = await this.keychainService.get('user_id');

              if (!uiState.appIsAuthenticating && !uiState.voipCallIsActive && !uiState.voiceCallIsActive && userId) {
                if (this.connectivityService.socket) {
                  this.connectivityService.socket.disconnect();
                }

                console.log('App offline');

                this.store.dispatch({
                  type: 'SET_UI_VARIABLE_ACTION',
                  payload: {
                    variable: 'appIsOnline',
                    value: false
                  }
                });

                if (this.plt.is('capacitor')) {
                  this.store.dispatch({
                    type: 'SET_UI_VARIABLE_ACTION',
                    payload: {
                      variable: 'appIsRunningInBackground',
                      value: true
                    }
                  });
                }
              }

            } else {

              console.log('App resume');

              const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
              const userId = await this.keychainService.get('user_id');

              // Display biometric authentication dialog

              if (this.plt.is('capacitor')) {
                const isBiometricAuthenticationOn = await this.keychainService.get('authenticate_with_biometrics');
                if (isBiometricAuthenticationOn && isBiometricAuthenticationOn == true) {
                  await this.requestBiometricAuthentication();
                }
              }

              this.store.dispatch({
                type: 'SET_UI_VARIABLE_ACTION',
                payload: {
                  variable: 'appIsOnline',
                  value: true
                }
              });

              if (this.plt.is('capacitor')) {
                this.store.dispatch({
                  type: 'SET_UI_VARIABLE_ACTION',
                  payload: {
                    variable: 'appIsRunningInBackground',
                    value: false
                  }
                });
              }

              console.log('App online');

              if (!uiState.appIsAuthenticating && !uiState.voipCallIsActive && !uiState.voiceCallIsActive && userId) {
                if (this.connectivityService.socket) {
                  this.connectivityService.socket.connect();
                  this.dataSynchronizationService.loadAllData();

                  /**
                   * @todo Check token for expiry
                   */
                }
              }
            }
          }
        });

        // Init Capacitor plugins

        if (this.plt.is('capacitor')) {

          // iOS keyboard

          if (this.plt.is('ios')) {
            Keyboard.setAccessoryBarVisible({ isVisible: false });
          }

          // Lock screen position

          if (deviceInfo.appDeviceType != 'tablet') {
            await ScreenOrientation.lock({ orientation: 'portrait' });
          }

          // Status bar

          if (deviceInfo.appPlatform != 'android') {
            StatusBar.setStyle({ style: Style.Light });
          } else {
            setTimeout(() => {
              StatusBar.setStyle({ style: Style.Light });
              StatusBar.setBackgroundColor({ color: '#009fc5' });
            }, 1000);
          }

          App.addListener('backButton', (data) => {
            // console.log(`Back button url: ${data.url}`);
          });

          App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
            console.log('App opened with URL:', JSON.stringify(data));
            // const openUrl = data.url;
          });

          // Push notifications

          // OneSignal.setAppId('efd52f6f-0721-42b1-9d9a-f48e9686d63a');
          // OneSignal.setNotificationOpenedHandler((jsonData) => {
          //   this.pushNotificationService.processOpenedNotification('mobile', jsonData.notification);
          // });

        } else {

          // Check if desktop PWA is installable

          this.swUpdate.versionUpdates.subscribe(async res => {
            console.log('APP UPDATE');
            console.log(res);

            if (res.type == 'VERSION_READY') {
              const translations = this.translate.instant(['desc_pwa_update_available', 'label_pwa_reload', 'label_cancel']);
              const toast = await this.toastCtrl.create({
                message: translations.desc_pwa_update_available,
                position: 'bottom',
                cssClass: 'toast-dark',
                buttons: [
                  {
                    side: 'end',
                    role: 'cancel',
                    text: translations.label_cancel,
                    handler: () => { }
                  }, {
                    text: translations.label_pwa_reload,
                    handler: () => {
                      window.location.reload();
                    }
                  }
                ]
              });
              await toast.present();
            }
          });

          // Listen for PWA installed event

          window.addEventListener('appinstalled', (evt) => {
            console.log('a2hs installed');
          });

          window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            console.log('beforeinstallprompt', event);
            this.globalService.pwaInstallPromptEvent = event;
            this.store.dispatch({
              type: 'SET_UI_VARIABLE_ACTION',
              payload: {
                variable: 'appPwaIsInstallable',
                value: true
              }
            });
          });

          document.addEventListener('fullscreenchange', (event) => {
            this.events.publish('fullscreen:changed', { isFullScreen: (document.fullscreenElement) ? true : false });
          });

          // Init oneSignal web SDK

          // try {
          //   await this.oneSignal.init({
          //     appId: 'efd52f6f-0721-42b1-9d9a-f48e9686d63a'
          //   });

          //   this.oneSignal.addListenerForNotificationOpened((callback) => {
          //     this.pushNotificationService.processOpenedNotification('web', callback);
          //   });
          // } catch (error) {
          //   Sentry.captureException(error);
          //   console.error(error);
          // }
        }

        setTimeout(() => {
          this.events.publish('app:ready', {});
        }, 1000);
      }
    });
  }

  private requestBiometricAuthentication() {
    return new Promise(async (resolve, reject) => {
      const translations = await this.translate.get(['label_fingerprint_lock', 'label_cancel', 'fingerprint_permission_error',
        'error_message_general_header', 'fingerprint_permission_error', 'popup_ok_button_label']).toPromise();
      try {
        // await NativeBiometric.verifyIdentity({
        //   reason: translations.desc_fingerprint_permission_request,
        //   title: translations.label_fingerprint_lock,
        //   description: translations.desc_fingerprint_permission_request,
        // });
        resolve({});
      } catch (error) {
        const alert = await this.alertCtrl.create({
          header: translations.error_message_general_header,
          message: translations.fingerprint_permission_error,
          buttons: [translations.popup_ok_button_label]
        });
        await alert.present();
        await this.requestBiometricAuthentication();
      }
    });
  }

  private loadDeviceInfo(): DeviceInfo {
    let platform = '', device = '', isChrome = false, chromeVersion = 0;
    if (this.plt.is('ios')) {
      platform = 'ios';
      isChrome = true;
    }

    if (this.plt.is('android')) {
      platform = 'android';
      device = 'android';
    }

    if (this.plt.is('tablet')) {
      device = 'tablet';
    }

    if (this.plt.is('iphone')) {
      device = 'iphone';
    }

    if (this.plt.is('desktop')) {
      platform = 'desktop';
      device = 'desktop';
    }

    console.log(window.navigator.userAgent);
    if (window.navigator.userAgent.indexOf('Chrome/') > -1) {
      isChrome = true;
      const agent = window.navigator.userAgent;
      let version = agent.substr(agent.indexOf('Chrome/') + 7);
      version = version.substr(0, version.indexOf('.'));
      chromeVersion = parseInt(version);
    }

    console.log('Platform: ' + platform);
    console.log('Device: ' + device);
    console.log('Capacitor: ' + this.plt.is('capacitor'));
    console.log('isChrome: ' + isChrome);
    console.log('ChromeVersion: ' + chromeVersion);
    console.log('Resolution: ' + screen.width + 'x' + screen.height);
    console.log('Resolution: ' + screen.availWidth + 'x' + screen.availHeight);

    this.store.dispatch({
      type: 'SET_BATCH_UI_VARIABLE_ACTION', payload: {
        appPlatform: platform,
        appDeviceType: device,
        appPlatformIsCordova: this.plt.is('capacitor'),
        appPlatformIsChrome: isChrome,
      }
    });

    return {
      appPlatform: platform,
      appDeviceType: device,
      appPlatformIsCordova: this.plt.is('capacitor')
    };
  }
}
