import { Directive, ElementRef, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { FileManagerService } from '../services/file-manager.service';
import { KeychainService } from '../services/keychain.service';
import { ApplicationState } from '../ngrx/application-state';
import { GlobalService } from '../services/global.service';
import { UiState } from '../ngrx/ui-state';

@Directive({
  selector: '[imageUrl]'
})
export class ImageUrlDirective {

  private file: string;

  private width: number;

  private height: number;

  private type: string;

  private date: string;

  private placeholder: string;

  constructor(private el: ElementRef, private store: Store<ApplicationState>, private plt: Platform, private globalService: GlobalService,
    private keychainService: KeychainService, private fileManagerService: FileManagerService) { }

  @Input() set imageType(type: string) {
    this.type = (type) ? type : '';
    if (this.file && this.width && this.height && this.type && this.date && this.placeholder) {
      this.loadMediaFile();
    }
  }

  @Input() set imageUrl(file: string) {
    this.file = (file) ? file : '';
    if (this.file && this.width && this.height && this.type && this.date && this.placeholder) {
      this.loadMediaFile();
    }
  }

  @Input() set imageWidth(width: number) {
    this.width = (width) ? width : 0;
    if (this.file && this.width && this.height && this.type && this.date && this.placeholder) {
      this.loadMediaFile();
    }
  }

  @Input() set imageDate(date: string) {
    this.date = (date) ? date : '';
    if (this.file && this.width && this.height && this.type && this.date && this.placeholder) {
      this.loadMediaFile();
    }
  }

  @Input() set placeholderImage(image: string) {
    this.placeholder = (image) ? image : '';
    if (this.file && this.width && this.height && this.type && this.date && this.placeholder) {
      this.loadMediaFile();
    }
  }

  @Input() set imageHeight(height: number) {
    this.height = (height) ? height : 0;
    if (this.file && this.width && this.height && this.type && this.date && this.placeholder) {
      this.loadMediaFile();
    }
  }

  private async loadMediaFile() {
    try {
      const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
      let mediaType = '';
      switch (this.type) {
        case 'profile':
          mediaType = 'users/';
          break;
        case 'location':
          mediaType = 'media/';
          break;
        case 'video':
          mediaType = 'media/';
          break;
        case 'picture':
          mediaType = 'media/';
          break;
        case 'gif':
          mediaType = 'media/';
          break;
        case 'url':
          mediaType = 'media/';
          break;
        case 'support':
          mediaType = 'support/';
          break;
        case 'live':
          mediaType = 'live-thumbnails/';
          break;
      }

      this.el.nativeElement.crossOrigin = 'Anonymous';

      // Load picture from cache

      const doc = await this.keychainService.get('media_' + this.file + this.width + this.height + this.date);
      if (doc) {
        this.el.nativeElement.src = doc.url;
        console.log(doc.url);
      } else {

        // Set placeholder image

        if (this.placeholder) {
          this.el.nativeElement.src = 'assets/img/' + ((this.type == 'profile') ? this.placeholder :
            'photo_placeholder_' + uiState.appPlatform + '.svg');
        }

        let filePath, fileUrl;
        if (this.type == 'video') {
          fileUrl = await this.globalService.getStorageFileURL(mediaType + this.file + '-thumbnail.webp');
        } else if (this.type == 'gif') {
          fileUrl = 'https://media.giphy.com/media/' + this.file + '/200w.gif';
        } else if (this.type == 'live') {
          fileUrl = await this.globalService.getStorageFileURL(mediaType + this.file + '.webp?' + this.date);
        } else if (this.type == 'support') {
          fileUrl = await this.globalService.getStorageFileURL(mediaType + this.file + '?' + this.date);
        } else {
          filePath = (this.type == 'location') ? '.webp' : '-' + this.width + 'x' + this.height + '.webp';
          fileUrl = await this.globalService.getStorageFileURL(mediaType + this.file + filePath + '?' + this.date);
        }

        console.log(fileUrl);

        // Download and cache file

        this.el.nativeElement.src = 'assets/img/photo_placeholder_' + ((this.plt.is('ios')) ? 'ios' : 'android') + '.svg';
        const localFileURL = await this.fileManagerService.download(fileUrl);

        await this.keychainService.set('media_' + this.file + this.width + this.height + this.date, { url: localFileURL });
        this.el.nativeElement.src = localFileURL;
      }
    } catch (error) {
      console.log('Could not download image');
      console.error(error);
    }
  }
}
