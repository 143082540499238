import { SupportMessageType, SupportChannels } from '../interfaces/support';
import { ConnectivityService } from './connectivity.service';
import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Events } from './events.service';
import * as _ from 'lodash';

interface UserSupportCreateData {
  text: string;
  message_type: SupportMessageType;
  message_type_id: string;
  is_anonymous: boolean;
  conversation_id: string;
  full_name: string;
  channel: SupportChannels;
  email: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class SupportService {

  private connectivityService: ConnectivityService;

  phoneNumber: string = '';

  systemStatus = '';

  news = [];

  conversations = [];

  constructor(private events: Events, private injector: Injector) { }

  init(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.connectivityService) {
          this.connectivityService = this.injector.get(ConnectivityService);
        }

        // Event listener

        this.connectivityService.feathers.service('user-support').on('created', async (data) => {
          this.conversations.push(data);
          this.events.publish('support-conversation:created', data);
        });

        this.connectivityService.feathers.service('user-support').on('updated', async (data) => {
          const index = _.findIndex(this.conversations, (o: any) => { return o.id == data.id; });
          if (index > -1) {
            this.conversations[index] = data;
          }
          this.events.publish('support-conversation:updated', data);
        });

        // Sync data

        const status = await this.connectivityService.feathers.service('user-support').find({ query: { type: 'status' } });
        const news = await this.connectivityService.feathers.service('user-support').find({ query: { type: 'news' } });
        const conversations = await this.connectivityService.feathers.service('user-support').find({ query: { type: 'conversations' } });
        this.systemStatus = status.status;
        this.conversations = conversations.conversations;
        this.news = news.results.data;
        resolve({});
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  find(params) {
    return new Promise(async (resolve, reject) => {
      this.connectivityService.feathers.service('user-support').find(params).then((results) => {
        resolve(results);
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  destroy(): void {
    this.connectivityService.feathers.service('user-support').removeListener('created');
    this.connectivityService.feathers.service('user-support').removeListener('updated');
  }

  create(data: UserSupportCreateData): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'create',
      category: 'support.service',
      data: data,
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-support').create(data);
  }

  update(conversationId, data) {
    return this.connectivityService.feathers.service('user-support').update(conversationId, data);
  }
}
