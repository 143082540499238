import { LiveEventsData, INITIAL_LIVE_EVENTS_DATA } from '../store-data';
import {
  ADD_LIVE_EVENT_ACTION, UPDATE_LIVE_EVENT_ACTION, DELETE_LIVE_EVENT_ACTION, RESET_LIVE_EVENTS_ACTION,
  AddLiveEventAction, UpdateLiveEventAction, DeleteLiveEventAction, ResetLiveEventsAction
} from '../actions/live-event';
import { Action } from '@ngrx/store';

import _ from 'lodash';

export function liveEventsData(state: LiveEventsData, action: Action): LiveEventsData {
  switch (action.type) {
    case ADD_LIVE_EVENT_ACTION:
      return handleAddLiveEventAction(state, <any>action);
    case UPDATE_LIVE_EVENT_ACTION:
      return handleUpdateLiveEventAction(state, <any>action);
    case DELETE_LIVE_EVENT_ACTION:
      return handleDeleteLiveEventAction(state, <any>action);
    case RESET_LIVE_EVENTS_ACTION:
      return handleResetLiveEventsAction(state, <any>action);
    default:
      return state;
  }
}

function handleAddLiveEventAction(state: LiveEventsData, action: AddLiveEventAction) {
  const newData = (state) ? Object.assign({}, state.list) : INITIAL_LIVE_EVENTS_DATA.list;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_LIVE_EVENTS_DATA.lastUpdatedTimestamp;
  action.payload = _.orderBy(action.payload, 'date_created', 'asc');

  for (const key in action.payload) {
    newData[action.payload[key].id] = action.payload[key];
    if (action.payload[key].date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
      lastUpdatedTimestamp = action.payload[key].date_updated;
    }
  }

  const newStoreState: LiveEventsData = {
    list: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleUpdateLiveEventAction(state: LiveEventsData, action: UpdateLiveEventAction) {
  const newData = (state) ? Object.assign({}, state.list) : INITIAL_LIVE_EVENTS_DATA.list;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_LIVE_EVENTS_DATA.lastUpdatedTimestamp;
  
  newData[action.payload.id] = action.payload;
  if (action.payload.date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
    lastUpdatedTimestamp = action.payload.date_updated;
  }

  const newStoreState: LiveEventsData = {
    list: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleDeleteLiveEventAction(state: LiveEventsData, action: DeleteLiveEventAction) {
  const newData = (state) ? Object.assign({}, state.list) : INITIAL_LIVE_EVENTS_DATA.list,
    lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_LIVE_EVENTS_DATA.lastUpdatedTimestamp;

  if (newData[action.payload.id]) {
    delete newData[action.payload.id];
  }

  const newStoreState: LiveEventsData = {
    list: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleResetLiveEventsAction(state: LiveEventsData, action: ResetLiveEventsAction) {
  return INITIAL_LIVE_EVENTS_DATA;
}
