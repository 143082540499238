import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { ConnectivityService } from './connectivity.service';
import { KeychainService } from './keychain.service';
import { Events } from './events.service';

@Injectable({
  providedIn: 'root',
})
export class VoipCallP2PService {

  private connectivityService: ConnectivityService;

  private keychainService: KeychainService;

  constructor(private events: Events, private injector: Injector) {
    this.connectivityService = this.injector.get(ConnectivityService);
    this.keychainService = this.injector.get(KeychainService);
  }

  init(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.connectivityService) {
          this.connectivityService = this.injector.get(ConnectivityService);
        }

        // Event listener

        this.connectivityService.feathers.service('video-chat-p2p').on('updated', async (data) => {

          console.log('UPDATED');
          console.log(data);

          if (data.notification_type == 'voip_call_request_accepted') {
            this.events.publish('voip-call-p2p:request-accepted', data);

          } else if (data.notification_type == 'voip_call_request_cancelled') {
            this.events.publish('voip-call-p2p:request-cancelled', data);

          } else if (data.notification_type == 'voip_call_request_declined') {
            this.events.publish('voip-call-p2p:request-declined', data);

          } else if (data.notification_type == 'voip_call_transcript_sent') {
            this.events.publish('voip-call-p2p:transcript-sent', data);

          } else if (data.notification_type == 'voip_call_message_send') {
            this.events.publish('voip-call-p2p:message-sent', data);

          } else if (data.notification_type == 'voip_call_disconnected') {
            this.events.publish('voip-call-p2p:disconnected', data);
          }
        });

        resolve({});
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  destroy() {
    return new Promise(async (resolve, reject) => {
      this.connectivityService.feathers.service('video-chat-p2p').removeListener('updated');
      resolve({});
    });
  }

  sendRequest(friendId, audioOnly): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'sendRequest',
      category: 'voip-call-p2p.service',
      data: {
        action: 'send-request',
        friend_id: friendId,
        audio_only: audioOnly
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('video-chat-p2p').create({
      action: 'send-request',
      friend_id: friendId,
      audio_only: audioOnly    
    }, {});
  }

  async acceptRequest(callId: string): Promise<any> {
    const deviceId = await this.keychainService.get('device_id');

    Sentry.addBreadcrumb({
      message: 'acceptRequest',
      category: 'voip-call-p2p.service',
      data: {
        action: 'accept-request',
        call_id: callId,
        device_id: deviceId,
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('video-chat-p2p').create({
      action: 'accept-request',
      call_id: callId,
      device_id: deviceId    
    }, {});
  }

  async declineRequest(callId: string): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'declineRequest',
      category: 'voip-call-p2p.service',
      data: {
        action: 'decline-request',
        call_id: callId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('video-chat-p2p').create({
      action: 'decline-request',
      call_id: callId
    }, {});
  }

  async cancelRequest(callId: string): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'cancelRequest',
      category: 'voip-call-p2p.service',
      data: {
        action: 'cancel-request',
        call_id: callId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('video-chat-p2p').create({
      action: 'cancel-request',
      call_id: callId
    }, {});
  }

  async sendMessage(callId: string, text = '', type: 'text' | 'animoji'): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'sendMessage',
      category: 'voip-call-p2p.service',
      data: {
        action: 'send-message',
        call_id: callId,
        text: text,
        type: type
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('video-chat-p2p').create({
      action: 'send-message',
      call_id: callId,
      text: text,
      type: type
    }, {});
  }

  async endCall(callId: string): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'endCall',
      category: 'voip-call-p2p.service',
      data: {
        action: 'end-call',
        call_id: callId
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('video-chat-p2p').create({
      action: 'end-call',
      call_id: callId
    }, {});
  }
}
