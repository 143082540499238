import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {

  private authenticationService: GlobalService;

  constructor(private router: Router, private injector: Injector) { }

  canActivate(): boolean {
    if (!this.authenticationService) {
      this.authenticationService = this.injector.get(GlobalService);
    }

    if (this.authenticationService.hasLoggedIn) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
