import { Action } from '@ngrx/store';

export const UPDATE_RECENT_ACTION = 'UPDATE_RECENT_ACTION';
export const REMOVE_RECENT_ACTION = 'REMOVE_RECENT_ACTION';
export const SELECT_RECENT_CONTACT_ACTION = 'SELECT_RECENT_CONTACT_ACTION';
export const RESET_CONTACT_SELECTION_ACTION = 'RESET_CONTACT_SELECTION_ACTION';
export const INCREASE_HISTORY_ITEMS_DISPLAY_ACTION = 'INCREASE_HISTORY_ITEMS_DISPLAY_ACTION';
export const RESET_RECENT_ACTION = 'RESET_RECENT_ACTION';

export class UpdateRecentAction implements Action {
  readonly type = UPDATE_RECENT_ACTION;
  constructor(public payload?: any) { }
}

export class RemoveRecentAction implements Action {
  readonly type = REMOVE_RECENT_ACTION;
  constructor(public payload?: any) { }
}

export class SelectRecentContactAction implements Action {
  readonly type = SELECT_RECENT_CONTACT_ACTION;
  constructor(public payload?: any) { }
}

export class ResetContactSelectionAction implements Action {
  readonly type = RESET_CONTACT_SELECTION_ACTION;
  constructor(public payload?: any) { }
}

export class ResetRecentAction implements Action {
  readonly type = RESET_RECENT_ACTION;
  constructor(public payload?: any) { }
}

export class IncreaseHistoryItemsDisplayAction implements Action {
  readonly type = INCREASE_HISTORY_ITEMS_DISPLAY_ACTION;
  constructor(public payload?: any) { }
}
