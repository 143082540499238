import { UiState } from '../ngrx/ui-state';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import { Browser } from '@capacitor/browser';
import { ApplicationState } from '../ngrx/application-state';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {

  remoteLibrariesLoaded = [];

  browserWindow: any;

  pwaInstallPromptEvent: any;

  StripeKeyDebug = 'pk_test_lUU8fG5L0icZBqOEGz47Ibfu';

  StripeKeyProduction = 'pk_live_SKue3kzYqqUywxjAXpBc2Ylp';

  hasLoggedIn = false;

  authToken: any;

  constructor(private store: Store<ApplicationState>) { }

  loadRemoteLibrary(library): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.remoteLibrariesLoaded.indexOf(library) == -1) {
        (<any>window).requirejs([library], () => {
          this.remoteLibrariesLoaded.push(library);
          resolve(undefined);
        }, (error) => {
          reject(error);
        });
      } else {
        resolve(undefined);
      }
    });
  }

  getStorageFileURL(file): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        if (file.indexOf('?') > -1) {
          file = file.substr(0, file.indexOf('?'));
        }

        console.log('https://cdn.lingu.social/' + file);
        resolve('https://cdn.lingu.social/' + file);
      } catch (error) {
        console.error(error);
        reject();
      }
    });
  }

  /*
  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  */

  toUnicode(theString): string {
    let unicodeString = '';
    for (let i = 0; i < theString.length; i++) {
      let theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
      while (theUnicode.length < 4) {
        theUnicode = '0' + theUnicode;
      }
      theUnicode = '\\u' + theUnicode;
      unicodeString += theUnicode;
    }
    return unicodeString;
  }

  loadJSFileDynamically(filename): void {
    const fileref = document.createElement('script');
    fileref.setAttribute('type', 'text/javascript');
    fileref.setAttribute('src', filename);
    if (typeof fileref != 'undefined')
      document.getElementsByTagName('head')[0].appendChild(fileref);
  }

  async openNativeBrowser(url): Promise<void> {
    Browser.open({ url: url });
  }

  async openInAppBrowser(url): Promise<void> {
    Browser.open({ url: url });
  }

  async closeInAppBrowser(): Promise<void> {
    Browser.close();
  }

  convertBlobToBase64(blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader;
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.toString());
      };
      reader.readAsDataURL(blob);
    });
  }
}
