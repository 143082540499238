import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { ApplicationState } from '../ngrx/application-state';
import { UiState } from '../ngrx/ui-state';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
 })
export class LoggerService {

  private environment = '';

  constructor(private store: Store<ApplicationState>) { }

  async logMessage(message: string, details: any, category: string, level: any): Promise<void> {
    const breadcrumb: Sentry.Breadcrumb = {
      message: message,
      category: category,
      data: details,
      level: level
    };

    Sentry.addBreadcrumb(breadcrumb);

    if (this.environment == '') {
      const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
      this.environment = uiState.appEnvironment;
      if (this.environment == 'local') {
        console.log(message, details);
      }
    } else {
      if (this.environment == 'local') {
        console.log(message, details);
      }
    }
  }
}
