import { Contact } from '../models/contact';
import { Phrasebook } from '../models/phrasebook';
import { LiveEvent } from '../models/live-event';
import { LiveVocabulary } from '../models/live-vocabulary';

export interface StoreData {
  voipCallStreamQueue: any[];
}

export const INITIAL_STORE_DATA: StoreData = {
  voipCallStreamQueue: []
};

export interface ContactsData {
  list: { [key: string]: Contact };
  listWithPhoneNumbers: { [key: string]: Contact };
  lastUpdatedTimestamp: string;
}

export const INITIAL_CONTACTS_DATA: ContactsData = {
  list: {},
  listWithPhoneNumbers: {},
  lastUpdatedTimestamp: undefined
};

export interface BlockedContact {
  id: string;
  phone_number: string;
  name: string;
}

export interface AccountData {
  details: {
    balance: number,
    call_back_pin_number: number,
    connected_clients: number,
    currency: string,
    date_created: string,
    date_updated: string,
    eu_country: string,
    id: string,
    is_debug?: boolean;
    is_active: boolean,
    is_deleted: boolean,
    is_inside_europe: boolean,
    language: string,
    payment_methods: string,
    phone_number: string,
    stripe_customer_id: string,
    custom_live_subscription_details: any;
    had_live_subscription_trial: boolean;
    is_custom_live_subscription: boolean;
    live_subscription_id: string;
    viewer_hours: number;
    coupons_used: string[];
    is_company: boolean;
    tax_exempt: boolean;
    discount: number;
    billing_address: AccountBillingAddress;
    blocked_contacts: BlockedContact[];
    receive_newsletter: boolean;
    subscription: {
      plan_billing_cycle: 'monthly' | 'annually';
      import_existing_transcripts: number;
      next_billing_cycle_date: string;
      next_billing_cycle_payment_attempts: number;
      next_billing_cycle_payment_status: string;
      next_billing_cycle_plan_id: string;
      phone_call_balance: number;
      phone_call_interpreter_minutes: number;
      phone_call_interpreter_minutes_purchased: number;
      plan_id: string;
      plan_time_period: string;
      subscription_date_end: string;
      subscription_date_started: string;
      transcription_minutes: number;
      transcription_minutes_purchased: number;
      next_billing_cycle_type: 'monthly' | 'annually';
      next_billing_cycle_payment_method_id: string;
      next_billing_cycle_payment_method_schedule_type: 'automatic' | 'manual';
      next_billing_cycle_payment_id: string;
    }
  };

  profile: {
    date_picture_updated: string,
    date_updated: string,
    email: string,
    friends: object,
    full_name: string,
    has_picture: boolean,
    id: string,
    is_online: string,
    language: string,
    last_seen: string,
    phone_number: string
  };
  transactions: { [key: string]: any };
  lastTransactionsUpdatedTimestamp: string;
}

export interface AccountBillingAddress {
  company_name: string;
  full_name: string;
  line1: string;
  line2: string;
  city: string;
  postal_code: string;
  state: string;
  country: string;
  vat_number: string;
}

export const INITIAL_ACCOUNT_DATA: AccountData = {
  details: {
    balance: 0,
    call_back_pin_number: 0,
    connected_clients: 0,
    currency: '',
    date_created: '',
    date_updated: '',
    eu_country: '',
    id: '',
    is_debug: false,
    is_active: true,
    is_deleted: false,
    is_inside_europe: false,
    language: '',
    payment_methods: '',
    phone_number: '',
    stripe_customer_id: '',
    custom_live_subscription_details: {},
    had_live_subscription_trial: false,
    is_custom_live_subscription: false,
    live_subscription_id: 'personal',
    viewer_hours: 0,
    coupons_used: [],
    is_company: false,
    tax_exempt: false,
    discount: 0,
    blocked_contacts: [],
    receive_newsletter: false,
    billing_address: {
      company_name: '',
      full_name: '',
      line1: '',
      line2: '',
      city: '',
      postal_code: '',
      state: '',
      country: '',
      vat_number: ''
    },
    subscription: {
      next_billing_cycle_payment_method_schedule_type: 'automatic',
      next_billing_cycle_payment_method_id: '',
      plan_billing_cycle: 'monthly',
      import_existing_transcripts: 0,
      next_billing_cycle_date: '',
      next_billing_cycle_payment_attempts: 0,
      next_billing_cycle_payment_status: 'completed',
      next_billing_cycle_plan_id: 'payg',
      next_billing_cycle_type: 'monthly',
      phone_call_balance: 0,
      phone_call_interpreter_minutes: 0,
      phone_call_interpreter_minutes_purchased: 0,
      plan_id: 'payg',
      plan_time_period: 'monthly',
      subscription_date_end: '',
      subscription_date_started: '2025-02-18T22:19:02.291Z',
      transcription_minutes: 0,
      transcription_minutes_purchased: 0,
      next_billing_cycle_payment_id: ''
    }
  },
  profile: {
    date_picture_updated: '',
    date_updated: '',
    email: '',
    friends: {},
    full_name: '',
    has_picture: false,
    id: '',
    is_online: '',
    language: '',
    last_seen: '',
    phone_number: ''
  },
  transactions: {},
  lastTransactionsUpdatedTimestamp: undefined
};

export interface NotificationsData {
  subtotalUnreadMessageItems: { [key: string]: any };
  subtotalUnreadMessages: { [key: string]: any };
  totalUnreadMessages: number;
  totalUnreadSystenMessages: number;
  unreadSystemMessages: any[];
  friendsTyping: { [key: string]: any };
}

export const INITIAL_NOTIFICATIONS_DATA: NotificationsData = {
  subtotalUnreadMessageItems: {},
  subtotalUnreadMessages: {},
  totalUnreadMessages: 0,
  totalUnreadSystenMessages: 0,
  unreadSystemMessages: [],
  friendsTyping: {}
};

export interface PhrasebookData {
  list: { [key: string]: Phrasebook };
  lastUpdatedTimestamp: string;
}

export const INITIAL_PHRASEBOOK_DATA: PhrasebookData = {
  list: {},
  lastUpdatedTimestamp: undefined
};

export interface ConfigDataDetails {
  data: any;
}

export interface ConfigData {
  config_coupons: ConfigDataDetails;
  config_access_numbers: ConfigDataDetails;
  config_currency_rates: ConfigDataDetails;
  config_locales: ConfigDataDetails;
  config_vat_rates: ConfigDataDetails;
  config_stripe_currencies: ConfigDataDetails;
  config_pricing: ConfigDataDetails;
  config_live_subscriptions: ConfigDataDetails;
  lastUpdatedTimestamp: string;
}

export const INITIAL_CONFIG_DATA: ConfigData = {
  config_coupons: { data: {} },
  config_access_numbers: { data: {} },
  config_currency_rates: { data: {} },
  config_locales: { data: {} },
  config_vat_rates: { data: {} },
  config_stripe_currencies: { data: {} },
  config_pricing: { data: {} },
  config_live_subscriptions: { data: {} },
  lastUpdatedTimestamp: undefined
};

export interface RecentData {
  data: { [key: string]: any };
  lastUpdatedTimestamp: string;
  selectedContact: {
    type: string,
    id: string,
    locale: string,
    actionType: string
  };
  selectedContactHistoryPos: number;
}

export const INITIAL_RECENT_DATA: RecentData = {
  data: {},
  lastUpdatedTimestamp: undefined,
  selectedContact: {
    type: '',
    id: '',
    locale: '',
    actionType: ''
  },
  selectedContactHistoryPos: 20
};

export interface TranscriptsExplorerData {
  data: { [key: string]: any };
  lastUpdatedTimestamp: string;
  selectedTab: 'folders' | 'favourites' | 'shared';
  sortByFilter: 'name' | 'date_updated' | 'status';
  sortByFilterAlpha: 'asc' | 'desc';
  breadcrumbs: any[];
  selectedItem: any;
}

export const INITIAL_TRANSCRIPTS_EXPLORER_DATA: TranscriptsExplorerData = {
  data: {},
  lastUpdatedTimestamp: undefined,
  selectedTab: 'folders',
  sortByFilter: 'name',
  sortByFilterAlpha: 'asc',
  breadcrumbs: [],
  selectedItem: undefined
};

export interface HistoryData {
  data: { [key: string]: any };
  lastUpdatedTimestamp: string;
}

export const INITIAL_HISTORY_DATA: HistoryData = {
  data: {},
  lastUpdatedTimestamp: undefined
};

export interface TranscriptsData {
  data: { [key: string]: any };
  lastUpdatedTimestamp: string;
}

export const INITIAL_TRANSCRIPTS_DATA: TranscriptsData = {
  data: {},
  lastUpdatedTimestamp: undefined
};

export interface SupportData {
  authenticatedData: any[];
  anonymousData: any[];
  unreadMessagesTotal: number;
  unreadMessagesList: any[];
}

export const INITIAL_SUPPORT_DATA: SupportData = {
  authenticatedData: [],
  anonymousData: [],
  unreadMessagesTotal: 0,
  unreadMessagesList: []
};

export interface LiveEventsData {
  list: { [key: string]: LiveEvent };
  lastUpdatedTimestamp: string;
}

export const INITIAL_LIVE_EVENTS_DATA: LiveEventsData = {
  list: {},
  lastUpdatedTimestamp: undefined
};

export interface LiveVocabularyData {
  list: { [key: string]: LiveVocabulary };
  lastUpdatedTimestamp: string;
}

export const INITIAL_LIVE_VOCABULARY_DATA: LiveVocabularyData = {
  list: {},
  lastUpdatedTimestamp: undefined
};