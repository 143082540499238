import { PluginListenerHandle } from '@capacitor/core';
import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { Network } from '@capacitor/network';

import { Events } from './events.service';
import { KeychainService } from './keychain.service';
import { GlobalizationService } from './globalization.service';
import { DataSynchronizationService } from './data-synchronization.service';

import { Store } from '@ngrx/store';
import { UiState } from '../ngrx/ui-state';
import { ApplicationState } from '../ngrx/application-state';

import authentication from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import feathers from '@feathersjs/feathers';
import { jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/angular-ivy';
import io from 'socket.io-client';
import { DateTime } from 'luxon';

import { getApp } from 'firebase/app';
import { getMessaging, deleteToken } from 'firebase/messaging';

interface EnvironmentDetails {
  region: string;
  location: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConnectivityService {

  socket: any;

  networkType = '';

  feathers: feathers.Application;

  ip = '';

  ipCity = '';

  ipCountry = '';

  ipRegion = '';

  ipTimeZone = '';

  private keychainService: KeychainService;

  private globalizationService: GlobalizationService;

  private dataSynchronizationService: DataSynchronizationService;

  constructor(private events: Events, private store: Store<ApplicationState>, private router: Router, private plt: Platform,
    private http: HttpClient, private injector: Injector) { }

  async init() {
    if (!this.dataSynchronizationService) {
      this.dataSynchronizationService = this.injector.get(DataSynchronizationService);
      this.globalizationService = this.injector.get(GlobalizationService);
      this.keychainService = this.injector.get(KeychainService);
    }

    const status = await Network.getStatus();
    this.networkType = status.connectionType;
    this.store.dispatch({
      type: 'SET_UI_VARIABLE_ACTION', payload: {
        variable: 'appIsOnline',
        value: status.connected
      }
    });

    Network.addListener('networkStatusChange', async (status) => {
      if (status.connected) {
        this.events.publish('network:online', {});
        this.store.dispatch({
          type: 'SET_UI_VARIABLE_ACTION', payload: {
            variable: 'appIsOnline',
            value: true
          }
        });

        const userId = await this.keychainService.get('user_id');
        if (userId) {
          setTimeout(() => {
            this.initializeWebsockets().then(async () => {
              const token = await this.keychainService.get('jwt');
              this.feathers.authenticate({
                strategy: 'jwt',
                accessToken: token,
                type: 'jwt'
              }).then(() => {
                this.dataSynchronizationService.loadAllData();
              }).catch((error) => {
                Sentry.captureException(error);
                console.error(error);
              });
            }).catch((error) => {
              console.error(error);
            });
          }, 1000);
        }

        console.log('Network connected!');

      } else {

        this.events.publish('network:offline', {});
        this.store.dispatch({
          type: 'SET_UI_VARIABLE_ACTION', payload: {
            variable: 'appIsOnline',
            value: false
          }
        });

        console.log('Network disconnected!');
      }
    });
  }

  initializeWebsockets(callId?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const uiState: UiState = await this.store.select('uiState').pipe(first()).toPromise();
        if (!uiState.appWebsocketsAreInitialized) {

          // Get environment details based on users IP address        

          await this._getIpAddress();

          // Set global vars

          this.store.dispatch({
            type: 'SET_BATCH_UI_VARIABLE_ACTION', payload: {
              appEnvironmentUrl: 'https://api.lingu.social',
              appEnvironmentRegion: this.ipRegion,
              appUserCountry: this.ipCountry
            }
          });

          Sentry.addBreadcrumb({
            message: 'initializeWebsockets',
            category: 'connectivity.service',
            data: {
              environmentDetails: {
                region: this.ipRegion,
                location: ''
              }
            },
            level: 'info'
          });

          // Connect to backend via websockets

          if (this.plt.is('capacitor') || document.URL.indexOf('app.lingu.social') > -1) {
            this.socket = io('https://api.lingu.social', {
              transports: ['websocket'],
              upgrade: false
            });
          } else if (document.URL.indexOf('staging.lingu.social') > -1) {
            this.socket = io('https://api-staging.lingu.social', {
              transports: ['websocket'],
              upgrade: false
            });
          } else if (document.URL.indexOf('dev.lingu.social') > -1) {
            this.socket = io('https://api-dev.lingu.social', {
              transports: ['websocket'],
              upgrade: false
            });
          } else {
            this.socket = io('http://localhost:8080', {
              transports: ['websocket'],
              upgrade: false
            });
          }

          // Connection status handling

          this.socket.on('disconnect', () => {
            this.events.publish('network:offline', {});
            this.store.dispatch({
              type: 'SET_UI_VARIABLE_ACTION', payload: {
                variable: 'appIsOnline',
                value: false
              }
            });

            console.log('Socket disconnected!');
          });

          this.socket.on('reconnect', async () => {
            this.events.publish('network:online', {});

            // Get environment details based on users IP address        

            this._getIpAddress();

            this.store.dispatch({
              type: 'SET_UI_VARIABLE_ACTION', payload: {
                variable: 'appIsOnline',
                value: true
              }
            });

            // Set global vars

            this.store.dispatch({
              type: 'SET_BATCH_UI_VARIABLE_ACTION', payload: {
                appEnvironmentRegion: this.ipRegion,
                appUserCountry: this.ipCountry
              }
            });

            const token = await this.keychainService.get('jwt');
            const userId = await this.keychainService.get('user_id');

            if (userId) {
              setTimeout(() => {
                this.feathers.authenticate({
                  strategy: 'jwt',
                  accessToken: token,
                  type: 'jwt'
                }).then(() => {
                  this.dataSynchronizationService.loadAllData();
                }).catch((error) => {
                  Sentry.captureException(error);
                  console.log('AUTH PROB');
                  console.error(error);
                });
              }, 5000);
            }

            console.log('Socket connected!');
          });

          this.feathers = feathers();
          this.feathers.configure(socketio(this.socket, { timeout: 30000 }));
          this.feathers.configure(authentication());
          this.feathers.service('user-devices').timeout = 5000;

          // Authenticate with JWT token

          const userId = await this.keychainService.get('user_id');
          if (userId) {
            const token = await this.keychainService.get('jwt');

            // Logout when session is missing device id or session expired

            await this.feathers.authenticate({
              strategy: 'jwt',
              accessToken: token,
              type: 'jwt'
            });

            const tokenData: any = jwtDecode(token);
            await this.keychainService.set('device_id', tokenData.device_id);
            if (!tokenData.device_id || (tokenData.device_id && tokenData.device_id.length <= 1) || DateTime.now().toUnixInteger() > tokenData.exp) {
              setTimeout(() => {
                this.logout();
              }, 5000);
            }

            resolve({});

          } else {

            // Login anonymously

            let tokenData;
            const authAnonymousService = this.feathers.service('auth-anonymous');
            const anonymousUserId = await this.keychainService.get('anonymous_user_id');
            const anonymousToken = await this.keychainService.get('anonymous_token');
            if (anonymousUserId && anonymousToken) {
              tokenData = { token: anonymousToken, user_id: anonymousUserId };
            } else {
              if (callId) {
                tokenData = await authAnonymousService.create({ call_id: callId }, {});
              } else {
                tokenData = await authAnonymousService.create({}, {});
              }
              await this.keychainService.set('anonymous_user_id', tokenData.user_id);
              await this.keychainService.set('anonymous_token', tokenData.token);
            }

            await this.feathers.authenticate({
              strategy: 'jwt',
              accessToken: tokenData.token,
              type: 'jwt'
            });

            this.store.dispatch({
              type: 'SET_UI_VARIABLE_ACTION', payload: {
                variable: 'appAnonymousUserId',
                value: tokenData.user_id
              }
            });

            resolve({});
          }
        } else {
          resolve({});
        }
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      }
    });
  }

  private async _getIpAddress() {
    try {
      const ipify: any = await this.http.get('https://www.lingu.social/get-ip-address').toPromise();
      this.ip = ipify.ip;
      this.ipCountry = ipify.country;
      this.ipRegion = ipify.region;
    } catch (error) { }
  }

  async logout(): Promise<void> {
    Network.removeAllListeners();
    this.dataSynchronizationService.resetDataStorage();
    this.keychainService.flush();

    await deleteToken(getMessaging(getApp()));
    await this.feathers.logout();
    await this.keychainService.flush();

    if (!this.plt.is('capacitor')) {
      window.caches.delete('lingusocial');
    }

    setTimeout(() => {
      this.store.dispatch({
        type: 'SET_UI_VARIABLE_ACTION', payload: {
          variable: 'appIsReady',
          value: false
        }
      });

      this.globalizationService.init().then(() => {
        setTimeout(() => {
          this.events.publish('app:ready', {});
        }, 2000);
        this.router.navigate(['/login']);
      });
    }, 500);
  }
}
