import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { ConnectivityService } from './connectivity.service';
import { GlobalizationService } from './globalization.service';
import { PhoneValidatorService } from './phone-validator.service';
import { VoiceCallService } from './voice-call.service';

import { ApplicationState } from '../ngrx/application-state';
import { ConfigData, AccountData } from '../ngrx/store-data';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { resolve } from 'path';

export interface PricingDetails {
  pricingPerMinuteTranslated: number;
  pricingPerMinute: number;
  pricingRaw: number;
  countryCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class PricingService {

  private connectivityService: ConnectivityService;

  private phoneValidatorService: PhoneValidatorService;

  private globalizationService: GlobalizationService;

  private voiceCallService: VoiceCallService;

  private priceSyncTimestamps = {};

  private twilioPricingDetails = {};

  constructor(private store: Store<ApplicationState>, private injector: Injector) { }

  load() {
    return new Promise(async (resolve, reject) => {
      if (!this.phoneValidatorService) {
        this.phoneValidatorService = this.injector.get(PhoneValidatorService);
        this.globalizationService = this.injector.get(GlobalizationService);
        this.connectivityService = this.injector.get(ConnectivityService);
        this.voiceCallService = this.injector.get(VoiceCallService);
      }

      const configData: ConfigData = await this.store.select('configData').pipe(first()).toPromise();
      const config = this.connectivityService.feathers.service('user-config');

      // Event listener

      config.removeListener('updated');
      config.on('updated', (data) => {
        Sentry.addBreadcrumb({
          message: 'on.updated',
          category: 'pricing.service',
          data: {
            data: data
          },
          level: 'info'
        });

        this.store.dispatch({
          type: 'UPDATE_CONFIG_ACTION',
          payload: [data]
        });

        console.log('Pricing updated');
      });

      // Get config data

      config.find({
        query: {
          date_updated: {               
            $gt: (!configData || !configData.lastUpdatedTimestamp) ? '' : configData.lastUpdatedTimestamp
          }
        }
      }).then((results) => {
        if (results && results.data.length > 0) {
          this.store.dispatch({
            type: 'UPDATE_CONFIG_ACTION',
            payload: results.data
          });
        }

        console.log('Config synced');
        resolve({});
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  /**
   * Get pricing for given mobile number
   * @param  Number phoneNumber
   * @return Object
   */
  getPricingForNumber(phoneNumber, includeSMS): Promise<PricingDetails> {
    return new Promise(async (resolve, reject) => {
      try {
        const configData: ConfigData = await this.store.select('configData').pipe(first()).toPromise();
        const accountData: AccountData = await this.store.select('accountData').pipe(first()).toPromise();

        const phoneNumberDetails = this.phoneValidatorService.getNumberDetails(phoneNumber);
        const countryCode = phoneNumberDetails.country.toUpperCase();

        let pricingPerMinute: number = 0.00;
        let pricingRaw: number = 0.00;
        let pricingPerMinuteTranslated: number = 0.00;

        // Check if number is valid

        if (countryCode.length > 0) {

          console.log(phoneNumberDetails);

          // Get default pricing based on country

          if (configData.config_pricing.data[countryCode]) {
            const pricingDetails = configData.config_pricing.data[countryCode];
            if (accountData.details.currency != 'usd') {
              pricingRaw = this.globalizationService.formatNumber((pricingDetails[(phoneNumberDetails.type == 'mobile') ? 'mobile' : 'landline'].pricing_raw * 100), 2);
              pricingPerMinute = this.globalizationService.formatNumber((pricingDetails[(phoneNumberDetails.type == 'mobile') ? 'mobile' : 'landline'].pricing_untranslated_eur * 100), 2);
              pricingPerMinuteTranslated = this.globalizationService.formatNumber((pricingDetails[(phoneNumberDetails.type == 'mobile') ? 'mobile' : 'landline'].pricing_translated_eur * 100), 2);
            } else {
              pricingRaw = this.globalizationService.formatNumber((pricingDetails[(phoneNumberDetails.type == 'mobile') ? 'mobile' : 'landline'].pricing_raw * 100), 2);
              pricingPerMinute = this.globalizationService.formatNumber((pricingDetails[(phoneNumberDetails.type == 'mobile') ? 'mobile' : 'landline'].pricing_untranslated_usd * 100), 2);
              pricingPerMinuteTranslated = this.globalizationService.formatNumber((pricingDetails[(phoneNumberDetails.type == 'mobile') ? 'mobile' : 'landline'].pricing_translated_usd * 100), 2);
            }

            // Get Twilio pricing details

            try {
              if (!this.priceSyncTimestamps[countryCode]) {
                this.twilioPricingDetails[countryCode] = await this.voiceCallService.getPricingForCountry(countryCode);
                this.priceSyncTimestamps[countryCode] = DateTime.now().toISO();
              } else {
                if ((DateTime.now().toUnixInteger() - this.priceSyncTimestamps[countryCode]) > 60) {
                  this.twilioPricingDetails[countryCode] = await this.voiceCallService.getPricingForCountry(countryCode);
                  this.priceSyncTimestamps[countryCode] = DateTime.now().toISO();
                }
              }
            } catch (e) { }

            // Iterate through destination phone number digits

            const tmpPhoneNumberLength = phoneNumber.length, tmpUserPhoneNumberLength = accountData.details.phone_number.length,
              numberType = (phoneNumberDetails.type == 'mobile') ? 'mobile' : 'landline', customPricingDetailsList = [];
            let tmpPhoneNumber = phoneNumber, tmpUserPhoneNumber = accountData.details.phone_number, customPricingDetails;
            for (let i = 0; i < tmpPhoneNumberLength; i++) {

              // Match destination number against destination prefixes

              for (let x = 0; x < this.twilioPricingDetails[countryCode][numberType].length; x++) {
                const customPricingIndex = this.twilioPricingDetails[countryCode][numberType][x]['destination_prefixes'].indexOf(tmpPhoneNumber);
                if (customPricingIndex > -1) {

                  // Match user phone number against origination prefixes

                  for (let y = 0; y < tmpUserPhoneNumberLength; y++) {
                    if (this.twilioPricingDetails[countryCode][numberType][x]['origination_prefixes'][0] == 'ALL') {
                      customPricingDetailsList.push(this.twilioPricingDetails[countryCode][numberType][x]);
                      break;
                    } else {
                      if (this.twilioPricingDetails[countryCode][numberType][x]['origination_prefixes'].indexOf(tmpUserPhoneNumber) > -1) {
                        customPricingDetailsList.push(this.twilioPricingDetails[countryCode][numberType][x]);
                        break;
                      }
                    }
                    tmpUserPhoneNumber = tmpUserPhoneNumber.substr(0, tmpUserPhoneNumber.length - 1);
                  }
                }
              }
              tmpPhoneNumber = tmpPhoneNumber.substr(0, tmpPhoneNumber.length - 1);
              if (customPricingDetailsList.length > 0) {
                break;
              }
            }

            if (customPricingDetailsList.length > 0) {
              customPricingDetails = _.sortBy(customPricingDetailsList, 'pricing_raw')[0];
              if (accountData.details.currency != 'usd') {
                pricingRaw = this.globalizationService.formatNumber(customPricingDetails.pricing_raw * 100, 2);
                pricingPerMinute = this.globalizationService.formatNumber(customPricingDetails.pricing_untranslated_eur * 100, 2);
                pricingPerMinuteTranslated = this.globalizationService.formatNumber(customPricingDetails.pricing_translated_eur * 100, 2);
              } else {
                pricingRaw = this.globalizationService.formatNumber(customPricingDetails.pricing_raw * 100, 2);
                pricingPerMinute = this.globalizationService.formatNumber(customPricingDetails.pricing_untranslated_usd * 100, 2);
                pricingPerMinuteTranslated = this.globalizationService.formatNumber(customPricingDetails.pricing_translated_usd * 100, 2);
              }
            }
          }

          resolve({
            'pricingPerMinuteTranslated': pricingPerMinuteTranslated,
            'pricingPerMinute': pricingPerMinute,
            'pricingRaw': pricingRaw,
            'countryCode': countryCode
          });
        }
      } catch (error) {
        Sentry.captureException(error);
        reject();
      }
    });
  }

  getGeneralPricing() {
    return new Promise(async (resolve, reject) => {
      const configData: ConfigData = await this.store.select('configData').pipe(first()).toPromise();
      resolve(configData.config_pricing.data['general']);
    });
  }
}
