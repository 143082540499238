import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Settings } from 'luxon';

import { Device } from '@capacitor/device';
import { ApplicationState } from '../ngrx/application-state';
import { KeychainService } from './keychain.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalizationService {

  defaultLanguage = '';

  defaultLocale = '';

  languageKeys = ['af_ZA', 'am_ET', 'ar_AE', 'ar_BH', 'ar_DZ', 'ar_EG', 'ar_IL', 'ar_IQ', 'ar_JO', 'ar_KW', 'ar_LB', 'ar_LY', 'ar_MA', 'ar_MR', 'ar_OM',
    'ar_PS', 'ar_QA', 'ar_SA', 'ar_SY', 'ar_TN', 'ar_YE', 'az_AZ', 'bg_BG', 'bn_BD', 'bn_IN', 'bs_BA', 'ca_ES', 'cs_CZ', 'cy_GB', 'da_DK', 'de_AT', 'de_CH',
    'de_DE', 'el_GR', 'en_AU', 'en_CA', 'en_GB', 'en_GH', 'en_HK', 'en_IE', 'en_IN', 'en_KE', 'en_NG', 'en_NZ', 'en_PH', 'en_PK', 'en_SG', 'en_TZ', 'en_US',
    'en_ZA', 'es_AR', 'es_BO', 'es_CL', 'es_CO', 'es_CR', 'es_CU', 'es_DO', 'es_EC', 'es_ES', 'es_GQ', 'es_GT', 'es_HN', 'es_MX', 'es_NI', 'es_PA', 'es_PE',
    'es_PR', 'es_PY', 'es_SV', 'es_US', 'es_UY', 'es_VE', 'et_EE', 'eu_ES', 'fa_IR', 'fi_FI', 'fil_PH', 'fr_BE', 'fr_CA', 'fr_CH', 'fr_FR', 'ga_IE', 'gl_ES',
    'gu_IN', 'he_IL', 'hi_IN', 'hr_HR', 'hu_HU', 'hy_AM', 'id_ID', 'is_IS', 'it_CH', 'it_IT', 'ja_JP', 'jv_ID', 'ka_GE', 'kk_KZ', 'km_KH', 'kn_IN', 'ko_KR',
    'lo_LA', 'lt_LT', 'lv_LV', 'mk_MK', 'ml_IN', 'mn_MN', 'mr_IN', 'ms_MY', 'mt_MT', 'my_MM', 'nb_NO', 'ne_NP', 'nl_BE', 'nl_NL', 'pa_IN', 'pl_PL', 'ps_AF',
    'pt_BR', 'pt_PT', 'ro_RO', 'ru_RU', 'si_LK', 'sk_SK', 'sl_SI', 'so_SO', 'sq_AL', 'sr_RS', 'su_ID', 'sv_SE', 'sw_KE', 'sw_TZ', 'ta_IN', 'ta_LK', 'ta_MY',
    'ta_SG', 'te_IN', 'th_TH', 'tr_TR', 'uk_UA', 'ur_IN', 'ur_PK', 'uz_UZ', 'vi_VN', 'wuu_CN', 'yue_CN', 'zh_CN', 'zh_CN_shandong', 'zh_CN_sichuan', 'zh_HK',
    'zh_TW', 'zu_ZA'];

  supportedLanguages = ['en', 'de', 'es'];

  spellcheckerLanguages = ['ar', 'ast', 'be', 'br', 'ca-ES', 'zh-CN', 'da', 'nl', 'nl-BE', 'en-AU', 'en-CA', 'en-GB', 'en-NZ', 'en-ZA', 'en-US', 'eo', 'fr', 'gl',
    'de', 'de-DE', 'de-AT', 'de-CH', 'el', 'ga', 'it', 'ja', 'km', 'fa', 'pl', 'pt', 'pt-AO', 'pt-BR', 'pt-MZ', 'pt-PT', 'ro', 'ru', 'sk', 'sl', 'es', 'tl',
    'ta', 'uk', 'sv', 'nl-NL', 'en'];

  customPhrasesLanguages: string[] = [
    'ar_SA', 'de_CH', 'de_DE', 'en_AU', 'en_CA', 'en_GB', 'en_IE', 'en_IN', 'en_US', 'en_ZA', 'es_ES', 'es_MX', 'es_US', 'fr_CA', 'fr_FR', 'hi_IN',
    'hu_HU', 'it_IT', 'ja_JP', 'ko_KR', 'nl_NL', 'pl_PL', 'pt_BR', 'pt_PT', 'ro_RO', 'sv_SE', 'th_TH', 'vi_VN', 'zh_CN', 'zh_HK', 'zh_TW'
  ];

  private keychainService: KeychainService;

  constructor(private translate: TranslateService, private store: Store<ApplicationState>, private injector: Injector) { }

  async init() {
    return new Promise(async (resolve, reject) => {
      if (!this.keychainService) {
        this.keychainService = this.injector.get(KeychainService);
      }

      const userId = await this.keychainService.get('user_id');
      if (!userId) {
        const systemLanguage = await Device.getLanguageCode();
        let defaultLanguage = systemLanguage.value;

        const tag = await Device.getLanguageTag();
        this.defaultLocale = tag.value.replace('-', '_');
        if (this.languageKeys.indexOf(this.defaultLocale) == -1) {
          this.defaultLocale = 'en_US';
        }

        console.log('System language: ' + systemLanguage.value);
        console.log('System locale: ' + tag.value);

        if (systemLanguage.value == 'zh') {
          defaultLanguage = 'zh-cn';
        } else if (systemLanguage.value == 'zh-CN') {
          defaultLanguage = 'zh-cn';
        } else if (systemLanguage.value == 'zh-HK') {
          defaultLanguage = 'zh-hk';
        } else if (systemLanguage.value == 'zh-SG') {
          defaultLanguage = 'zh-cn';
        } else if (systemLanguage.value == 'zh-TW') {
          defaultLanguage = 'zh-tw';
        } else {
          defaultLanguage = (this.supportedLanguages.indexOf(systemLanguage.value.substr(0, 2)) > -1) ?
            this.supportedLanguages[this.supportedLanguages.indexOf(systemLanguage.value.substr(0, 2))] : 'en';
        }

        // globalService.isRTL = (defaultLanguage == 'ar' || defaultLanguage == 'he') ? true : false;

        /**
         * @todo Set to en for now because other locale files are not finalised
         */

        this.defaultLanguage = defaultLanguage;
        this.translate.setDefaultLang(this.defaultLanguage);
        await this.translate.use(this.defaultLanguage).toPromise();
        resolve(this.defaultLanguage);
      } else {
        const locale = await this.setDefaultLanguage('');
        this.defaultLanguage = locale;
        resolve(locale);
      }
    });
  }

  setDefaultLanguage(defaultLanguage): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const accountData = await this.store.select('accountData').pipe(first()).toPromise();
      if (defaultLanguage.length == 0) {
        if (accountData) {
          const languageShort = accountData.details.language.substr(0, 2);
          if (accountData.details.language == 'zh_CN' || accountData.details.language == 'zh_HK' || accountData.details.language == 'zh_TW') {
            defaultLanguage = accountData.details.language.replace('_', '-').toLowerCase();
          } else if (this.supportedLanguages.indexOf(languageShort) > -1) {
            defaultLanguage = this.supportedLanguages[this.supportedLanguages.indexOf(languageShort)];
          } else {
            defaultLanguage = 'en';
          }
        } else {
          defaultLanguage = 'en';
        }
      }

      /**
       * @todo Set to en for now because other locale files are not finalised
       */

      defaultLanguage = 'en';

      // this.store.dispatch({
      //   type: 'SET_UI_VARIABLE_ACTION',
      //   payload: {
      //     variable: 'appIsRTL',
      //     value: (defaultLanguage == 'ar' || defaultLanguage == 'he') ? true : false
      //   }
      // });

      // this.platform.setDir('rtl', true);

      Settings.defaultLocale = defaultLanguage;
      this.translate.setDefaultLang(defaultLanguage);
      await this.translate.use(defaultLanguage).toPromise();
      resolve(defaultLanguage);
    });
  }

  formatNumber(number: number, decimals: number) {
    const x = Math.pow(10, Number(decimals) + 1);
    return Number.parseFloat((Number(number) + (1 / x)).toFixed(decimals));
  }

  hasSpellcheckerSupport(locale: string): { hasSupport: boolean, locale: string } {
    locale = locale.toLowerCase().replace('_', '-');
    if (this.spellcheckerLanguages.indexOf(locale) > -1) {
      return { hasSupport: true, locale: locale.replace('_', '-') };
    }
    if (this.spellcheckerLanguages.indexOf(locale.substr(0, 2)) > -1) {
      return { hasSupport: true, locale: locale.substr(0, 2) };
    }
    return { hasSupport: false, locale: '' };
  }
}
