import { Action } from '@ngrx/store';
import { Phrasebook } from '../../models/phrasebook';

export const ADD_PHRASEBOOK_ACTION = 'ADD_PHRASEBOOK_ACTION';
export const REMOVE_PHRASEBOOK_ACTION = 'REMOVE_PHRASEBOOK_ACTION';
export const RESET_PHRASEBOOK_ACTION = 'RESET_PHRASEBOOK_ACTION';

export class AddPhrasebookAction implements Action {
  readonly type = ADD_PHRASEBOOK_ACTION;
  constructor(public payload?: Phrasebook[]) { }
}

export class RemovePhrasebookAction implements Action {
  readonly type = REMOVE_PHRASEBOOK_ACTION;
  constructor(public payload?: string) { }
}

export class ResetPhrasebookAction implements Action {
  readonly type = RESET_PHRASEBOOK_ACTION;
  constructor(public payload?: string) { }
}
