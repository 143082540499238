import { Platform, ModalController } from '@ionic/angular';
import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ApplicationState } from '../ngrx/application-state';
import { RecentData, ContactsData } from '../ngrx/store-data';
import { Events } from './events.service';

import { SupportModalPage } from '../pages/support-modal/support-modal.page';
import { ConnectivityService } from './connectivity.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  private connectivityService: ConnectivityService;

  constructor(private store: Store<ApplicationState>, private plt: Platform, private events: Events,
    private modalCtrl: ModalController, private router: Router, private injector: Injector) {
    this.connectivityService = this.injector.get(ConnectivityService);
  }

  async processOpenedNotification(platform, notification) {
    console.log('PN handleNotificationOpened');
    console.log(platform, notification);

    let item, type;
    if (platform == 'mobile') {
      item = notification.notification.payload.additionalData;
      type = notification.notification.payload.additionalData.type;
    } else {
      item = notification.data;
      type = notification.data.type;
    }

    if (type == 'user_history') {

      // Open conversation dialog

      const contactsData: ContactsData = await this.store.select('contactsData').pipe(first()).toPromise();
      const recentData: RecentData = await this.store.select('recentData').pipe(first()).toPromise();

      if (item.sender && item.sender == 'support') {

        // Support message

        const modal = await this.modalCtrl.create({
          component: SupportModalPage,
          cssClass: (this.plt.is('desktop') || this.plt.is('tablet')) ? 'custom-modal' : '',
        });
        await modal.present();

      } else {

        // Instant message

        if (recentData.selectedContact.id != item.recent_id) {
          setTimeout(() => {
            this.store.dispatch({
              type: 'RESET_CONTACT_SELECTION_ACTION',
              payload: ''
            });

            this.store.dispatch({
              type: 'SELECT_RECENT_CONTACT_ACTION',
              payload: {
                type: 'contact',
                id: item.recent_id,
                actionType: 'text',
                locale: contactsData.list[item.recent_id].language
              }
            });

            if (!this.plt.is('desktop') && !this.plt.is('tablet')) {
              this.router.navigate(['/instant-messages']);
            } else {
              this.events.publish('chat:selected', { previousId: recentData.selectedContact.id });
            }
          }, (recentData.data[item.recent_id] && recentData.data[item.recent_id].id == item.id) ? 0 : 1000);
        }
      }

    } else if (type == 'voip_call_request_send') {

      // Send voip call action notification

      setTimeout(() => {
        const hasAccepted = (notification.action.actionID == 'accept') ? true : false;
        this.events.publish('voip-call-p2p:push-notification-action', { hasAccepted: hasAccepted });
      }, 1000);
    }
  }

  /**
   * Update notification token
   * 
   * @param token 
   * @returns 
   */
  async updateNotificationToken(token: string): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'updateNotificationToken',
      category: 'user-notifications.service',
      data: {
        token: token
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-notifications').update(token, {});
  }
}
