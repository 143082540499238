import { Injectable, Injector } from '@angular/core';
import { first } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';

import { ConnectivityService } from './connectivity.service';
import { LoggerService } from './logger.service';

import { ApplicationState } from '../ngrx/application-state';
import { PhrasebookData } from '../ngrx/store-data';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class PhrasebookService {

  private connectivityService: ConnectivityService;

  private loggerService: LoggerService;

  constructor(private store: Store<ApplicationState>, private injector: Injector) { }

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.connectivityService) {
        this.connectivityService = this.injector.get(ConnectivityService);
        this.loggerService = this.injector.get(LoggerService);
      }

      const phrasebook = this.connectivityService.feathers.service('user-phrasebook');

      // Service events

      phrasebook.removeListener('created');
      phrasebook.on('created', (data) => {
        this.loggerService.logMessage('Phrase created ', data, 'phrasebook.service', 'info');
        this.store.dispatch({
          type: 'ADD_PHRASEBOOK_ACTION',
          payload: [data]
        });
      });

      phrasebook.removeListener('removed');
      phrasebook.on('removed', (data) => {
        this.loggerService.logMessage('Phrase removed ', data, 'phrasebook.service', 'info');
        this.store.dispatch({
          type: 'REMOVE_PHRASEBOOK_ACTION',
          payload: data.id
        });
      });

      // Sync phrasebook

      const phrasebookData: PhrasebookData = await this.store.select('phrasebookData').pipe(first()).toPromise();
      phrasebook.find({
        query: {
          date_created: (!phrasebookData || !phrasebookData.lastUpdatedTimestamp) ? '' : phrasebookData.lastUpdatedTimestamp
        }
      }).then((results) => {
        if (results && results.length > 0) {
          this.store.dispatch({
            type: 'ADD_PHRASEBOOK_ACTION',
            payload: results
          });
        }

        console.log('Phrasebook synced');
        resolve({});
      }).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        reject(error);
      });
    });
  }

  add(text, translation, from, to): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'add',
      category: 'phrasebook.service',
      data: {
        text: text,
        translation: translation,
        from: from,
        to: to
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-phrasebook').create({
      text: text,
      translation: translation,
      from: from,
      to: to
    }, {});
  }

  delete(id): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'delete',
      category: 'phrasebook.service',
      data: {
        id: id
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('user-phrasebook').remove(id, {});
  }
}
