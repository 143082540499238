import { LiveVocabularyData, INITIAL_LIVE_VOCABULARY_DATA } from '../store-data';
import {
  ADD_LIVE_VOCABULARY_ACTION, UPDATE_LIVE_VOCABULARY_ACTION, DELETE_LIVE_VOCABULARY_ACTION, RESET_LIVE_VOCABULARY_ACTION,
  AddVocabularyAction, UpdateVocabularyAction, DeleteVocabularyAction, ResetVocabularyAction
} from '../actions/live-vocabulary';
import { Action } from '@ngrx/store';
import _ from 'lodash';

export function liveVocabularyData(state: LiveVocabularyData, action: Action): LiveVocabularyData {
  switch (action.type) {
    case ADD_LIVE_VOCABULARY_ACTION:
      return handleAddVocabularyAction(state, <any>action);
    case UPDATE_LIVE_VOCABULARY_ACTION:
      return handleUpdateVocabularyAction(state, <any>action);
    case DELETE_LIVE_VOCABULARY_ACTION:
      return handleDeleteVocabularyAction(state, <any>action);
    case RESET_LIVE_VOCABULARY_ACTION:
      return handleResetVocabularyAction(state, <any>action);
    default:
      return state;
  }
}

function handleAddVocabularyAction(state: LiveVocabularyData, action: AddVocabularyAction) {
  const newData = (state) ? Object.assign({}, state.list) : INITIAL_LIVE_VOCABULARY_DATA.list;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_LIVE_VOCABULARY_DATA.lastUpdatedTimestamp;
  action.payload = _.orderBy(action.payload, 'date_created', 'asc');

  for (const key in action.payload) {
    newData[action.payload[key].id] = action.payload[key];
    if (action.payload[key].date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
      lastUpdatedTimestamp = action.payload[key].date_updated;
    }
  }

  const newStoreState: LiveVocabularyData = {
    list: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleUpdateVocabularyAction(state: LiveVocabularyData, action: UpdateVocabularyAction) {
  const newData = (state) ? Object.assign({}, state.list) : INITIAL_LIVE_VOCABULARY_DATA.list;
  let lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_LIVE_VOCABULARY_DATA.lastUpdatedTimestamp;

  newData[action.payload.id] = action.payload;
  if (action.payload.date_updated > lastUpdatedTimestamp || lastUpdatedTimestamp === undefined) {
    lastUpdatedTimestamp = action.payload.date_updated;
  }

  const newStoreState: LiveVocabularyData = {
    list: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleDeleteVocabularyAction(state: LiveVocabularyData, action: DeleteVocabularyAction) {
  const newData = (state) ? Object.assign({}, state.list) : INITIAL_LIVE_VOCABULARY_DATA.list,
    lastUpdatedTimestamp = (state) ? state.lastUpdatedTimestamp : INITIAL_LIVE_VOCABULARY_DATA.lastUpdatedTimestamp;

  if (newData[action.payload.id]) {
    delete newData[action.payload.id];
  }

  const newStoreState: LiveVocabularyData = {
    list: newData,
    lastUpdatedTimestamp: lastUpdatedTimestamp
  };

  return newStoreState;
}

function handleResetVocabularyAction(state: LiveVocabularyData, action: ResetVocabularyAction) {
  return INITIAL_LIVE_VOCABULARY_DATA;
}
