import { Action } from '@ngrx/store';
import { Contact } from '../../models/contact';

export const SYNC_CONTACTS_ACTION = 'SYNC_CONTACTS_ACTION';
export const UPDATE_CONTACT_ACTION = 'UPDATE_CONTACT_ACTION';
export const REMOVE_CONTACT_ACTION = 'REMOVE_CONTACT_ACTION';
export const CONTACT_STARTED_TYPING_ACTION = 'CONTACT_STARTED_TYPING_ACTION';
export const CONTACT_FINISHED_TYPING_ACTION = 'CONTACT_FINISHED_TYPING_ACTION';
export const RESET_CONTACTS_ACTION = 'RESET_CONTACTS_ACTION';

export class SyncContactsAction implements Action {
  readonly type = SYNC_CONTACTS_ACTION;
  constructor(public payload?: any) { }
}

export class UpdateContactAction implements Action {
  readonly type = UPDATE_CONTACT_ACTION;
  constructor(public payload?: Contact) { }
}

export class RemoveContactAction implements Action {
  readonly type = REMOVE_CONTACT_ACTION;
  constructor(public payload?: any) { }
}

export class ContactStartedTypingAction implements Action {
  readonly type = CONTACT_STARTED_TYPING_ACTION;
  constructor(public payload?: string) { }
}

export class ContactFinishedTypingAction implements Action {
  readonly type = CONTACT_FINISHED_TYPING_ACTION;
  constructor(public payload?: string) { }
}

export class ResetContactsAction implements Action {
  readonly type = RESET_CONTACTS_ACTION;
  constructor(public payload?: string) { }
}
