import { Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { ConnectivityService } from './connectivity.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {

  private connectivityService: ConnectivityService;

  constructor(private injector: Injector) {
    this.connectivityService = this.injector.get(ConnectivityService);
  }

  create(data): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'create',
      category: 'top-up.service',
      data: {
        data: data
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('top-up').create(data);
  }

  delete(id): Promise<any> {
    Sentry.addBreadcrumb({
      message: 'delete',
      category: 'top-up.service',
      data: {
        id: id
      },
      level: 'info'
    });

    return this.connectivityService.feathers.service('top-up').remove(id);
  }
}
