<ion-row *ngIf="data.length > 0" class="ion-no-padding" style="background: white; padding-left: 4px; padding-right: 4px;">
  <ion-col class="ion-text-center ion-no-padding">
    <ion-button (click)="select(data[0])" color="white" mode="ios"
      [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
      style="--padding-start: 0px; --padding-end: 0px;" class="no-margin">
      <img src="assets/lib/noto-emoji/svg/emoji_u{{data[0]}}.svg"
        [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
        [style.width]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'" />
    </ion-button>
  </ion-col>

  <ion-col class="ion-text-center ion-no-padding">
    <ion-button (click)="select(data[1])" color="white" mode="ios"
      [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
      style="--padding-start: 0px; --padding-end: 0px;" class="no-margin">
      <img src="assets/lib/noto-emoji/svg/emoji_u{{data[1]}}.svg"
        [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
        [style.width]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'" />
    </ion-button>
  </ion-col>

  <ion-col class="ion-text-center ion-no-padding">
    <ion-button (click)="select(data[2])" color="white" mode="ios"
      [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
      style="--padding-start: 0px; --padding-end: 0px;" class="no-margin">
      <img src="assets/lib/noto-emoji/svg/emoji_u{{data[2]}}.svg"
        [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
        [style.width]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'" />
    </ion-button>
  </ion-col>

  <ion-col class="ion-text-center ion-no-padding">
    <ion-button (click)="select(data[3])" color="white" mode="ios"
      [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
      style="--padding-start: 0px; --padding-end: 0px;" class="no-margin">
      <img src="assets/lib/noto-emoji/svg/emoji_u{{data[3]}}.svg"
        [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
        [style.width]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'" />
    </ion-button>
  </ion-col>

  <ion-col class="ion-text-center ion-no-padding">
    <ion-button (click)="select(data[4])" color="white" mode="ios"
      [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
      style="--padding-start: 0px; --padding-end: 0px;" class="no-margin">
      <img src="assets/lib/noto-emoji/svg/emoji_u{{data[4]}}.svg"
        [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
        [style.width]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'" />
    </ion-button>
  </ion-col>

  <ion-col class="ion-text-center ion-no-padding">
    <ion-button (click)="select(data[5])" color="white" mode="ios"
      [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
      style="--padding-start: 0px; --padding-end: 0px;" class="no-margin">
      <img src="assets/lib/noto-emoji/svg/emoji_u{{data[5]}}.svg"
        [style.height]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'"
        [style.width]="(plt.is('tablet') || plt.is('desktop')) ? '54px' : '44px'" />
    </ion-button>
  </ion-col>
</ion-row>