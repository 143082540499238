import { Action } from '@ngrx/store';

export const ADD_LIVE_EVENT_ACTION = 'ADD_LIVE_EVENT_ACTION';
export const UPDATE_LIVE_EVENT_ACTION = 'UPDATE_LIVE_EVENT_ACTION';
export const DELETE_LIVE_EVENT_ACTION = 'DELETE_LIVE_EVENT_ACTION';
export const RESET_LIVE_EVENTS_ACTION = 'RESET_LIVE_EVENTS_ACTION';

export class AddLiveEventAction implements Action {
  readonly type = ADD_LIVE_EVENT_ACTION;
  constructor(public payload?: any) { }
}

export class UpdateLiveEventAction implements Action {
  readonly type = UPDATE_LIVE_EVENT_ACTION;
  constructor(public payload?: any) { }
}

export class DeleteLiveEventAction implements Action {
  readonly type = DELETE_LIVE_EVENT_ACTION;
  constructor(public payload?: any) { }
}

export class ResetLiveEventsAction implements Action {
  readonly type = RESET_LIVE_EVENTS_ACTION;
  constructor(public payload?: any) { }
}
