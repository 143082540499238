import { Action } from '@ngrx/store';

export const UPDATE_CONFIG_ACTION = 'UPDATE_CONFIG_ACTION';
export const RESET_CONFIG_ACTION = 'RESET_CONFIG_ACTION';

export class UpdateConfigAction implements Action {
  readonly type = UPDATE_CONFIG_ACTION;
  constructor(public payload?: any) { }
}

export class ResetConfigAction implements Action {
  readonly type = RESET_CONFIG_ACTION;
  constructor(public payload?: any) { }
}
