import { Action } from '@ngrx/store';

export const SET_UI_VARIABLE_ACTION = 'SET_UI_VARIABLE_ACTION';
export const SET_BATCH_UI_VARIABLE_ACTION = 'SET_BATCH_UI_VARIABLE_ACTION';
export const RESET_UI_VARIABLES_ACTION = 'RESET_UI_VARIABLES_ACTION';

export class SetUiVariableAction implements Action {
  readonly type = SET_UI_VARIABLE_ACTION;
  constructor(public payload?: any) { }
}

export class SetBatchUiVariableAction implements Action {
  readonly type = SET_BATCH_UI_VARIABLE_ACTION;
  constructor(public payload?: any) { }
}

export class ResetUiVariableAction implements Action {
  readonly type = RESET_UI_VARIABLES_ACTION;
  constructor(public payload?: any) { }
}
